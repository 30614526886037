import { memo, useCallback, useRef, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import type { DocumentNode } from "graphql";
import type { Subject } from "rxjs";

import {
  PageTypes,
  CompletionStatuses,
  MeetingEndedState,
  DocumentBundleMembershipRole,
  AnnotationGraphicTypes,
  AnnotationSubtype,
  CoordinateSystem,
  AnnotationDesignationType,
} from "graphql_globals";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { TEXT_ANNOTATION_PLACEHOLDER, TEXT_ANNOTATION_EM } from "constants/globals";
import { useGraphicCache, type GraphicCache } from "common/meeting/context/graphic_cache";
import { useToolbar } from "common/meeting/context/toolbar";
import { updateMeetingParticipantSigningAssets } from "common/meeting/pdf/annotation/asset";
import {
  addNewAnnotationToDocumentCache,
  addRadioAnnotationToDocumentCache,
} from "common/meeting/pdf/annotation/util";
import { PDFWrapper, usePDFContext, useIsPdfLoaded, type PageInfo } from "common/pdf/pspdfkit";
import {
  annotationDefaultPdfPointSize,
  annotationPdfPointSizeFromText,
  textAnnotationContent,
} from "common/pdf/util";
import { PDFSoftCover } from "common/pdf/pspdfkit/overlay";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation, UNFULFILLED } from "common/pdf/pspdfkit/designation";
import { NotaryPointer } from "common/meeting/notary_pointer";
import { getTimeZoneDate } from "common/meeting/pdf/annotation/date_interaction";
import AssetGenerator from "common/core/asset_generator";
import ReuseVectorGraphicModal from "common/pdf/interaction/sign/modals/reuse_vector_graphic_modal";
import { getCurrentDocumentNode, type getPrimaryParticipant } from "common/meeting/util";
import { constrainSize } from "util/number";
import { useJumpToNotaryPointer } from "common/meeting/scroll";
import { createOptimisticId } from "common/meeting/pdf/annotation";
import {
  useAnnotationUpdate,
  useDeleteAnnotation,
  useAsset,
  usePreloadAssets,
  // eslint-disable-next-line import/no-restricted-paths -- Don't want to move this while we have some much PDFjs code
} from "signer_portal/meeting/v3/document/annotation";
import AddCheckmarkAnnotationMutation from "common/meeting/pdf/annotation/add_checkmark_annotation_mutation.graphql";
import AddDateMutation from "common/meeting/pdf/annotation/add_date_annotation_mutation.graphql";
import AddTextAnnotationMutation from "common/meeting/pdf/annotation/add_text_annotation_mutation.graphql";
import { MobileMeetingSigningControls } from "common/meeting/mobile";
import { Visible, useMobileScreenClass } from "common/core/responsive";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import ToolPreview from "./tool_preview";
import AddVectorGraphicAnnotationMutation from "./add_vector_graphic_annotation_mutation.graphql";
import type {
  WitnessMeetingDocumentV3 as Meeting,
  WitnessMeetingDocumentV3_meetingParticipants_WitnessParticipant as WitnessParticipant,
  WitnessMeetingDocumentV3_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "./index_fragment.graphql";
import Styles from "./index.module.scss";
import type { ActiveParticipant } from "..";

type CustomInteractionError = {
  interactionErrorMessage: ReactNode;
};
type Interaction = { locked: true } | { locked: false; error?: Error | CustomInteractionError };
type JumpToArgs = Parameters<typeof useJumpToNotaryPointer>[0];
type Props = {
  user: Parameters<typeof getPrimaryParticipant>[1];
  meeting: Meeting;
  query: DocumentNode;
  interaction$: Subject<Interaction>;
  witnessParticipant: WitnessParticipant | IdentityVerifiedWitnessParticipant;
  notaryPointer: JumpToArgs["notaryPointer"];
  onShowNotaryPointer: JumpToArgs["onShowNotaryPointer"];
  indicatedDesignation: JumpToArgs["indicatedDesignation"];
  currentPenHolderParticipant: ActiveParticipant | null;
};
type InteractionOptions = {
  meetingId: string;
  documentId: string;
  query: Props["query"];
  cache: GraphicCache;
  currentTool: string | null;
  placeTool: () => void;
  setFocused: ReturnType<typeof usePDFContext>["setFocused"];
  witnessParticipant: Props["witnessParticipant"];
  interaction$: Props["interaction$"];
};
type Point = { x: number; y: number };

function useWrapInteraction<Args extends unknown[], Fn extends (...args: Args) => Promise<unknown>>(
  interaction$: Props["interaction$"],
  cb: Fn,
): Fn {
  return useCallback(
    (...args: Args) => {
      interaction$.next({ locked: true });
      return cb(...args)
        .then((value) => {
          interaction$.next({ locked: false });
          return value;
        })
        .catch((error: Error) => {
          interaction$.next({ locked: false, error });
          return Promise.reject(error);
        });
    },
    [interaction$, cb],
  ) as Fn;
}

export function getCachedSizeForAnnotation({
  cache,
  graphicType,
  userId,
  size,
}: {
  cache: GraphicCache;
  graphicType: AnnotationGraphicTypes;
  userId: string;
  size: { height: number; width: number };
}) {
  const cachedSize = cache.get({ userId, graphicType });
  if (cachedSize) {
    return cachedSize;
  }
  const { width, height } = size;
  const max = { height: 28, width: 500 };
  return width && height
    ? constrainSize({ width, height, maxHeight: max.height, maxWidth: max.width })
    : max;
}

function usePDFInteraction(options: InteractionOptions) {
  const {
    placeTool,
    setFocused,
    currentTool,
    cache,
    meetingId,
    documentId,
    query,
    witnessParticipant,
    interaction$,
  } = options;
  const userId = witnessParticipant.userId!;
  const addVectorGraphicAnnotationMutateFn = useMutation(AddVectorGraphicAnnotationMutation);
  const { getAsset, reuseAssetState, assetGeneratorState } = useAsset(false); // witness names should not be locked
  const addTextAnnotationMutateFn = useMutation(AddTextAnnotationMutation);
  const addCheckmarkMutateFn = useMutation(AddCheckmarkAnnotationMutation);
  const addDateMutateFn = useMutation(AddDateMutation);

  const handleAddAnnotation = (
    {
      point,
      pageIndex,
    }: {
      point: Point;
      pageIndex: number;
    },
    designation?: { type: AnnotationDesignationType; id: string },
  ) => {
    const mixin = {
      meetingId,
      documentId,
      authorId: userId,
      location: {
        pageType: PageTypes.DOCUMENT,
        page: pageIndex,
        point,
        coordinateSystem: CoordinateSystem.ABSOLUTE,
      },
    };
    const annotationDesignation = designation
      ? {
          fulfilled: true,
          required: false,
          id: designation.id,
          __typename: "AnnotationDesignation",
          inProgress: false,
          ...(designation.type === AnnotationDesignationType.FREE_TEXT && {
            fulfilled: false,
            inProgress: true,
          }),
        }
      : null;
    const optimisticAnnotation = {
      id: createOptimisticId(),
      authorId: userId,
      annotationDesignationId: designation?.id || null,
      location: {
        coordinateSystem: CoordinateSystem.ABSOLUTE,
        page: pageIndex,
        pageType: PageTypes.DOCUMENT,
        point: { ...point, __typename: "Point" as const },
        __typename: "AnnotationLocation" as const,
      },
      meetingId,
      canEdit: true,
    };
    const optimisticResponse = {
      annotationDesignation,
      errors: null,
      meeting: null,
      document: null,
    };
    const tool = designation?.type || currentTool;
    switch (tool) {
      case AnnotationSubtype.SIGNATURE:
      case AnnotationSubtype.INITIALS: {
        const graphicType = tool.toLowerCase().includes("signature")
          ? AnnotationGraphicTypes.SIGNATURE
          : AnnotationGraphicTypes.INITIALS;
        return getAsset(graphicType, witnessParticipant, cache).then((asset) => {
          if (!asset?.key) {
            return null;
          }
          const { height, width } = getCachedSizeForAnnotation({
            cache,
            userId,
            graphicType,
            size: asset.size,
          });
          const input = {
            ...mixin,
            key: asset.key,
            subtype: AnnotationSubtype.SIGNATURE,
            size: { height, width },
            annotationDesignationId: designation?.id,
          };
          return addVectorGraphicAnnotationMutateFn({
            variables: { input },
            optimisticResponse: {
              addVectorGraphicAnnotation: {
                annotation: {
                  ...optimisticAnnotation,
                  asset: { __typename: "SecureUrl", url: asset.url },
                  pngAsset: { __typename: "SecureUrl", url: asset.url },
                  kind: AnnotationGraphicTypes.INITIALS,
                  subtype: AnnotationSubtype.INITIALS,
                  size: {
                    ...input.size,
                    __typename: "Size",
                  },
                  __typename: "VectorGraphicAnnotation",
                },
                author: {
                  id: userId,
                  signingAssets: {
                    font: asset.font,
                    signatureAsset: null,
                    initialsAsset: {
                      __typename: "SigningAsset",
                      font: asset.font,
                      method: asset.method,
                      png: {
                        key: asset.key,
                        url: asset.url,
                        __typename: "SecureUrl",
                      },
                    },
                    __typename: "SigningAssets",
                  },
                  __typename: "User",
                },
                ...optimisticResponse,
                __typename: "AddVectorGraphicAnnotationPayload",
              },
            },
            update(cacheProxy, { data }) {
              const { author, errors, annotation } = data!.addVectorGraphicAnnotation!;
              addNewAnnotationToDocumentCache(cacheProxy, {
                meetingId,
                documentId,
                newAnnotation: annotation!,
                annotationDesignationId: designation?.id,
                errors,
              });
              author &&
                updateMeetingParticipantSigningAssets(query, cacheProxy, {
                  meetingId,
                  userId,
                  signingAssets: author.signingAssets,
                });
            },
          }).then((result) => result.data!.addVectorGraphicAnnotation!.annotation!);
        });
      }
      case AnnotationSubtype.CHECKMARK: {
        const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
        return addCheckmarkMutateFn({
          variables: {
            input: { ...mixin, size, annotationDesignationId: designation?.id },
          },
          optimisticResponse: {
            addCheckmarkAnnotation: {
              __typename: "AddCheckmarkAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                subtype: AnnotationSubtype.CHECKMARK,
                __typename: "CheckmarkAnnotation",
              },
              ...optimisticResponse,
              updatedDesignations: [],
              designationGroup: null,
            },
          },
          update(cacheProxy, { data }) {
            const { addCheckmarkAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addCheckmarkAnnotation!.annotation!,
              annotationDesignationId: designation?.id,
              errors: addCheckmarkAnnotation!.errors,
            });
          },
        })
          .then((r) => r.data!.addCheckmarkAnnotation!.annotation!)
          .catch((error) => {
            if (
              isGraphQLError(error) &&
              typeof error.message === "string" &&
              error.message.includes("designation_group_max_fulfilled")
            ) {
              return Promise.reject({
                interactionErrorMessage: (
                  <FormattedMessage
                    id="f63abc86-dc98-43af-be5d-c5980884085b"
                    defaultMessage="Oops! You’ve attempted to select too many checkboxes. Uncheck one before making a new selection."
                  />
                ),
              });
            }
            return Promise.reject(error);
          });
      }
      case AnnotationSubtype.RADIO_CHECKMARK: {
        const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
        return addCheckmarkMutateFn({
          variables: {
            input: { ...mixin, size, annotationDesignationId: designation!.id },
          },
          optimisticResponse: {
            addCheckmarkAnnotation: {
              __typename: "AddCheckmarkAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                subtype: AnnotationSubtype.RADIO_CHECKMARK,
                __typename: "CheckmarkAnnotation",
              },
              ...optimisticResponse,
              updatedDesignations: [],
              designationGroup: null,
            },
          },
          update(cacheProxy, { data }) {
            const { addCheckmarkAnnotation } = data!;
            addRadioAnnotationToDocumentCache(query, cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addCheckmarkAnnotation!.annotation!,
              annotationDesignationId: designation!.id,
              errors: addCheckmarkAnnotation!.errors,
            });
          },
        }).then((r) => r.data!.addCheckmarkAnnotation!.annotation!);
      }
      case AnnotationSubtype.DATE_SIGNED: {
        const text = getTimeZoneDate()!;
        const size = annotationPdfPointSizeFromText(text);
        return addDateMutateFn({
          variables: {
            input: { ...mixin, size, annotationDesignationId: designation?.id },
          },
          optimisticResponse: {
            addDateAnnotation: {
              __typename: "AddDateAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text,
                subtype: AnnotationSubtype.DATE,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addDateAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addDateAnnotation!.annotation!,
              annotationDesignationId: designation?.id,
              errors: addDateAnnotation!.errors,
            });
          },
        }).then(({ data }) => data!.addDateAnnotation!.annotation!);
      }
      case AnnotationSubtype.DOB:
      case AnnotationSubtype.ZIP:
      case AnnotationSubtype.CITY:
      case AnnotationSubtype.STATE:
      case AnnotationSubtype.ADDRESS1:
      case AnnotationSubtype.ADDRESS2:
      case AnnotationSubtype.NAME: {
        let text = "";
        switch (tool) {
          case AnnotationSubtype.DOB: {
            text = witnessParticipant.dob || "";
            break;
          }
          case AnnotationSubtype.ZIP: {
            text = witnessParticipant.address.postal;
            break;
          }
          case AnnotationSubtype.CITY: {
            text = witnessParticipant.address.city;
            break;
          }
          case AnnotationSubtype.STATE: {
            text = witnessParticipant.address.state;
            break;
          }
          case AnnotationSubtype.ADDRESS1: {
            text = witnessParticipant.address.line1;
            break;
          }
          case AnnotationSubtype.ADDRESS2: {
            text = witnessParticipant.address.line2;
            break;
          }
          case AnnotationSubtype.NAME: {
            text = witnessParticipant.fullName || "";
            break;
          }
        }

        const size = text
          ? annotationPdfPointSizeFromText(text)
          : annotationPdfPointSizeFromText(TEXT_ANNOTATION_EM);

        return addTextAnnotationMutateFn({
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text,
              editable: true,
              newSubtype: tool,
              size,
              annotationDesignationId: designation?.id,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text,
                subtype: tool,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId: designation?.id,
              errors: addTextAnnotation!.errors,
            });
          },
        }).then(({ data }) => {
          return data!.addTextAnnotation!.annotation;
        });
      }
      case AnnotationSubtype.DAY_SIGNED:
      case AnnotationSubtype.YEAR_SIGNED:
      case AnnotationSubtype.MONTH_SIGNED:
      case AnnotationSubtype.N_A: {
        const text =
          currentTool === AnnotationSubtype.N_A
            ? "N/A"
            : textAnnotationContent(currentTool!, { contact: null });
        const size = annotationPdfPointSizeFromText(text);
        return addTextAnnotationMutateFn({
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text,
              editable: true,
              newSubtype: currentTool as AnnotationSubtype,
              size,
              annotationDesignationId: designation?.id,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text,
                subtype: currentTool as AnnotationSubtype,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId: designation?.id,
              errors: addTextAnnotation!.errors,
            });
          },
        }).then(({ data }) => {
          return data!.addTextAnnotation!.annotation;
        });
      }
      case AnnotationSubtype.FREE_TEXT:
      case "text": {
        const size = annotationPdfPointSizeFromText(
          designation ? TEXT_ANNOTATION_EM : TEXT_ANNOTATION_PLACEHOLDER,
        );
        return addTextAnnotationMutateFn({
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text: "",
              editable: true,
              newSubtype: AnnotationSubtype.FREE_TEXT,
              size,
              annotationDesignationId: designation?.id,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text: "",
                subtype: AnnotationSubtype.FREE_TEXT,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId: designation?.id,
              errors: addTextAnnotation!.errors,
            });
          },
        }).then(({ data }) => {
          const annotation = data!.addTextAnnotation!.annotation;
          setFocused?.(annotation!.id);
          return annotation;
        });
      }
      default:
        return Promise.resolve(null);
    }
  };
  const handlePagePress = async ({
    pageIndex,
    point,
    pageType,
  }: {
    pageIndex: number;
    point: Point;
    pageType: PageTypes;
  }) => {
    if (pageType === PageTypes.DOCUMENT) {
      // Witneses can only work on document.
      placeTool();
      return handleAddAnnotation({ point, pageIndex });
    }
    return Promise.resolve(null);
  };

  const handleDesignationClick = (designation: {
    id: string;
    type: AnnotationDesignationType;
    location: { page: number; point: Point };
  }) => {
    const { page, point } = designation.location;
    return (
      handleAddAnnotation(
        { point: { x: point.x, y: point.y }, pageIndex: page },
        designation,
      ) as Promise<Awaited<ReturnType<typeof handleAddAnnotation>>>
    ).then((annotation) => annotation || UNFULFILLED);
  };

  const callbackOptions = { query, userId, meetingId, documentId, cache };
  return {
    getAsset,
    reuseAssetState,
    assetGeneratorState,
    handlePagePress: useWrapInteraction<Parameters<typeof handlePagePress>, typeof handlePagePress>(
      interaction$,
      handlePagePress,
    ),
    handleAnnotationUpdate: useWrapInteraction<
      Parameters<ReturnType<typeof useAnnotationUpdate>>,
      ReturnType<typeof useAnnotationUpdate>
    >(interaction$, useAnnotationUpdate(callbackOptions)),
    handleAnnotationDelete: useWrapInteraction<
      Parameters<ReturnType<typeof useDeleteAnnotation>>,
      ReturnType<typeof useDeleteAnnotation>
    >(interaction$, useDeleteAnnotation(callbackOptions)),
    handleDesignationClick: useWrapInteraction<
      Parameters<typeof handleDesignationClick>,
      typeof handleDesignationClick
    >(interaction$, handleDesignationClick),
  };
}

function WitnessMeetingDocumentContent({
  meeting,
  query,
  witnessParticipant,
  user,
  interaction$,
  notaryPointer,
  onShowNotaryPointer,
  indicatedDesignation,
  currentPenHolderParticipant,
}: Props) {
  const pageInfoRef = useRef<{ pageInfo: PageInfo; documentId: string } | null>(null);
  const isMobile = useMobileScreenClass();
  const organization = meeting.organizationTransaction.publicOrganization;
  const isPdfLoaded = useIsPdfLoaded();
  const { setFocused } = usePDFContext();
  const cache = useGraphicCache();
  const recipientColors = useRecipientColors();
  const { currentTool, placeTool } = useToolbar();
  const isMeetingOver = meeting.endedState !== MeetingEndedState.NOT_COMPLETED;
  const currentDocument = getCurrentDocumentNode(meeting);
  const isLockedDoc =
    currentDocument.completionStatus === CompletionStatuses.COMPLETE ||
    currentDocument.completionStatus === CompletionStatuses.REJECTED;
  const uiLocked = !isPdfLoaded || isMeetingOver || isLockedDoc;
  const signingLocked = !currentPenHolderParticipant || uiLocked;
  useJumpToNotaryPointer({
    notaryPointer,
    onShowNotaryPointer,
    indicatedDesignation,
    currentDocument,
  });
  usePreloadAssets({ cache, participant: witnessParticipant, organization });
  const callbackOptions = {
    meetingId: meeting.id,
    documentId: currentDocument.id,
    cache,
    witnessParticipant,
    setFocused,
    currentTool,
    placeTool,
    query,
    interaction$,
  };
  const {
    getAsset,
    assetGeneratorState,
    reuseAssetState,
    handleAnnotationUpdate,
    handleAnnotationDelete,
    handlePagePress,
    handleDesignationClick,
  } = usePDFInteraction(callbackOptions);
  const {
    userId: witnessUserId,
    signerRole: { index: witnessIndex },
    parentId,
  } = witnessParticipant;
  const getDesignationColor = useCallback(
    ({ signerRole }: { signerRole: { index: string; role: DocumentBundleMembershipRole } }) => {
      return witnessIndex === signerRole.index &&
        signerRole.role === DocumentBundleMembershipRole.WITNESS
        ? recipientColors.witness.text
        : null;
    },
    [witnessIndex],
  );
  return (
    <>
      <div className={Styles.documentContainer}>
        <PDFDocumentContainer
          document={currentDocument}
          onPagePress={signingLocked ? undefined : handlePagePress}
          setPageInfoCb={(pageInfo) => (pageInfoRef.current = pageInfo)}
          hideToolbar={isMobile}
        >
          {signingLocked && <PDFSoftCover />}
          {assetGeneratorState && (
            <AssetGenerator
              currentUser={witnessParticipant}
              assetType={assetGeneratorState.type}
              onSave={assetGeneratorState.onSaveAsset}
              onCancel={assetGeneratorState.onCancelSaveAsset}
              organization={organization}
            />
          )}
          {currentPenHolderParticipant && reuseAssetState && (
            <ReuseVectorGraphicModal
              onReuse={reuseAssetState.onReuseAsset}
              onRecreate={reuseAssetState.onRecreateAsset}
              onCancel={reuseAssetState.onCancelReuseAsset}
              participant={witnessParticipant}
              type={reuseAssetState.type}
              organization={organization}
            />
          )}
        </PDFDocumentContainer>
      </div>
      {!uiLocked && (
        <Visible xs sm>
          <MobileMeetingSigningControls
            lockSignerName={false}
            meeting={meeting}
            meetingQuery={query}
            currentPenHolderParticipant={currentPenHolderParticipant}
            signerCanAnnotate={!currentDocument.isEnote}
            user={user}
            getAsset={getAsset}
            assetCache={cache}
            organization={organization}
            pageInfo={pageInfoRef.current}
          />
        </Visible>
      )}
      {!currentDocument.isEnote &&
        currentDocument.annotations.edges.map(({ node }) => {
          const canEditAnnotation = !signingLocked && node.authorId === witnessUserId;
          return (
            <Annotation
              key={node.id}
              annotation={node}
              onUpdate={canEditAnnotation ? handleAnnotationUpdate : undefined}
              onDelete={canEditAnnotation ? handleAnnotationDelete : undefined}
            />
          );
        })}
      {currentDocument.annotationDesignations.edges
        .filter((e) => {
          // If we have a parent, we're notary colocated; if we don't, we don't want
          // notary designations.
          return (
            (parentId || e.node.signerRole.role !== DocumentBundleMembershipRole.NOTARY) &&
            e.node.active
          );
        })
        .map(({ node }) => {
          const color = currentPenHolderParticipant && getDesignationColor(node);
          return (
            <AnnotationDesignation
              key={node.id}
              designation={node}
              color={color}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onFulfill={color ? (handleDesignationClick as any) : undefined}
            />
          );
        })}
      {currentPenHolderParticipant && !isMeetingOver && (
        <ToolPreview
          currentTool={currentTool}
          witnessParticipant={witnessParticipant}
          organization={organization}
          cache={cache}
        />
      )}
      {currentPenHolderParticipant &&
        !isMeetingOver &&
        notaryPointer?.documentId === currentDocument.id && (
          <NotaryPointer
            location={{
              page: notaryPointer.pageIndex,
              pageType: notaryPointer.pageType,
              point: notaryPointer.point,
            }}
          />
        )}
    </>
  );
}

function WitnessMeetingPDF(props: Props) {
  return (
    <PDFWrapper>
      <WitnessMeetingDocumentContent {...props} />
    </PDFWrapper>
  );
}

export default memo(WitnessMeetingPDF);
