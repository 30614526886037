import { useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { NOTARIZE_NOTARY_URL } from "constants/marketing";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedSelectField } from "common/form/fields/select";
import AlertMessage from "common/core/alert_message";
import { useId } from "util/html";
import { getFormValues } from "util/form";

import Styles from "./state.module.scss";

type Props = {
  /** Field is disabled until this is truthy */
  states:
    | undefined
    | null
    | {
        abbreviation: string | null;
        name: string | null;
        notaryOnDemandSupported: boolean;
      }[];
  formValues?: { state?: string };
};

const MESSAGES = defineMessages({
  state: {
    id: "88d8e8dc-8e4f-4583-89eb-fcaca172970c",
    defaultMessage: "Select state",
  },
});

function NotarySignupStateField({ states, formValues }: Props) {
  const fieldId = useId();
  const intl = useIntl();
  const items = useMemo(() => {
    return (states || [])
      .map((state) => ({ label: state.name!, value: state.abbreviation! }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [states]);
  const selectedState = formValues?.state;
  const odnStateIsSelected = Boolean(
    selectedState &&
      states?.some((s) => s.notaryOnDemandSupported && s.abbreviation === selectedState),
  );
  return (
    <>
      <label htmlFor={fieldId} className={Styles.label}>
        <FormattedMessage
          id="c89179f3-f389-4d58-a21b-8fc4ae5ea563"
          defaultMessage="What state are you commissioned in?"
        />
      </label>
      <FormGroup fields={["state"]}>
        <DeprecatedSelectField
          id={fieldId}
          name="state"
          placeholder={intl.formatMessage(MESSAGES.state)}
          data-automation-id="state-field"
          visibilityToggle
          tooltipIconOutside
          tooltipPlacementRight
          disabled={!items.length}
          items={items}
        />
        <FormGroupErrors fields={["state"]} />
      </FormGroup>
      {Boolean(selectedState) && (
        <AlertMessage kind={odnStateIsSelected ? "success" : "info"}>
          <FormattedMessage
            id="6385fae7-f8e0-41ec-8b54-d5be284628b1"
            defaultMessage="{odnStateIsSelected, select, true{On-demand is available here!} other{On-demand is not available here. <link>Learn more</link>}}"
            values={{
              odnStateIsSelected,
              link: (text) => <a href={NOTARIZE_NOTARY_URL}>{text}</a>,
            }}
          />
        </AlertMessage>
      )}
    </>
  );
}

export default getFormValues<Props>("notarySignup")(NotarySignupStateField);
