import { useIntl } from "react-intl";
import { useState } from "react";

import { useForm } from "common/core/form";
import { useProofTransactionsEnabled } from "util/feature_detection";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { MULTISIGNER_EASYLINK } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { transformProofRequirementForSubmit } from "common/proof_requirements/common";
import { EditableTitle } from "common/core/form/subforms/editable_title";

import {
  CCRecipients,
  COMMON_MESSAGES,
  MultiSignerSelection,
  PayerRadioGroup,
  ProofRequirement,
} from "../common";
import {
  DOCS_PROVIDED_PROOF_MESSAGE_PROPS,
  type DocumentsProvidedFormValues,
  DocumentsProvidedTemplates,
  type TemplateInfoProps,
} from "./common";
import { EditEasylinkLayout } from "../edit";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function EditDocumentsProvidedEasylink(props: {
  notaryProfile: Parameters<typeof useProofTransactionsEnabled>[0];
  initialValues: DocumentsProvidedFormValues;
}) {
  const { initialValues } = props;
  const proofTransactionsEnabled = useProofTransactionsEnabled(props.notaryProfile);
  const multiSignerEasylinkEnabled = useFeatureFlag(MULTISIGNER_EASYLINK);
  const [activeOrganizationId] = useActiveOrganization();

  const form = useForm<DocumentsProvidedFormValues>({
    defaultValues: initialValues,
  });
  const { control } = form;
  const intl = useIntl();
  const [templateInfo, setTemplateInfo] = useState<TemplateInfoProps>({
    isEsign: initialValues.proofRequirement?.length !== 0,
  });

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        return (
          <EditEasylinkLayout
            form={form}
            initialValues={initialValues}
            title={
              <EditableTitle<DocumentsProvidedFormValues>
                aria-label={intl.formatMessage(COMMON_MESSAGES.NAME_LABEL)}
                control={control}
                name="name"
              />
            }
            variables={(values, justActivated) => {
              return {
                input: {
                  easylinkId: initialValues.id,
                  name: values.name,
                  templateId: values.templateId,
                  proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                  payer: values.payer,
                  ...(justActivated && { active: true }),
                  minSigners: parseInt(values.minSigners, 10),
                  maxSigners: parseInt(values.maxSigners, 10),
                  ccRecipientEmails: values.ccRecipients.map(({ email }) => email),
                },
              };
            }}
          >
            <DocumentsProvidedTemplates form={form} setTemplateInfo={setTemplateInfo} />

            {proofTransactionsEnabled && (
              <ProofRequirement
                form={form}
                disabled={!templateInfo.isEsign}
                defaultAuthenticationRequirement={organization.defaultAuthenticationRequirement}
                availableProofRequirements={organization.availableProofRequirements}
                {...DOCS_PROVIDED_PROOF_MESSAGE_PROPS}
              />
            )}
            {multiSignerEasylinkEnabled && (
              <MultiSignerSelection
                form={form}
                signerFields={templateInfo.signerFields}
                templateId={templateInfo.templateId}
                isEditing
              />
            )}
            <CCRecipients form={form} />
            <PayerRadioGroup form={form} user={data!.viewer.user!} organization={organization} />
          </EditEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
