import type { InjectedFormProps } from "redux-form";
import { useState } from "react";

import { Feature } from "graphql_globals";
import { validatePresence } from "validators/form";
import { composeValidators } from "util/form";
import SubForm from "common/form/sub_form";
import type { TransactionEdit_organization_Organization as Organization } from "common/transactions/graphql/queries/edit_query.graphql";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";

import UpdateTransactionFeeForm from "./update_fees_form";

type FormValues = {
  notarizationFee: number;
  additionalSealFee: number;
  transactionFees: string;
};

type Props = {
  organization: Organization;
  value: string;
};

type InnerProps = ReduxFormProps & Props;

type ReduxFormProps = InjectedFormProps<FormValues>;

export const validationRules = () => {
  return composeValidators(
    validatePresence({
      field: "notarizationFee",
      label: "Value equal to or greater than 0",
    }),
    validatePresence({
      field: "additionalSealFee",
      label: "Value equal to or greater than 0",
    }),
  );
};

function TransactionFeeFormContainer(props: InnerProps) {
  const { organization, value } = props;
  const [fees, setFees] = useState(value);
  const { notaverseFirstSeal, notaverseAdditionalSeal } = organization.activeTier.prices;

  if (!organization.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING)) {
    return null;
  }

  return (
    <SubForm>
      <UpdateTransactionFeeForm
        {...props}
        fees={fees}
        setFees={setFees}
        notaverseFirstSeal={notaverseFirstSeal ? notaverseFirstSeal / 100 : undefined}
        notaverseAdditionalSeal={
          notaverseAdditionalSeal ? notaverseAdditionalSeal / 100 : undefined
        }
      />
    </SubForm>
  );
}

export default deprecatedSubForm()(TransactionFeeFormContainer);
