import { useEffect, useState, memo, type ComponentProps } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useToggleBooleanState } from "util/effects";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { fromSocketEvent } from "socket/util";
import { NOTARY_INITIATED_DOCUMENT_REFRESH } from "constants/signer_capabilities";
import type useSignerConnectionState from "common/meeting/notary/signer_connection_state";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import SignerPartyInfo from "common/meeting/sidebar/signer_info";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import AVResetIcon from "assets/images/refresh_video.svg";
import DocResetIcon from "assets/images/refresh_document.svg";
import InfoIcon from "assets/images/info_outline_black.svg";
import ScreenShareIcon from "assets/images/meeting/screenshare.svg";
import FolderIcon from "assets/images/folder-line.svg";
import PopoutMenuItem from "common/core/popout_menu/item";
import UserFullName from "common/user/user_full_name";
import {
  StandardTrackWithNotaryControls,
  SimulatedIndicator,
} from "common/meeting/sidebar/party/track";
import { useFeatureFlag } from "common/feature_gating";
import type { RemoteParty } from "common/video_conference";
import { MeetingParticipantRoles } from "graphql_globals";

import { SignerJoinTimer } from "./timer";
import TransactionDetails from "./transaction_details";
import SignerScreenShare from "./signer_screen_share";
import NotaryMeetingInfo from "./meeting_info";
import AlertAVIssueAction from "./alert_av_issue_action";
import { RemoveParticipantModal } from "./remove_participant_modal";
import Styles from "./signer_party.module.scss";
import type {
  SignerParty as Meeting,
  SignerParty_meetingParticipants as MeetingParticipant,
  SignerParty_meetingParticipants_SignerParticipant as SignerParticipant,
  SignerParty_meetingParticipants_WitnessParticipant as WitnessParticipant,
  SignerParty_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "./signer_party_fragment.graphql";

type Props = {
  meeting: Meeting;
  party: RemoteParty<MeetingParticipant>;
  onCheckId: (x: SignerParticipant | IdentityVerifiedWitnessParticipant) => void;
  onSetPenholder: (participantId: string) => void;
  signerConnectionState?: ReturnType<typeof useSignerConnectionState>;
};
type DeviceStatus = ComponentProps<typeof NotaryMeetingInfo>["deviceStatus"];

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "b37c00bc-5b66-4778-8c96-843aeda24129",
    defaultMessage: "Expand signer party controls",
  },
});
const SIMULATED_SIGNER_FEED = (
  <SimulatedIndicator>
    <FormattedMessage
      id="4632df13-92f7-4740-a027-1db363988acd"
      defaultMessage="[signer video feed]"
    />
  </SimulatedIndicator>
);

function particpantIsCurrentPenholder(participant: MeetingParticipant): boolean {
  return "isCurrentPenHolder" in participant && participant.isCurrentPenHolder;
}

function useDeviceInfo(channel: Parameters<typeof fromSocketEvent>[0], party: Props["party"]) {
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>();
  useEffect(() => {
    const sub = fromSocketEvent<DeviceStatus>(channel, "device_status").subscribe({
      next: (data) => {
        const signerUserId = party.participants[0].userId;
        if (signerUserId === data?.actingUserId) {
          setDeviceStatus(data);
        }
      },
    });
    return () => sub.unsubscribe();
  }, [channel]);

  const [deviceInfoOpen, toggleDeviceInfo] = useToggleBooleanState(false);
  return {
    toggleDeviceInfo,
    deviceInfoOpen,
    deviceStatus,
  };
}

function getSignerResetNotification() {
  return {
    type: NOTIFICATION_TYPES.MEETING,
    title: (
      <FormattedMessage
        id="1b0482c3-cf6f-422c-a095-fd099aee41b6"
        defaultMessage="Reset Signer Connection Sent"
      />
    ),
    message: (
      <FormattedMessage
        id="acffaffc-aff3-4b63-9d39-f067b550ca0c"
        defaultMessage="Signer will attempt to reset their connection to the meeting"
      />
    ),
  };
}

function SignerParty({ party, meeting, onCheckId, onSetPenholder, signerConnectionState }: Props) {
  const { participants, screenTrack } = party;
  const { documentBundle, meetingParticipants, videoConference } = meeting;
  const { organizationTransaction } = documentBundle!;
  const hasScreenShareTrack = Boolean(screenTrack);
  const intl = useIntl();
  const [screenShareDesired, setScreenShareDesired] = useState(true);
  useEffect(() => {
    if (!hasScreenShareTrack) {
      // Whenever the screntrack goes away, reset the desire to show it.
      setScreenShareDesired(true);
    }
  }, [hasScreenShareTrack]);
  const { channel, analytics } = useNotaryMeetingContext();
  const { toggleDeviceInfo, deviceInfoOpen, deviceStatus } = useDeviceInfo(channel, party);
  const videoStatus =
    signerConnectionState === "Disconnected_Callback" ||
    signerConnectionState === "Disconnected_Time"
      ? null
      : deviceStatus?.signerView === "retake_photo"
        ? "retake_photo"
        : deviceStatus?.appStatus === "background"
          ? "background"
          : deviceStatus?.signerView === "signer_uploading_doc"
            ? "signer_uploading_doc"
            : null;
  const [transactionDetailsOpen, toggleTransactionDetails] = useToggleBooleanState(false);
  const { simulatedNotaryProfile } = useNotaryMeetingContext();
  const signerParticipantsInThisParty = participants.filter(
    (participant) =>
      participant.__typename === "SignerParticipant" ||
      participant.__typename === "IdentityVerifiedWitnessParticipant",
  );
  const primarySignerParticipant = participants.find(
    (participant) => !participant.parentId,
  )! as SignerParticipant;
  const signerEnabledDocumentRefresh = meeting.signerCapabilities!.includes(
    NOTARY_INITIATED_DOCUMENT_REFRESH,
  );
  const singleSignerParty = participants.length === 1;

  // We consider the meeting to have concurrent signing if there are more than one witness or signer remote parties that can act as penholders
  const concurrentSigning =
    meetingParticipants.filter(
      (p) =>
        !p.parentId &&
        (p.__typename === "SignerParticipant" ||
          p.__typename === "WitnessParticipant" ||
          p.__typename === "IdentityVerifiedWitnessParticipant"),
    ).length > 1;

  // If there are any witnesses with no parent, we should also assume signer party can be selected as penholder
  const signerPartyAsPenholder = singleSignerParty && concurrentSigning;
  const networkQuality = party.useNetworkQuality();
  const notaryIsPenHolder = meetingParticipants.some(
    (p) => p.__typename === "NotaryParticipant" && p.isCurrentPenHolder,
  );

  const allSignersPassedCA = participants.every(
    (p) =>
      ("photoIdVerified" in p && p.photoIdVerified) ||
      !("requiresCredentialAnalysis" in p) ||
      !p.requiresCredentialAnalysis,
  );
  const { platform } = primarySignerParticipant;
  const screenShareEnabled = platform === "WEB" || platform === "ANDROID";
  const isChimeConference = videoConference.__typename === "ChimeVideoConference";
  const canRequestScreenShare = screenShareEnabled && !hasScreenShareTrack && !isChimeConference;

  const removeSignerEnabled = useFeatureFlag("remove-meeting-signer");
  const [removeParticipantModalOpen, setRemoveParticipantModalOpen] = useState(false);
  const toggleRemoveParicipantModal = () => setRemoveParticipantModalOpen((v) => !v);
  const penholderParticipantInParty =
    signerParticipantsInThisParty.find((signer) => signer.isCurrentPenHolder) ||
    signerParticipantsInThisParty[0];
  const handleCheckId =
    penholderParticipantInParty.__typename === "IdentityVerifiedWitnessParticipant" &&
    !penholderParticipantInParty.photoId?.selfiePicture
      ? undefined
      : () => onCheckId(penholderParticipantInParty);

  return (
    <>
      <MeetingSidebarParty
        track={(trackProps) => (
          <StandardTrackWithNotaryControls
            track={
              simulatedNotaryProfile ? (
                SIMULATED_SIGNER_FEED
              ) : (
                <div className={Styles.timerTrack}>
                  {signerConnectionState !== "Connected" && <SignerJoinTimer />}
                  {party.track}
                </div>
              )
            }
            networkQuality={networkQuality}
            isCompleted={false}
            videoStatus={videoStatus}
            onCheckId={handleCheckId}
            checkIdWarning={!allSignersPassedCA}
            trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
            popoutMenuProps={{
              children: ({ close }) => (
                <>
                  <AlertAVIssueAction
                    meeting={meeting}
                    activeParticipantId={primarySignerParticipant.id}
                  />
                  <PopoutMenuItem
                    onClick={() => {
                      channel.sendMessage("reset_connection", {
                        user_id: primarySignerParticipant.userId,
                      });
                      analytics.onResetConnection();
                      pushNotification(getSignerResetNotification());
                      close();
                    }}
                    backgroundImage={AVResetIcon}
                    data-automation-id="reset-connection"
                  >
                    <FormattedMessage
                      id="9878cbaf-6fd8-430d-beb9-6bd80499aa8c"
                      defaultMessage="Reset Signer A/V"
                    />
                  </PopoutMenuItem>
                  {signerEnabledDocumentRefresh && (
                    <PopoutMenuItem
                      onClick={() => {
                        channel.sendMessage("change_page", {
                          pageNum: 0,
                          documentId: meeting.currentDocumentId,
                        });
                        analytics.onRefreshDocument();
                        close();
                      }}
                      backgroundImage={DocResetIcon}
                      data-automation-id="refresh-signer-document"
                    >
                      <FormattedMessage
                        id="a5435019-8402-4f43-a204-770281d3fea6"
                        defaultMessage="Refresh Signer Doc"
                      />
                    </PopoutMenuItem>
                  )}
                  <PopoutMenuItem onClick={toggleDeviceInfo} backgroundImage={InfoIcon}>
                    <FormattedMessage
                      id="b6a61be1-71b9-4a2d-a3f7-18cb12afc9c4"
                      defaultMessage="Meeting Information"
                    />
                  </PopoutMenuItem>
                  {canRequestScreenShare && (
                    <PopoutMenuItem
                      onClick={() => {
                        channel.sendMessage("screen_share_request", {
                          meeting_participant_id: primarySignerParticipant.id,
                          share_request: "start",
                        });
                        close();
                      }}
                      backgroundImage={ScreenShareIcon}
                    >
                      <FormattedMessage
                        id="8322519f-9d71-4932-93fc-6721cc6f36c3"
                        defaultMessage="Request Screenshare"
                      />
                    </PopoutMenuItem>
                  )}
                  {removeSignerEnabled && concurrentSigning && !removeParticipantModalOpen && (
                    <PopoutMenuItem
                      onClick={() => {
                        toggleRemoveParicipantModal();
                        close();
                      }}
                      iconName="failure"
                    >
                      <FormattedMessage
                        id="a3402806-519c-417b-b1bf-5ad3f2f73ef0"
                        defaultMessage="Remove {singleSignerParty, select, true {{isWitness, select, true {Witness} other {Signer}}} other {Signers}}"
                        values={{
                          singleSignerParty,
                          isWitness: party.role === MeetingParticipantRoles.WITNESS,
                        }}
                      />
                    </PopoutMenuItem>
                  )}
                  {!organizationTransaction.isRetail && (
                    <PopoutMenuItem onClick={toggleTransactionDetails} backgroundImage={FolderIcon}>
                      <FormattedMessage
                        id="87d5b4b4-90e8-4a47-a48f-a32ac6f2d896"
                        defaultMessage="Transaction Details"
                      />
                    </PopoutMenuItem>
                  )}
                  <VideoCondenseAction
                    videoCondensed={trackProps.videoCondensed}
                    toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  />
                </>
              ),
            }}
          />
        )}
        renderExpansion={
          singleSignerParty
            ? undefined
            : () =>
                participants.map((participant) => (
                  <SignerPartyInfo
                    key={participant.id}
                    participant={participant as SignerParticipant | WitnessParticipant}
                    onClick={
                      !notaryIsPenHolder || particpantIsCurrentPenholder(participant)
                        ? undefined
                        : onSetPenholder
                    }
                  />
                ))
        }
        connectionState={signerConnectionState}
        isActive={participants.some(particpantIsCurrentPenholder)}
        onActivate={
          signerPartyAsPenholder && !particpantIsCurrentPenholder(participants[0])
            ? () => onSetPenholder(participants[0].id)
            : undefined
        }
      >
        <MeetingSidebarPartyInfo
          header={
            singleSignerParty ? (
              <span className={SENSITIVE_CLASS}>
                <UserFullName user={participants[0]} wrap />
                {participants[0].pronouns && (
                  <span className={Styles.pronouns}> ({participants[0].pronouns})</span>
                )}
              </span>
            ) : (
              <FormattedMessage
                id="cbc9028a-631c-42d4-b4f5-ec3f66243c46"
                defaultMessage="Multi-signer"
              />
            )
          }
          headerSubtext={
            signerPartyAsPenholder && particpantIsCurrentPenholder(participants[0]) ? (
              <FormattedMessage
                id="91d5fd29-42e8-451c-bc21-e2a39e909930"
                defaultMessage="Active {isWitness, select, true {Witness} other {Signer}}"
                values={{ isWitness: party.role === MeetingParticipantRoles.WITNESS }}
              />
            ) : party.role === MeetingParticipantRoles.WITNESS ? (
              <FormattedMessage
                id="797bc601-ffbc-4673-97a9-38f5aee64813"
                defaultMessage="Witness"
              />
            ) : singleSignerParty ? (
              <FormattedMessage id="797bc601-ffbc-4673-97a9-38f5aee64813" defaultMessage="Signer" />
            ) : (
              <FormattedMessage
                id="914bae22-a6d9-4520-83d0-272994eac1ef"
                defaultMessage="{numSigners, number} Signers"
                values={{ numSigners: participants.length }}
              />
            )
          }
        />
      </MeetingSidebarParty>
      {deviceInfoOpen && (
        <NotaryMeetingInfo
          meeting={meeting}
          participant={primarySignerParticipant}
          deviceStatus={deviceStatus}
          onExit={toggleDeviceInfo}
        />
      )}
      {transactionDetailsOpen && (
        <TransactionDetails meeting={meeting} onExit={toggleTransactionDetails} />
      )}
      {screenShareDesired && hasScreenShareTrack && (
        <SignerScreenShare
          screenTrack={screenTrack!}
          participant={primarySignerParticipant}
          onStopScreenShare={() => {
            channel.sendMessage("screen_share_request", {
              meeting_participant_id: primarySignerParticipant.id,
              share_request: "stop",
            });
            setScreenShareDesired(false);
          }}
        />
      )}
      {removeParticipantModalOpen && (
        <RemoveParticipantModal
          onClose={toggleRemoveParicipantModal}
          meeting={meeting}
          participantId={primarySignerParticipant.id}
        />
      )}
    </>
  );
}

export default memo(SignerParty);
