import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import PaginationCaret from "common/pagination/caret";

import type { Document } from ".";
import Styles from "./import_loop.module.scss";
import { getDocuments } from "./requests";

type Props = {
  onCancel: () => void;
  onGoBack: () => void;
  goToNextStep: () => void;
  profileId: number;
  loopId: number;
  initialSelectedDocuments: Set<Document>;
  onSelectDocuments: (documents: Set<Document>) => void;
};

function documentsToIds(documents: Document[] | Set<Document>) {
  return new Set(Array.from(documents).map((document: Document) => document.id));
}

export default function AddDocuments({
  onCancel,
  onGoBack,
  goToNextStep,
  profileId,
  loopId,
  initialSelectedDocuments,
  onSelectDocuments,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Set<Document["id"]>>(
    documentsToIds(initialSelectedDocuments),
  );

  const handleContinue = (selectedDocIds: Set<Document["id"]>) => {
    const selectedDocs = new Set(documents.filter((d) => selectedDocIds.has(d.id)));
    onSelectDocuments(selectedDocs);
    goToNextStep();
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedDocumentIds(new Set());
    } else {
      setSelectedDocumentIds(documentsToIds(documents));
    }
    setAllSelected(!allSelected);
  };

  const changeSelection = (documentId: Document["id"]) => {
    if (selectedDocumentIds.has(documentId)) {
      selectedDocumentIds.delete(documentId);
    } else {
      selectedDocumentIds.add(documentId);
    }
    setSelectedDocumentIds(new Set(selectedDocumentIds));
  };

  useEffect(() => {
    let canceled = false;
    setLoading(true);

    // The `profileId` and `loopId` given as props are never expected to change because once we
    // reach the document selection step these IDs are locked in. However, as a general React
    // pattern a child component should not know about parent behavior/expected prop changes, so
    // treat these props as though they could change. This covers us against any race conditions
    // that could arise if we ever implement new features of the wizard whereby these props might
    // in fact change, such as a back button or a perpetual profile switcher.
    getDocuments(profileId, loopId)
      .then((data: Document[]) => {
        if (!canceled) {
          setDocuments(data);
        }
      })
      .finally(() => {
        if (!canceled) {
          setLoading(false);
        }
      });

    return () => {
      canceled = true;
    };
  }, [profileId, loopId]);

  return (
    <div>
      <div className={Styles.selectDocumentsTitle}>
        <FormattedMessage
          id="e32c4c82-9576-464a-a907-ed3c0bc56052"
          defaultMessage="Add Documents"
          tagName="h3"
        />
      </div>
      <FormattedMessage
        id="e2ee74a8-342a-4464-8316-02fbbeb5bc3a"
        defaultMessage="PDF files preferred. Others may be supported. Size limit is 30MB."
      />
      <div className={Styles.selectAllBox}>
        <DeprecatedCheckboxWithLabel
          label={allSelected ? "Deselect All" : "Select All"}
          checked={allSelected}
          onChange={handleSelectAll}
        />
      </div>
      <div className={Styles.documentsNameHeader}>
        <FormattedMessage id="4464ac2f-b4d6-42b7-aec1-78ba17498edf" defaultMessage="Name" />
      </div>
      <div className={Styles.documentsList}>
        {loading ? (
          <LoadingIndicator />
        ) : documents.length ? (
          documents.map((document) => (
            <DeprecatedCheckboxWithLabel
              key={document.id}
              className={Styles.checkboxListItems}
              label={document.name}
              checked={selectedDocumentIds.has(document.id)}
              onChange={() => changeSelection(document.id)}
            />
          ))
        ) : (
          <FormattedMessage
            id="88e5732a-3c9f-4239-bdfa-74b24ee4696b"
            defaultMessage="No documents to show"
          />
        )}
      </div>
      <div className={Styles.buttons}>
        <Button
          className={Styles.backButton}
          onClick={onGoBack}
          variant="secondary"
          buttonColor="action"
        >
          <PaginationCaret prev />
          <FormattedMessage id="d0e7c22d-a0e0-457d-82c8-230f8e1caacb" defaultMessage="Back" />
        </Button>
        <Button onClick={onCancel} variant="secondary" buttonColor="action">
          <FormattedMessage id="f260b24b-5b8a-4674-899f-0ccb2500aba3" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={() => handleContinue(selectedDocumentIds)}
          disabled={selectedDocumentIds.size === 0}
          variant="primary"
          buttonColor="action"
        >
          <FormattedMessage
            id="75e9a0b5-7a82-4988-bbed-670b9bb5419b"
            defaultMessage="Add {documentCount, plural, one{1 Document} other{# Documents}} to Transaction"
            values={{ documentCount: selectedDocumentIds.size }}
          />
        </Button>
      </div>
    </div>
  );
}
