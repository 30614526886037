import { useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import Button from "common/core/button";
import PronounsSubform from "common/form/sub_forms/pronouns";

type PhoneProps =
  | { displayPhoneNumber: "required" | "shown"; phoneCountryCode?: string }
  | { displayPhoneNumber?: false; phoneCountryCode?: never };
type Props = PhoneProps & {
  showCustomPronouns?: boolean;
  fieldNamePrefix?: string;
  displayEmail?: boolean;
  displayPronouns?: boolean;
  showSignatoryCapacity?: boolean;
  readOnly?: boolean;
};

const MESSAGES = defineMessages({
  signatory: {
    id: "c6d47494-ab06-4d7f-90d0-4797f300eb17",
    defaultMessage: "Signatory capacity",
  },
  email: {
    id: "656ddbd4-1071-487a-a9f3-0bf28ccfcb58",
    defaultMessage: "Email address",
  },
  first: {
    id: "1b98db1c-b742-48f6-9f6a-ee9e7be7e121",
    defaultMessage: "First name",
  },
  middle: {
    id: "41ac6718-efd5-4a47-b626-53c58b9a61ae",
    defaultMessage: "Middle name",
  },
  last: {
    id: "c62ec4c6-3656-4851-8c98-728ba0f7ecd6",
    defaultMessage: "Last name",
  },
});

function CosignerSubForm({
  fieldNamePrefix = "",
  displayEmail,
  displayPhoneNumber,
  displayPronouns,
  phoneCountryCode,
  showSignatoryCapacity,
  showCustomPronouns,
  readOnly,
}: Props) {
  const intl = useIntl();
  const [includePronounsField, setIncludePronounsField] = useState<boolean>(false);

  const nameFieldNames = [
    `${fieldNamePrefix}firstName`,
    `${fieldNamePrefix}middleName`,
    `${fieldNamePrefix}lastName`,
  ] as const;
  const [firstNameFieldName, middleNameFieldName, lastNameFieldName] = nameFieldNames;
  const phoneFieldNames = [
    `${fieldNamePrefix}phoneCountryCode`,
    `${fieldNamePrefix}phoneNumber`,
  ] as const;
  const [countryCodeFieldName, phoneNumberFieldName] = phoneFieldNames;
  const signatoryFieldName = `${fieldNamePrefix}signatoryTitle`;
  const emailFieldName = `${fieldNamePrefix}email`;

  return (
    <div>
      <DeprecatedFormRow>
        <FormGroup fields={[firstNameFieldName]} disableFormRowStyle>
          <DeprecatedTextField
            data-automation-id="cosigner-first-name-field"
            name={firstNameFieldName}
            useStyledInput
            displayRequiredAsterisk={!readOnly}
            autoComplete="given-name"
            useNativeLabel
            label={intl.formatMessage(MESSAGES.first)}
            disabled={readOnly}
          />
          <FormGroupErrors fields={[firstNameFieldName]} />
        </FormGroup>
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <FormGroup fields={[middleNameFieldName]} disableFormRowStyle>
          <DeprecatedTextField
            data-automation-id="cosigner-middle-name-field"
            name={middleNameFieldName}
            useStyledInput
            autoComplete="additional-name"
            useNativeLabel
            label={intl.formatMessage(MESSAGES.middle)}
            disabled={readOnly}
          />
        </FormGroup>
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <FormGroup fields={[lastNameFieldName]} disableFormRowStyle>
          <DeprecatedTextField
            data-automation-id="cosigner-last-name-field"
            name={lastNameFieldName}
            useStyledInput
            displayRequiredAsterisk={!readOnly}
            autoComplete="family-name"
            useNativeLabel
            label={intl.formatMessage(MESSAGES.last)}
            disabled={readOnly}
          />
          <FormGroupErrors fields={[lastNameFieldName]} />
        </FormGroup>
      </DeprecatedFormRow>
      {displayPronouns &&
        (includePronounsField ? (
          <PronounsSubform
            formName={fieldNamePrefix}
            showCustomPronouns={showCustomPronouns}
            autoFocusOnMount
            useNativeLabel
            autoBlur={false}
            disabled={readOnly}
          />
        ) : (
          <Button
            buttonColor="action"
            variant="tertiary"
            buttonSize="condensed"
            onClick={() => setIncludePronounsField(true)}
            disabled={readOnly}
          >
            <FormattedMessage
              id="69d604ee-a28b-48ab-a58a-b4524b2d489c"
              defaultMessage="+ Add pronouns (optional)"
              tagName="span"
            />
          </Button>
        ))}
      {displayEmail && (
        <DeprecatedFormRow className="email">
          <FormGroup fields={[emailFieldName]} disableFormRowStyle>
            <DeprecatedTextField
              type="email"
              name={emailFieldName}
              data-automation-id="email-field"
              useNativeLabel
              label={intl.formatMessage(MESSAGES.email)}
              autoComplete="email"
              useStyledInput
              displayRequiredAsterisk={!readOnly}
              disabled={readOnly}
            />
            <FormGroupErrors fields={[emailFieldName]} />
          </FormGroup>
        </DeprecatedFormRow>
      )}
      {showSignatoryCapacity && (
        <DeprecatedFormRow>
          <DeprecatedTextField
            name={signatoryFieldName}
            data-automation-id={`${fieldNamePrefix}-signatory-title`}
            aria-label={intl.formatMessage(MESSAGES.signatory)}
            placeholder={intl.formatMessage(MESSAGES.signatory)}
            placeholderAsLabel
            useStyledInput
            displayRequiredAsterisk={!readOnly}
            disabled={readOnly}
          />
          <FormGroupErrors fields={[signatoryFieldName]} />
        </DeprecatedFormRow>
      )}
      {displayPhoneNumber && (
        <>
          <FormGroup fields={phoneFieldNames} disableFormRowStyle>
            <DeprecatedFormRow>
              <DeprecatedMultipartRow>
                <DeprecatedMultipartColumn width={3}>
                  <DeprecatedPhoneCountryCodeField
                    name={countryCodeFieldName}
                    data-automation-id="phone-country-code-field"
                    labelRequiredAsterisk={displayPhoneNumber === "required" && !readOnly}
                    disabled={readOnly}
                  />
                </DeprecatedMultipartColumn>
                <DeprecatedMultipartColumn width={9}>
                  <DeprecatedPhoneNumberField
                    name={phoneNumberFieldName}
                    data-automation-id="phone-number-field"
                    isInternational={deprecatedIsInternational({ countryCode: phoneCountryCode })}
                    labelRequiredAsterisk={displayPhoneNumber === "required" && !readOnly}
                    disabled={readOnly}
                  />
                </DeprecatedMultipartColumn>
              </DeprecatedMultipartRow>
            </DeprecatedFormRow>
          </FormGroup>
          <FormGroupErrors fields={phoneFieldNames} />
        </>
      )}
    </div>
  );
}

export default CosignerSubForm;
