import { Fragment, type ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import type { FieldArrayFieldsProps } from "redux-form";

import { HeaderAction } from "common/form/sub_form/section/header_action";
import { DOCUMENT_UPLOADER_COSIGNER_REQUIRED } from "constants/aria-describedby";
import { EVENT } from "constants/analytics";
import { segmentTrack } from "util/segment";
import Button from "common/core/button";
import { DeprecatedFormRow } from "common/form/elements/row";
import Icon from "common/core/icon";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { RadioGroup } from "common/core/form/option";
import SectionHeader from "common/form/sub_form/section/header";
import SubFormSection from "common/form/sub_form/section";
import type { CustomerSignerFromForm } from "common/transactions/transaction_v2";
import { usePermissions } from "common/core/current_user_role";
import SigningGroupSection from "common/form/sub_forms/signing_group";

import RepresentativeSignersSection from "../representative_signers";
import { ProofSection } from "./proof";

const messages = defineMessages({
  addAdditionalSigner: {
    id: "c933d1c7-00af-42d0-aece-e68d3ebaca63",
    defaultMessage: "+ Add additional signer",
  },
  additionalSignerRemove: {
    id: "3c2848a4-f3d2-44e1-a121-956b70735fe8",
    defaultMessage: "- Remove",
  },
  additionalSignerDisabled: {
    id: "82db4b32-4b85-4209-82c3-ef1fc1c4b62e",
    defaultMessage:
      "You have selected 'Yes' to indicate that the notary personally knows the signer. We only support this feature for transactions with one signer. Change your response to 'No' to add additional signers.",
  },
  cosignerLabel: {
    id: "73f2f0b1-e670-4590-8cdf-6251972017d6",
    defaultMessage: "Additional signer",
  },
  cosignerRequired: {
    id: "e14ed2c9-cb5f-4b29-a203-36730cc7a99f",
    defaultMessage: "Does this transaction require additional signers?",
  },
  cosignerRequiredSubtitle: {
    id: "0f2c3915-402d-4483-9f7f-fd89eaeed8d3",
    defaultMessage:
      "Do any of the documents you're sending require signatures from more than 1 person? " +
      "This does not include witnesses.",
  },
});

type Fields = FieldArrayFieldsProps<{
  name?: string;
  email?: string;
  ial2Proof?: boolean;
  smsAuthProof?: boolean;
  kbaProof?: boolean;
}>;

type Props = {
  isProof: boolean;
  personallyKnownToNotary: boolean;
  fields: Fields;
  maxSigners: number;
  addCosigner: () => void;
  removeCosigner: (index: number) => void;
  renderCosignerSubForm: (field: string, index: number) => ReactNode;
  signerDetails: CustomerSignerFromForm[];
  change(name: string, value: boolean): void;
  readOnly: boolean;
};

export function CosignersSection({
  change,
  isProof,
  fields,
  maxSigners,
  addCosigner,
  removeCosigner,
  renderCosignerSubForm,
  personallyKnownToNotary,
  signerDetails,
}: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  return (
    <>
      {fields.length < 2 && (
        <SubFormSection className="SignerDetailsSection--Cosigner" automationId="cosignerForm">
          <DeprecatedFormRow>
            <RadioGroup
              className="SignerDetailsSection--Cosigner--RadioGroup"
              label={intl.formatMessage(messages.cosignerRequired)}
              helperText={intl.formatMessage(messages.cosignerRequiredSubtitle)}
            >
              <DeprecatedRadioButtonField
                aria-describedby={DOCUMENT_UPLOADER_COSIGNER_REQUIRED}
                name="cosigner"
                labelText="No"
                automationId="cosigner-not-required-radio-button"
                radioValue={false}
                groupValue={fields.length > 1}
                size="small"
                onChange={() => removeCosigner(1)}
                disabled={!hasPermissionFor("editTransactionSigners") || personallyKnownToNotary}
              />
              <DeprecatedRadioButtonField
                aria-describedby={DOCUMENT_UPLOADER_COSIGNER_REQUIRED}
                name="cosigner"
                labelText="Yes"
                automationId="cosigner-required-radio-button"
                radioValue
                groupValue={fields.length > 1}
                size="small"
                onChange={addCosigner}
                disabled={!hasPermissionFor("editTransactionSigners") || personallyKnownToNotary}
              />
            </RadioGroup>
            {personallyKnownToNotary && (
              <div className="SignerDetailsSection--Warning">
                <div className="SignerDetailsSection--Warning--Icon">
                  <Icon name="disclaimer" />
                </div>
                <div className="SignerDetailsSection--Warning--Text">
                  {intl.formatMessage(messages.additionalSignerDisabled)}
                </div>
              </div>
            )}
          </DeprecatedFormRow>
        </SubFormSection>
      )}
      {fields.length > 1 &&
        fields.map((signerField, index) => {
          if (!index) {
            return null;
          }
          return signerDetails[index]?.recipientGroup ? (
            <Fragment key={index}>
              <SigningGroupSection
                fieldNamePrefix={signerField}
                title={intl.formatMessage(messages.cosignerLabel)}
                sectionClass="SignerDetailsSection--Signers"
                formRowClass="SignerDetailsSection--Signers--signer SignerDetailsSection--Signers--cosigner"
              />
            </Fragment>
          ) : (
            <Fragment key={index}>
              <SubFormSection className="SignerDetailsSection--Signers">
                <DeprecatedFormRow className="SignerDetailsSection--Signers--signer SignerDetailsSection--Signers--cosigner">
                  <SectionHeader
                    action={
                      <>
                        {hasPermissionFor("editTransactionSigners") && (
                          <HeaderAction
                            onClick={() => {
                              removeCosigner(index);
                            }}
                          >
                            {intl.formatMessage(messages.additionalSignerRemove)}
                          </HeaderAction>
                        )}
                      </>
                    }
                    title={intl.formatMessage(messages.cosignerLabel)}
                  />
                  {renderCosignerSubForm(signerField, index)}
                </DeprecatedFormRow>
              </SubFormSection>
              <RepresentativeSignersSection
                formName="EditTransactionForm"
                fieldNamePrefix={`${signerField}.capacities`}
              />
              {isProof && (
                <ProofSection change={change} fieldNamePrefix={signerField} signerIndex={index} />
              )}
            </Fragment>
          );
        })}

      {fields.length > 1 &&
        fields.length < maxSigners &&
        hasPermissionFor("editTransactionSigners") && (
          <SubFormSection className="SignerDetailsSection--Signers">
            <DeprecatedFormRow>
              <Button buttonColor="action" variant="tertiary" onClick={addCosigner}>
                {intl.formatMessage(messages.addAdditionalSigner)}
              </Button>
            </DeprecatedFormRow>
          </SubFormSection>
        )}
    </>
  );
}

export function useCosignerManager(
  fields: Fields,
  maxSigners: number,
  defaultProofRequirement?: {
    ial2Proof: boolean;
    smsAuthProof: boolean;
    kbaProof: boolean;
  },
) {
  return {
    addCosigner() {
      if (fields.length < maxSigners) {
        segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ADD_COSIGNER);
        fields.push({ ...defaultProofRequirement });
      }
    },
    removeCosigner(signerIndex: number) {
      segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_REMOVE_COSIGNER, {
        signerIndex,
      });
      fields.remove(signerIndex);
    },
  };
}
