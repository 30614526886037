import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import PaginationCaret from "common/pagination/caret";

import type { Signer } from ".";
import Styles from "./import_loop.module.scss";
import { getParticipants } from "./requests";

type Props = {
  onCancel: () => void;
  onGoBack: () => void;
  goToNextStep: () => void;
  profileId: number;
  loopId: number;
  initialSelectedSigners: Set<Signer>;
  onSelectSigners: (signers: Set<Signer>) => void;
};

function signersToIds(signers: Signer[] | Set<Signer>) {
  return new Set(Array.from(signers).map((signer: Signer) => signer.dotloop_id));
}

export default function AddSigners({
  onCancel,
  onGoBack,
  goToNextStep,
  profileId,
  loopId,
  initialSelectedSigners,
  onSelectSigners,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [selectedSignerIds, setSelectedSignerIds] = useState<Set<Signer["dotloop_id"]>>(
    signersToIds(initialSelectedSigners),
  );

  const handleContinue = (selectedIds: Set<Signer["dotloop_id"]>) => {
    const selected = new Set(signers.filter((s) => selectedIds.has(s.dotloop_id)));
    onSelectSigners(selected);
    goToNextStep();
  };

  const changeSelection = (signerId: Signer["dotloop_id"]) => {
    if (selectedSignerIds.has(signerId)) {
      selectedSignerIds.delete(signerId);
    } else {
      selectedSignerIds.add(signerId);
    }
    setSelectedSignerIds(new Set(selectedSignerIds));
  };

  useEffect(() => {
    let canceled = false;
    setLoading(true);

    getParticipants(profileId, loopId)
      .then((data: Signer[]) => {
        if (!canceled) {
          setSigners(data);
        }
      })
      .finally(() => {
        if (!canceled) {
          setLoading(false);
        }
      });

    return () => {
      canceled = true;
    };
  }, [profileId, loopId]);

  return (
    <div>
      <div className={Styles.selectSignersTitle}>
        <FormattedMessage
          id="10686f54-9ce2-40c9-b889-f35670c8c4b0"
          defaultMessage="Who will be signing the documents in this transaction?"
        />
      </div>

      <table className={Styles.signersTable}>
        <thead className={Styles.thead}>
          <tr>
            <FormattedMessage
              id="1fc5bce7-5702-4507-83c0-79f9b73dffa6"
              tagName="th"
              defaultMessage="Name"
            />
            <FormattedMessage
              id="fb6e9cef-4241-4882-98b2-99d8156d8d49"
              tagName="th"
              defaultMessage="Email"
            />
            <FormattedMessage
              id="2a0d3950-4d62-4925-bc3d-f8820aed52e7"
              tagName="th"
              defaultMessage="Role"
            />
          </tr>
        </thead>
        <tbody className={Styles.tbody}>
          {loading ? (
            <LoadingIndicator />
          ) : signers.length ? (
            signers.map((signer) => (
              <tr key={signer.dotloop_id}>
                <td>
                  <DeprecatedCheckboxWithLabel
                    key={signer.dotloop_id}
                    className={Styles.checkboxListItems}
                    label={`${signer.first_name} ${signer.last_name}`}
                    checked={selectedSignerIds.has(signer.dotloop_id)}
                    onChange={() => changeSelection(signer.dotloop_id)}
                  />
                </td>
                <td onClick={() => changeSelection(signer.dotloop_id)}>{signer.email}</td>
                <td onClick={() => changeSelection(signer.dotloop_id)}>{signer.dotloop_role}</td>
              </tr>
            ))
          ) : (
            <FormattedMessage
              id="97bad91e-ff87-4843-9f02-4bbfe2213450"
              defaultMessage="No signers to show for this loop."
            />
          )}
        </tbody>
      </table>

      <div className={Styles.buttons}>
        <Button
          className={Styles.backButton}
          onClick={onGoBack}
          variant="secondary"
          buttonColor="action"
        >
          <PaginationCaret prev />
          <FormattedMessage id="76e46050-71d1-4a20-9675-113d5d5e2ec7" defaultMessage="Back" />
        </Button>
        <Button onClick={onCancel} variant="secondary" buttonColor="action">
          <FormattedMessage id="10686f54-9ce2-40c9-b889-f35670c8c2b0" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={() => handleContinue(selectedSignerIds)}
          disabled={selectedSignerIds.size === 0}
          variant="primary"
          buttonColor="action"
        >
          <FormattedMessage
            id="10686f54-9ce2-40c9-b889-f35670c8c2b1"
            defaultMessage="Add {signerCount, plural, one{1 Signer} other{# Signers}} to Transaction"
            values={{ signerCount: selectedSignerIds.size }}
          />
        </Button>
      </div>
    </div>
  );
}
