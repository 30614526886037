import "./index.scss";

import type { ReactElement } from "react";
import { FieldArray, type FieldArrayFieldsProps, type InjectedFormProps } from "redux-form";
import { defineMessages, useIntl } from "react-intl";

import Button from "common/core/button";
import { usePermissions } from "common/core/current_user_role";
import SectionHeader from "common/form/sub_form/section/header";
import { HeaderAction } from "common/form/sub_form/section/header_action";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { userCanEditContact } from "util/points_of_contact";
import { OrganizationTransactionContactRoleType } from "graphql_globals";
import { COLLABORATORS } from "constants/points_of_contact";

import PointOfContact from "./point_of_contact";

const MAX_NUM_POINTS_OF_CONTACT = 50;

const messages = defineMessages({
  pointOfContactHeader: {
    id: "f0175f6c-2e05-4f82-97a0-a8396e221b81",
    defaultMessage: "Point of contact",
  },
  addPointOfContactButton: {
    id: "c117e299-fb76-431a-bdb8-13408d8a2bb8",
    defaultMessage: "+ Add point of contact",
  },
  addContactButton: {
    id: "dcfaa020-f2fc-49de-bea1-83d765470780",
    defaultMessage: "+ Add contact (optional)",
  },
  addCollaboratorButton: {
    id: "3ceea024-0bb6-4b66-8550-8240dabf18d5",
    defaultMessage: "+ Add another collaborator (optional)",
  },
  pointOfContactHeaderActionText: {
    id: "4418a165-ee5b-4ae2-8f2f-e783aed102ae",
    defaultMessage: "- Remove",
  },
});

type Props = {
  addCollaboratorOption?: boolean;
  allowAccessToDocumentsOption?: boolean;
  organization: {
    id: string;
    titleAgencyAccess?: boolean;
    realEstateCollabEnabled?: boolean;
  };
  isCollab?: boolean;
  hasCollaboratorSection?: boolean;
  customContactFilter?: (index: number) => boolean;
  renderCustomContact?: (member: string, pointOfContact: PointOfContactType) => ReactElement;
};

type PointOfContactType = {
  index?: number;
  onClickRemove?: (index: number) => void;
  accessToTransaction?: boolean;
  id?: string;
  authorOrganizationId?: string;
  email?: string | null;
  phoneNumber?: string | null;
  firstName?: string;
  lastName?: string;
  role?: OrganizationTransactionContactRoleType;
  shownToSigner?: boolean;
  title?: string | null;
  organizationName?: string | null;
};

type InnerProps = InjectedFormProps<Props> &
  Props & {
    fields: FieldArrayFieldsProps<PointOfContactType>;
  };

function PointsOfContact({
  fields,
  allowAccessToDocumentsOption,
  organization,
  customContactFilter,
  renderCustomContact,
  isCollab,
  addCollaboratorOption,
  change,
  touch,
}: InnerProps) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();

  const handleAddPointOfContactClick = () => {
    if (fields.length === MAX_NUM_POINTS_OF_CONTACT) {
      return;
    }
    fields.push({ shownToSigner: true });
  };

  const handleRemoveClick = (index: number) => {
    fields.remove(index);
    change("hasPointsOfContact", false);
    touch("hasPointsOfContact");
  };

  const handleAddCollaboratorClick = () => {
    if (fields.length === MAX_NUM_POINTS_OF_CONTACT) {
      return;
    }
    fields.push({
      accessToTransaction: true,
      onClickRemove: handleRemoveClick,
      role: OrganizationTransactionContactRoleType.TITLE_AGENT,
      shownToSigner: true,
    });
  };

  const hasNoContacts = fields.length === 0;

  // All roles should appear in the drop down if real-estate-collab is OFF,
  // non-onboarded-title-agent is OFF, or the transaction is NOT a HELOC.
  // If non-onboarded-title-agent is ON, only show contact roles in the drop
  // down because collaborators will be selected separately
  const withCollaboratorRoles = !isCollab || !addCollaboratorOption;

  const PointOfContactComponents = fields.map((member, index, fields) => {
    const pointOfContact = fields.get(index) as undefined | PointOfContactType;

    // We only want readOnly to be calculated if the authorOrganizationId is present - otherwise, we return false.
    // This is to handle the case where the point of contact has not been sent to the backend yet and does not have
    // an authorOrganizationId because it's still being created.
    const readOnly = !userCanEditContact({
      contact: pointOfContact!,
      organization,
      canEditUnownedTransaction: hasPermissionFor("editUnownedTransaction"),
    });

    if (
      addCollaboratorOption &&
      renderCustomContact &&
      pointOfContact?.role &&
      COLLABORATORS.includes(pointOfContact.role)
    ) {
      return renderCustomContact(member, {
        ...pointOfContact,
        index,
        onClickRemove: handleRemoveClick,
      });
    }
    if (customContactFilter?.(index)) {
      return renderCustomContact?.(member, pointOfContact!);
    }

    return (
      <div key={index} className="PointOfContactContainer">
        <SectionHeader
          action={
            !readOnly ? (
              <HeaderAction onClick={() => handleRemoveClick(index)} automationId="remove-contact">
                {intl.formatMessage(messages.pointOfContactHeaderActionText)}
              </HeaderAction>
            ) : null
          }
          title={intl.formatMessage(messages.pointOfContactHeader, { pocNum: null })}
        />
        <PointOfContact
          fieldNamePrefix={member}
          pointOfContact={pointOfContact!}
          allowAccessToDocumentsOption={allowAccessToDocumentsOption}
          readOnly={readOnly}
          withCollaborators={withCollaboratorRoles}
        />
      </div>
    );
  });
  return (
    <div className="PointsOfContactContainer" data-automation-id="points-of-contact-container">
      {hasNoContacts && (
        <DeprecatedFormRow>
          <DeprecatedRadioButtonField
            name="hasPointsOfContact"
            labelText="No"
            automationId="no-points-of-contact-radio-button"
            groupValue={!hasNoContacts}
            radioValue={false}
            size="small"
          />
          <DeprecatedRadioButtonField
            name="hasPointsOfContact"
            labelText="Yes"
            automationId="yes-points-of-contact-radio-button"
            groupValue={!hasNoContacts}
            radioValue
            size="small"
            onChange={handleAddPointOfContactClick}
          />
        </DeprecatedFormRow>
      )}
      {PointOfContactComponents}
      {!hasNoContacts && fields.length < MAX_NUM_POINTS_OF_CONTACT && (
        <>
          {addCollaboratorOption ? (
            <>
              <DeprecatedFormRow>
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  onClick={handleAddPointOfContactClick}
                  automationId="add-contact-button"
                >
                  {intl.formatMessage(messages.addContactButton)}
                </Button>
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  onClick={handleAddCollaboratorClick}
                  automationId="add-collaborator-button"
                >
                  {intl.formatMessage(messages.addCollaboratorButton)}
                </Button>
              </DeprecatedFormRow>
            </>
          ) : (
            <DeprecatedFormRow>
              <Button
                variant="tertiary"
                buttonColor="action"
                buttonSize="condensed"
                onClick={handleAddPointOfContactClick}
                automationId="add-point-of-contact-button"
              >
                {intl.formatMessage(messages.addPointOfContactButton)}
              </Button>
            </DeprecatedFormRow>
          )}
        </>
      )}
    </div>
  );
}

function EnhancedPointsOfContacts(props: Props) {
  return (
    <FieldArray
      {...props}
      name="pointsOfContact"
      component={PointsOfContact}
      rerenderOnEveryChange
      isCollab={Boolean(props.organization.realEstateCollabEnabled)}
      // hasCollaboratorSection will be true if non-onboarded-title is enabled and
      // the transaction type is not HELOC as defined in the lender transaction container
      addCollaboratorOption={props.hasCollaboratorSection}
    />
  );
}

export default deprecatedSubForm<Props>({
  getValuesFor: ["pointsOfContact", "hasPointsOfContact"],
})(EnhancedPointsOfContacts);
