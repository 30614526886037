import "./index.scss";

import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { FieldArray } from "redux-form";

import { DOCUMENT_UPLOADER_PRIMARY_SIGNER } from "constants/aria-describedby";
import { EVENT } from "constants/analytics";
import { Feature, AuthTypes } from "graphql_globals";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import { segmentTrack } from "util/segment";
import { useFeatureFlag } from "common/feature_gating";
import AddressSubForm from "common/form/sub_forms/address";
import Button from "common/core/button";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";
import CosignerSubForm from "common/form/sub_forms/cosigner";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedFormRow } from "common/form/elements/row";
import Icon from "common/core/icon";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import SectionHeader from "common/form/sub_form/section/header";
import SignerSubForm from "common/form/sub_forms/signer";
import SigningGroupSection from "common/form/sub_forms/signing_group";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import TipWell from "common/core/tip_well";
import Tooltip from "common/core/tooltip";
import { usePermissions } from "common/core/current_user_role";

import RepresentativeSignersSection from "../representative_signers";
import CredibleWitnessSection from "../credible_witness";
import { ProofSection, includeSMSVerification } from "./proof";
import { SecondaryIdSection } from "./secondary_id";
import { CosignersSection, useCosignerManager } from "./cosigners";

const messages = defineMessages({
  primarySignerLabel: {
    id: "6e664fdf-3107-4d63-ae0c-2695138f7345",
    defaultMessage: "Primary signer",
  },
  showAddress: {
    id: "4152f7ff-98dd-4ca3-a9a3-6db7341870fe",
    defaultMessage: "+ Add signer address",
  },
  removeAddress: {
    id: "0f58726a-fd54-49da-a99e-dc68cd3a2ab6",
    defaultMessage: "- Remove address",
  },
  countryCode: {
    id: "f05b34c0-66ab-43cd-bd9b-01711cab33aa",
    defaultMessage: "Country code",
  },
  phoneNumber: {
    id: "b7dfd8fc-de1d-4a60-8567-c726b630a1a2",
    defaultMessage: "Phone number",
  },
  phoneTipHeader: {
    id: "3b8bfda5-382f-42de-8a11-1a593d67d1d8",
    defaultMessage: "Why should I add a phone number?",
  },
  phoneTip: {
    id: "00c5c4be-da23-4785-a470-72fbbe3144f4",
    defaultMessage:
      "Text messages have a better response rate. " +
      "The signer will be sent a link to the transaction via SMS in addition to the email.",
  },
  upgradeToUnlock: {
    id: "47acfee9-c6c9-4779-9814-ab1a922d6e77",
    defaultMessage: "Upgrade to unlock",
  },
  notifySignerSms: {
    id: "8cbd02a9-1271-4fcf-9cb6-9960313f6736",
    defaultMessage: "Notify signer via SMS",
  },
  upgradeNotifySignerSms: {
    id: "0b2e66c6-4905-4e1f-9b58-4880a72c95e2",
    defaultMessage: "Upgrade to Pro to notify your signers via text ",
  },
  requireSmsAuthentication: {
    id: "25dd6da4-f445-4162-bd19-b0b171247dd9",
    defaultMessage: "Require SMS authentication",
  },
  smsAuthenticationTooltip: {
    id: "23d20f63-9df0-4614-8bd5-723ea9342397",
    defaultMessage:
      "The signer will need to verify their identity by entering an authentication code sent to their mobile phone.",
  },
  tooltipTriggerLabel: {
    id: "311a693d-c4cc-48c4-abe0-e85ad2115573",
    defaultMessage: "More details about SMS Authentication",
  },
});

// NOTE: make sure this is defined outside the same class that uses FieldArray
// otherwise every render will cause the fields to re-register
// https://github.com/erikras/redux-form/issues/2412
function SignerDetailsSection(props) {
  const {
    fields,
    formName,
    formValues: {
      signerDetails,
      smsAuthenticationRequired,
      organizationTransactionWitnesses,
      personallyKnownToNotary,
    },
    organization: { featureList, defaultAuthenticationRequirement, defaultVerifiedEsignEnabled },
    hideAddress,
    hideSecondaryId,
    maxSigners,
    displayCosignerEmail,
    showSignatoryCapacity,
    change,
    formType,
    formErrors,
  } = props;

  const intl = useIntl();

  const [primarySigner] = fields.map((signer) => signer);
  const signerHasAddress = signerDetails?.[0]?.address;
  const primaryRecipientGroup = signerDetails?.[0]?.recipientGroup;

  const { hasPermissionFor } = usePermissions();
  const showAdvancedTransactionDetails = featureList.includes(
    Feature.ADVANCED_TRANSACTION_CREATION,
  );
  const showSecondaryId = showAdvancedTransactionDetails && !hideSecondaryId;
  const smsInvitationEnabled = featureList.includes(Feature.SMS_INVITATION);
  const isPhoneDisabled = !smsInvitationEnabled || !hasPermissionFor("editTransactionSigners");
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  const isProofTransaction = proofTransactionsEnabled && formType === "proof";
  const isNotarization = formType === "notarization";
  const isEsignTransaction = formType === "esign";
  // [BIZ-4243] To remove proof-transactions flag
  const isRegularEsignTransactionWithFlagOff = !proofTransactionsEnabled && isEsignTransaction;
  const hasSmsAuthRequirement = defaultAuthenticationRequirement === AuthTypes.SMS;
  const showDeprecatedSmsAuthRequiredCheckbox =
    hasSmsAuthRequirement && (isRegularEsignTransactionWithFlagOff || isNotarization);
  const showCredibleWitnessSection =
    isNotarization && featureList.includes(Feature.CREDIBLE_WITNESS);

  const phoneTip = !smsInvitationEnabled ? null : (
    <TipWell heading={intl.formatMessage(messages.phoneTipHeader)}>
      {intl.formatMessage(messages.phoneTip)}
    </TipWell>
  );

  const { addCosigner, removeCosigner } = useCosignerManager(
    fields,
    maxSigners,
    isProofTransaction
      ? {
          smsAuthProof: hasSmsAuthRequirement,
          kbaProof: defaultVerifiedEsignEnabled,
        }
      : undefined,
  );

  function addSignerAddress(signerIndex) {
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ADD_SIGNER_ADDR, {
      signerIndex,
    });

    change(`signerDetails[${signerIndex}].address`, {});
  }

  function removeSignerAddress(signerIndex) {
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_REMOVE_SIGNER_ADDR, {
      signerIndex,
    });
    change(`signerDetails[${signerIndex}].address`, null);
  }

  const smsCheckbox = showDeprecatedSmsAuthRequiredCheckbox && (
    <FormGroup fields={["smsAuthenticationRequired"]} disableFormRowStyle>
      <DeprecatedCheckboxField
        containerClassname="SignerDetailsSection"
        automationId="sms-authentication-required"
        name="smsAuthenticationRequired"
        label={intl.formatMessage(messages.requireSmsAuthentication)}
      />
      <Tooltip
        target={<Icon name="question" className={"SignerDetailsSection--TooltipIcon"} />}
        placement="bottom"
        className={"SignerDetailsSection--Tooltip"}
        triggerButtonLabel={intl.formatMessage(messages.tooltipTriggerLabel)}
      >
        {intl.formatMessage(messages.smsAuthenticationTooltip)}
      </Tooltip>
    </FormGroup>
  );

  return (
    <>
      <SubForm className="SignerDetailsSection">
        {primaryRecipientGroup ? (
          <SigningGroupSection
            fieldNamePrefix={primarySigner}
            title={intl.formatMessage(messages.primarySignerLabel)}
            sectionClass="SignerDetailsSection--Signers--signer"
          >
            {smsCheckbox}
          </SigningGroupSection>
        ) : (
          <>
            <SubFormSection
              className="SignerDetailsSection--Signers--signer"
              key={primarySigner}
              tipWell={phoneTip}
            >
              {smsCheckbox}

              <DeprecatedFormRow noMargin>
                <SectionHeader
                  titleId={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
                  title={intl.formatMessage(messages.primarySignerLabel)}
                />
              </DeprecatedFormRow>

              <DeprecatedFormRow noMargin>
                <SignerSubForm
                  fieldNamePrefix={primarySigner}
                  displayRequiredAsterisk
                  showSignatoryCapacity={showSignatoryCapacity}
                  showEmail
                  readOnly={!hasPermissionFor("editTransactionSigners")}
                />
              </DeprecatedFormRow>

              {primarySigner && (
                <>
                  {!smsInvitationEnabled && (
                    <DeprecatedFormRow noMargin>
                      <SectionHeader
                        title={intl.formatMessage(messages.notifySignerSms)}
                        lockedMessage={intl.formatMessage(messages.upgradeNotifySignerSms)}
                      />
                    </DeprecatedFormRow>
                  )}
                  <DeprecatedFormRow>
                    <FormGroup
                      className="SignerSubForm--FormGroup"
                      errorClassName="SignerSubForm__validationFailed"
                      fields={[`${primarySigner}.phoneCountryCode`, `${primarySigner}.phoneNumber`]}
                      disableFormRowStyle
                    >
                      <DeprecatedMultipartRow>
                        <DeprecatedMultipartColumn width={3}>
                          <DeprecatedPhoneCountryCodeField
                            name={`${primarySigner}.phoneCountryCode`}
                            data-automation-id="phone-country-code-field"
                            label={intl.formatMessage(messages.countryCode)}
                            labelRequiredAsterisk={
                              isProofTransaction
                                ? includeSMSVerification(signerDetails[0])
                                : smsAuthenticationRequired
                            }
                            disabled={isPhoneDisabled}
                          />
                        </DeprecatedMultipartColumn>
                        <DeprecatedMultipartColumn width={9}>
                          <DeprecatedPhoneNumberField
                            name={`${primarySigner}.phoneNumber`}
                            data-automation-id="phone-number-field"
                            isInternational={deprecatedIsInternational({
                              countryCode: signerDetails?.[0]?.phoneCountryCode || false,
                            })}
                            label={intl.formatMessage(messages.phoneNumber)}
                            labelRequiredAsterisk={
                              isProofTransaction
                                ? includeSMSVerification(signerDetails[0])
                                : smsAuthenticationRequired
                            }
                            disabled={isPhoneDisabled}
                          />
                        </DeprecatedMultipartColumn>
                      </DeprecatedMultipartRow>
                    </FormGroup>
                    <FormGroupErrors
                      fields={[`${primarySigner}.phoneCountryCode`, `${primarySigner}.phoneNumber`]}
                      groupClassName="SignerSubForm--FormGroup"
                      errorClassName="SignerSubForm--validationMessage"
                    />
                  </DeprecatedFormRow>
                </>
              )}

              {primarySigner &&
                !signerHasAddress &&
                showAdvancedTransactionDetails &&
                !hideAddress &&
                hasPermissionFor("editTransactionSigners") && (
                  <DeprecatedFormRow>
                    <Button
                      variant="tertiary"
                      buttonSize="condensed"
                      buttonColor="action"
                      onClick={() => addSignerAddress(0)}
                    >
                      {intl.formatMessage(messages.showAddress)}
                    </Button>
                  </DeprecatedFormRow>
                )}
              {signerHasAddress && showAdvancedTransactionDetails && !hideAddress && (
                <AddressSubForm
                  formName={formName}
                  fieldNamePrefix={`${primarySigner}.address`}
                  useStyledInputs
                  disabled={!hasPermissionFor("editTransactionSigners")}
                />
              )}

              {primarySigner &&
                signerHasAddress &&
                showAdvancedTransactionDetails &&
                !hideAddress &&
                hasPermissionFor("editTransactionSigners") && (
                  <DeprecatedFormRow>
                    <Button
                      variant="tertiary"
                      buttonColor="action"
                      buttonSize="condensed"
                      onClick={() => removeSignerAddress(0)}
                    >
                      {intl.formatMessage(messages.removeAddress)}
                    </Button>
                  </DeprecatedFormRow>
                )}
            </SubFormSection>
            <RepresentativeSignersSection fieldNamePrefix={`${primarySigner}.capacities`} />
          </>
        )}
      </SubForm>

      {!primaryRecipientGroup && isProofTransaction && (
        <ProofSection
          change={change}
          formErrors={formErrors}
          fieldNamePrefix={primarySigner}
          signerIndex={0}
        />
      )}

      <SubForm>
        <SubFormSectionDivider />

        <CosignersSection
          change={change}
          isProof={isProofTransaction}
          fields={fields}
          maxSigners={maxSigners}
          addCosigner={addCosigner}
          removeCosigner={removeCosigner}
          personallyKnownToNotary={personallyKnownToNotary}
          signerDetails={signerDetails}
          renderCosignerSubForm={(signerField, index) => (
            <CosignerSubForm
              fieldNamePrefix={signerField}
              displayEmail={displayCosignerEmail}
              readOnly={!hasPermissionFor("editTransactionSigners")}
              displayPhoneNumber={
                (smsAuthenticationRequired && smsInvitationEnabled) ||
                (isProofTransaction && includeSMSVerification(signerDetails[index]))
                  ? "required"
                  : smsInvitationEnabled
                    ? "shown"
                    : false
              }
              phoneCountryCode={signerDetails[index]?.phoneCountryCode}
              showSignatoryCapacity={showSignatoryCapacity}
            />
          )}
        />

        {showSecondaryId && <SecondaryIdSection />}

        {showCredibleWitnessSection && (
          <CredibleWitnessSection
            change={change}
            organizationTransactionWitnesses={organizationTransactionWitnesses}
          />
        )}
      </SubForm>
    </>
  );
}
SignerDetailsSection.defaultProps = {
  hideAddress: false,
  hideSecondaryId: false,
  maxSigners: 2,
  displayCosignerEmail: false,
};

SignerDetailsSection.propTypes = {
  formName: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  hideAddress: PropTypes.bool,
  hideSecondaryId: PropTypes.bool,
  maxSigners: PropTypes.number,
  displayCosignerEmail: PropTypes.bool,
  showSignatoryCapacity: PropTypes.bool,
  formType: PropTypes.oneOf(["esign", "notarization", "proof"]),

  // Redux Form
  fields: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,

  // deprecatedSubForm
  change: PropTypes.func.isRequired,
};

const SignerDetailsSectionWithForm = deprecatedSubForm({
  getValuesFor: [
    "signerDetails",
    "smsAuthenticationRequired",
    "organizationTransactionWitnesses",
    "personallyKnownToNotary",
  ],
})((props) => <FieldArray {...props} name="signerDetails" component={SignerDetailsSection} />);

function SingleUseSignerDetailsSection(props) {
  const {
    fields,
    formValues: { organizationTransactionWitnesses, personallyKnownToNotary },
    change,
    organization: { featureList },
    maxSigners,
    showSignatoryCapacity,
  } = props;

  const intl = useIntl();
  const { addCosigner, removeCosigner } = useCosignerManager(fields, maxSigners);
  const [primarySigner] = fields.map((signer) => signer);
  const showAdvancedTransactionDetails = featureList.includes(
    Feature.ADVANCED_TRANSACTION_CREATION,
  );
  const showCredibleWitnessSection = featureList.includes(Feature.CREDIBLE_WITNESS);

  return (
    <>
      <SubForm className="SignerDetailsSection">
        <SubFormSection className="SignerDetailsSection--Signers--signer" key={primarySigner}>
          <DeprecatedFormRow noMargin>
            <SectionHeader
              titleId={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
              title={intl.formatMessage(messages.primarySignerLabel)}
            />
          </DeprecatedFormRow>
          <DeprecatedFormRow noMargin>
            <SignerSubForm
              fieldNamePrefix={primarySigner}
              displayRequiredAsterisk
              showSignatoryCapacity={showSignatoryCapacity}
              showEmail={false}
            />
          </DeprecatedFormRow>
        </SubFormSection>
        <SubFormSectionDivider />
        <CosignersSection
          fields={fields}
          maxSigners={maxSigners}
          addCosigner={addCosigner}
          removeCosigner={removeCosigner}
          personallyKnownToNotary={personallyKnownToNotary}
          renderCosignerSubForm={(signerField) => (
            <CosignerSubForm
              fieldNamePrefix={signerField}
              showSignatoryCapacity={showSignatoryCapacity}
            />
          )}
        />
        {showAdvancedTransactionDetails && <SecondaryIdSection />}
        {showCredibleWitnessSection && (
          <CredibleWitnessSection
            change={change}
            organizationTransactionWitnesses={organizationTransactionWitnesses}
            featureList={featureList}
          />
        )}
      </SubForm>
    </>
  );
}

SingleUseSignerDetailsSection.propTypes = {
  organization: PropTypes.object.isRequired,
  maxSigners: PropTypes.number.isRequired,
  showSignatoryCapacity: PropTypes.bool,

  // Redux Form
  fields: PropTypes.object.isRequired,

  // deprecatedSubForm
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

const SingleUseSignerDetailsSectionWithForm = deprecatedSubForm({
  getValuesFor: ["signerDetails", "organizationTransactionWitnesses", "personallyKnownToNotary"],
})((props) => (
  <FieldArray {...props} name="signerDetails" component={SingleUseSignerDetailsSection} />
));

export default SignerDetailsSectionWithForm;
export { SignerDetailsSectionWithForm as SignerDetailsSection };
export { SingleUseSignerDetailsSectionWithForm as SingleUseSignerDetailsSection };
