import "./index.scss";

import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import SectionHeader from "common/form/sub_form/section/header";
import PaymentForm from "common/settings/payment/index.apollo";
import WorkflowModal from "common/modals/workflow_modal";
import type { TransactionEdit_organization_Organization as Organization } from "common/transactions/graphql/queries/edit_query.graphql";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { Payer } from "graphql_globals";
import { isNotaryNST } from "common/notary/capacity";
import { usePermissions } from "common/core/current_user_role";

type OrganizationSubset = {
  name: Organization["name"];
  defaultPayer: Organization["defaultPayer"];
  defaultPaymentSource: Organization["defaultPaymentSource"];
  paymentSpecified: Organization["paymentSpecified"];
  featureList: Organization["featureList"];
};
type FormValues = {
  payer: Payer;
};
type SubFormSubset = {
  formValues: FormValues;
  change: (field: string, value: string) => void;
};
type Props = SubFormSubset & {
  organization: OrganizationSubset;
  notaryProfile: Parameters<typeof isNotaryNST>[0];
};

function getAccountPaymentMethod({
  defaultPayer,
  defaultPaymentSource,
}: {
  defaultPayer: Organization["defaultPayer"];
  defaultPaymentSource: Organization["defaultPaymentSource"];
}) {
  if (defaultPayer !== Payer.CUSTOMER) {
    return defaultPayer;
  }

  switch (defaultPaymentSource?.__typename) {
    case "Card":
      return Payer.ORGANIZATION;
    case "AchAccount":
      return Payer.ORGANIZATION_ACH;
    default:
      return null;
  }
}

function hasAccountPaymentMethod({
  defaultPayer,
  defaultPaymentSource,
}: {
  defaultPayer: Organization["defaultPayer"];
  defaultPaymentSource: Organization["defaultPaymentSource"];
}) {
  return defaultPayer === Payer.NOTARIZE || Boolean(defaultPaymentSource);
}

function TransactionPaymentSection({ organization, notaryProfile, formValues, change }: Props) {
  const {
    name: organizationName,
    defaultPayer,
    defaultPaymentSource,
    paymentSpecified,
  } = organization;

  const accountPaymentMethod = useMemo(
    () => getAccountPaymentMethod({ defaultPayer, defaultPaymentSource }) || "",
    [defaultPayer, defaultPaymentSource],
  );
  const setToSigner = useCallback(() => change("payer", Payer.CUSTOMER), []);
  const setToNewPaymentMethod = useCallback(
    (options: Parameters<typeof getAccountPaymentMethod>[0]) =>
      change("payer", getAccountPaymentMethod(options) as string),
    [],
  );
  const { hasPermissionFor } = usePermissions();
  const canEditPaymentDetails = hasPermissionFor("editOrganizationDetails");
  const isNonAdminWithoutPayment =
    !canEditPaymentDetails && !hasAccountPaymentMethod({ defaultPayer, defaultPaymentSource });
  const fieldCx = classnames("TransactionPaymentSection--field", {
    "TransactionPaymentSection--field__disabled": isNonAdminWithoutPayment,
  });
  const isNst = isNotaryNST(notaryProfile);

  return (
    <>
      <div className="TransactionPaymentSection">
        <div className={fieldCx}>
          <SectionHeader
            title={
              <FormattedMessage
                id="439a7e04-5d21-40f2-b006-042e2687ab02"
                defaultMessage="{isNst, select, true{How will you bill the signer for this transaction?} other{Who should pay after the documents are completed?}}"
                values={{ isNst }}
              />
            }
          />
          <DeprecatedRadioButtonField
            automationId="payer-signer-radio-button"
            name="payer"
            labelText={
              <FormattedMessage
                id="daa92119-7231-4c90-bd6e-9385c2176f03"
                defaultMessage="{isNst, select, true{Pay on Proof} other{Signer}}"
                values={{ isNst }}
              />
            }
            radioValue={Payer.CUSTOMER}
            size="small"
            className="TransactionPaymentSection--radio"
          />
          <DeprecatedRadioButtonField
            automationId="payer-sender-radio-button"
            name="payer"
            labelText={
              <FormattedMessage
                id="0cea5031-c48b-43d8-80f9-7a2df9826a18"
                defaultMessage="{isNst, select, true{Bill signer outside of Proof} other{{organizationName}}}"
                values={{ isNst, organizationName }}
              />
            }
            radioValue={accountPaymentMethod}
            size="small"
            className="TransactionPaymentSection--radio"
          />
        </div>
        {isNonAdminWithoutPayment && (
          <p className="TransactionPaymentSection--non-admin-message">
            <FormattedMessage
              id="1e097dca-6c70-4e74-ada2-fde169d34abf"
              defaultMessage="Note: To select company as payer, an account admin needs to add a payment method."
            />
          </p>
        )}
      </div>
      {formValues.payer !== Payer.CUSTOMER && !accountPaymentMethod && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="70d85ae4-d27a-4a61-bc3a-9730b943bb2c"
              defaultMessage="Set up payment"
            />
          }
          closeBehavior={{ tag: "with-button", onClose: setToSigner }}
        >
          <PaymentForm
            submitButtonLabel={
              <FormattedMessage
                id="52b438f4-c5c9-49b1-b2a3-b3370e51c97a"
                defaultMessage="Save Card"
              />
            }
            onChangesSaved={setToNewPaymentMethod}
            updateDefaultPayer={!paymentSpecified}
            useModalStyle
            forceNewPayment
            collectOrgPaymentInfo
          />
        </WorkflowModal>
      )}
    </>
  );
}

export default deprecatedSubForm<Props>({ getValuesFor: ["payer"] })(TransactionPaymentSection);
