import { memo, useState, useEffect, Fragment, type ReactElement } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { NotarialActs, SigningRequirementEnum } from "graphql_globals";
import { Pill } from "common/core/pill_tabs";
import { useForm, Controller } from "common/core/form";
import Tooltip from "common/core/tooltip";
import {
  CheckboxGroup,
  CheckboxLabel,
  Checkbox,
  RadioLabel,
  RadioGroup,
  RadioInput,
} from "common/core/form/option";
import {
  defaultRequiredMessage,
  FieldErrorMessage,
  FormattedFieldError,
} from "common/core/form/error";
import RequiredAsterisk from "common/core/form/required-asterisk";
import Button from "common/core/button";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import Icon from "common/core/icon";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import { userFullName } from "util/user";
import { notarialActLabel } from "util/document";
import { useFeatureFlag } from "common/feature_gating";
import { DOC_CATEGORIES } from "constants/document";
import { SUPPORT_HOST } from "constants/support";

import type {
  NotarialAct as Meeting,
  NotarialAct_documentBundle as Bundle,
} from "./notarial_act_fragment.graphql";
import Styles from "./notarial_act.module.scss";

export type AcknowledgementType = "individual" | "representative";
type QuickStampForm = {
  isPrePrinted: "yes" | "no";
  notarialAct: NotarialActs;
  acknowledgementType?: AcknowledgementType;
  principalUserIds: (string | false)[];
};
type BaseProps = {
  onClose: () => void;
  meetingParticipants: Meeting["meetingParticipants"];
  currentDocumentClassification: string | null;
  bundleParticipants: Bundle["participants"];
  notaryStateName: string;
};
type AckChoiceProps = {
  onChange: (newValue: AcknowledgementType) => void;
  value?: AcknowledgementType;
};
type QuickStampProps = BaseProps & {
  onSubmit: (params: {
    isPrePrinted: boolean;
    notarialAct: NotarialActs;
    acknowledgementType?: AcknowledgementType;
    principals: string[];
  }) => void;
  notaryStateName: string;
};
type SealProps = BaseProps & {
  onSubmit: (params: { notarialAct: NotarialActs; principals: string[] }) => void;
};

const PS1583_ONLY_COPY = (
  <FormattedMessage
    id="6be95d93-f1c3-4a6a-9791-db94b894dd46"
    defaultMessage="PS1583 forms only accept an attestation"
  />
);
const HELP_FOR_ACT: Readonly<Record<NotarialActs, null | ReactElement>> = Object.freeze({
  [NotarialActs.JURAT]: (
    <FormattedMessage
      id="6351b2aa-268a-47bc-b514-45b69c2341db"
      defaultMessage="For a signer to sign and swear that the document's contents are true"
    />
  ),
  [NotarialActs.ACKNOWLEDGEMENT]: (
    <FormattedMessage
      id="ad679193-e356-4439-b859-b5e64b7b811c"
      defaultMessage="For a signer to acknowledge that they signed the document"
    />
  ),
  [NotarialActs.COPY_CERTIFICATION]: (
    <FormattedMessage
      id="6749003b-16f4-46fe-afcf-258eb03b8835"
      defaultMessage="To certify a reproduction as a true copy of the original document"
    />
  ),
  [NotarialActs.OATH_AFFIRMATION]: (
    <FormattedMessage
      id="d7998c22-a2eb-4135-9e05-515c87542c7c"
      defaultMessage="To administer an oath or affirmation"
    />
  ),
  [NotarialActs.ATTESTATION]: null,
  [NotarialActs.AFFIDAVIT]: null,
  [NotarialActs.VERIFICATION_OF_FACT_PS1583]: null,
  [NotarialActs.VERIFICATION_OF_FACT]: null,
});

function getPermittedActsSupportLink(notaryStateName: string) {
  switch (notaryStateName) {
    case "Arizona":
      return `${SUPPORT_HOST}/hc/en-us/articles/360058802314-Arizona-Permitted-Notarial-Acts`;
    case "Florida":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000328962-Florida-Permitted-Notarial-Acts`;
    case "Idaho":
      return `${SUPPORT_HOST}/hc/en-us/articles/360058807974-Idaho-How-to-Identify-a-Signer`;
    case "Indiana":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000329482-Indiana-Permitted-Notarial-Acts`;
    case "Iowa":
      return `${SUPPORT_HOST}/hc/en-us/articles/360060636953-Iowa-Permitted-Notarial-Acts`;
    case "Kentucky":
      return `${SUPPORT_HOST}/hc/en-us/articles/360058802334-Kentucky-Permitted-Notarial-Acts`;
    case "Maryland":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000247062-Maryland-Permitted-Notarial-Acts`;
    case "Michigan":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000291461-Michigan-Permitted-Notarial-Acts`;
    case "Minnesota":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000321921-Minnesota-Permitted-Notarial-Acts`;
    case "Montana":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000958122-Montana-Permitted-Notarial-Acts-`;
    case "Nebraska":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000958482-Nebraska-Permitted-Notarial-Acts`;
    case "Nevada":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000322661-Nevada-Permitted-Notarial-Acts`;
    case "North Dakota":
      return `${SUPPORT_HOST}/hc/en-us/articles/360059012594-North-Dakota-Permitted-Notarial-Acts`;
    case "Oklahoma":
      return `${SUPPORT_HOST}/hc/en-us/articles/360059477434-Oklahoma-Permitted-Notarial-Acts-`;
    case "Pennsylvania":
      return `${SUPPORT_HOST}/hc/en-us/articles/360061280773-Pennsylvania-Permitted-Notarial-Acts`;
    case "Tennessee":
      return `${SUPPORT_HOST}/hc/en-us/articles/360061281633-Tennessee-Permitted-Notarial-Acts`;
    case "Texas":
      return `${SUPPORT_HOST}/hc/en-us/articles/1500000298162-Texas-Permitted-Notarial-Acts`;
    case "Virginia":
      return `${SUPPORT_HOST}/hc/en-us/articles/360059115314-Virginia-Permitted-Notarial-Acts-`;
    case "Washington":
      return `${SUPPORT_HOST}/hc/en-us/articles/360059071654-Washington-Permitted-Notarial-Acts`;
    default:
      return null;
  }
}

function isCurrentDocumentPS1583(
  classification: string | null,
  possibleNotarialActs: NotarialActs[],
): boolean {
  return (
    classification?.toUpperCase() === DOC_CATEGORIES.PS1583_ATTESTATION &&
    possibleNotarialActs.includes(NotarialActs.ATTESTATION)
  );
}

function defaultSelectedNotarialAct(
  classification: string | null,
  possibleNotarialActs: NotarialActs[],
) {
  return isCurrentDocumentPS1583(classification, possibleNotarialActs)
    ? NotarialActs.ATTESTATION
    : undefined;
}

function sortActLabels(a: NotarialActs, b: NotarialActs) {
  return notarialActLabel(a).localeCompare(notarialActLabel(b));
}

function getParticipantActInfo({ meetingParticipants, bundleParticipants }: BaseProps) {
  const signerParticipants = meetingParticipants.filter(
    (p) => p.__typename === "SignerParticipant",
  );
  const notarialActs = Array.from(
    new Set(signerParticipants.flatMap((p) => p.availableNotarialActs)),
  );
  const notarizationParticipants = bundleParticipants!.filter(
    (p) => p!.signingRequirement !== SigningRequirementEnum.ESIGN,
  );

  const identityVerifiedWitnesses = meetingParticipants.filter(
    (p) => p.__typename === "IdentityVerifiedWitnessParticipant",
  );
  return {
    signerParticipants,
    presentUserIds: new Set(signerParticipants.map((p) => p.userId!)),
    notarialActs,
    singleParticipant: [...notarizationParticipants, ...identityVerifiedWitnesses].length === 1,
    notarizationParticipants,
    identityVerifiedWitnesses,
  };
}

export function AckChoice({ onChange, value }: AckChoiceProps) {
  return (
    <div className={Styles.ackChoiceContainer}>
      <Pill selected={value === "individual"} onClick={() => onChange("individual")}>
        <FormattedMessage
          id="d2894c17-e377-486f-b238-b2b2b797996d"
          defaultMessage="By individual"
        />
      </Pill>
      <Pill selected={value === "representative"} onClick={() => onChange("representative")}>
        <FormattedMessage
          id="0d265ff9-4ef4-4421-8121-b3633892f36b"
          defaultMessage="As a representative"
        />
      </Pill>
    </div>
  );
}

function invalidSelectedUserIds(principalUserIds: QuickStampForm["principalUserIds"]): boolean {
  const selectedUserIds = new Set(principalUserIds.filter(Boolean));
  return !selectedUserIds.size;
}

function QuickStampNotarialActItemsInner(props: QuickStampProps) {
  const actInfo = getParticipantActInfo(props);
  const {
    singleParticipant,
    signerParticipants,
    notarizationParticipants,
    identityVerifiedWitnesses,
  } = actInfo;
  const intl = useIntl();
  const form = useForm<QuickStampForm>({
    defaultValues: {
      notarialAct: defaultSelectedNotarialAct(
        props.currentDocumentClassification,
        actInfo.notarialActs,
      ),
      acknowledgementType: "individual",
      principalUserIds: singleParticipant ? signerParticipants.map((sp) => sp.userId!) : [],
    },
  });

  const [isPrePrintedWatch, notarialActWatch] = form.watch(["isPrePrinted", "notarialAct"]);
  const isNonPreprintedSelected = isPrePrintedWatch === "no";
  useEffect(() => {
    if (isNonPreprintedSelected && notarialActWatch === NotarialActs.OATH_AFFIRMATION) {
      form.setValue("notarialAct", undefined as unknown as NotarialActs);
    }
  });

  const handleSubmit = ({
    notarialAct,
    isPrePrinted,
    principalUserIds,
    acknowledgementType,
  }: QuickStampForm) => {
    const selectedUserIds = new Set(principalUserIds.filter(Boolean));

    const isPrePrintedBool = isPrePrinted === "yes";
    props.onSubmit({
      notarialAct,
      isPrePrinted: isPrePrintedBool,
      acknowledgementType:
        !isPrePrintedBool && notarialAct === NotarialActs.ACKNOWLEDGEMENT
          ? acknowledgementType
          : undefined,
      principals: [...signerParticipants, ...identityVerifiedWitnesses]
        .filter((p) => selectedUserIds.has(p.userId!))
        .map((p) => p.id),
    });
  };

  const principalUserIds = form.watch("principalUserIds");

  return (
    <form className={Styles.quickStampActs} onSubmit={form.handleSubmit(handleSubmit)}>
      <h1>
        <FormattedMessage
          id="ebd6f575-9bed-4b76-9bb2-2511e0ef0a14"
          defaultMessage="Generate Notarial Certificate"
        />
        <button type="button" onClick={props.onClose}>
          <Icon name="x" />
        </button>
      </h1>

      <p className={Styles.subtitle}>
        <FormattedMessage
          id="67429038-fec1-4276-91ff-3ef83b79d69c"
          defaultMessage="Quick Stamp helps you complete a compliant notarial certificate."
        />
      </p>

      {isCurrentDocumentPS1583(props.currentDocumentClassification, actInfo.notarialActs) && (
        <p className={Styles.subtitle}>{PS1583_ONLY_COPY}</p>
      )}

      <RadioGroup
        label={
          <>
            <FormattedMessage
              id="40c06701-bce9-452a-8e15-081f85c52886"
              defaultMessage="Is there a pre-printed notarial statement?"
            />
            <RequiredAsterisk />
          </>
        }
        groupError={
          <FormattedFieldError inputName="kind" error={form.formState.errors.isPrePrinted} />
        }
      >
        <RadioLabel
          label={
            <FormattedMessage id="0f69aea8-86ac-49d2-80e4-2f6ebca52624" defaultMessage="Yes" />
          }
          radio={
            <RadioInput<QuickStampForm["isPrePrinted"]>
              value="yes"
              {...form.register("isPrePrinted", { required: defaultRequiredMessage(intl) })}
            />
          }
        />
        <RadioLabel
          label={<FormattedMessage id="52bd9a09-6249-426f-b8e0-db4bba1fd729" defaultMessage="No" />}
          radio={
            <RadioInput<QuickStampForm["isPrePrinted"]>
              value="no"
              {...form.register("isPrePrinted", { required: defaultRequiredMessage(intl) })}
            />
          }
        />
      </RadioGroup>

      <RadioGroup
        label={
          <>
            <FormattedMessage
              id="f4af1507-35b7-42d2-9413-63b4b7a7bfd8"
              defaultMessage="Select Notarial Act"
            />
            <RequiredAsterisk />
          </>
        }
        groupError={
          <FormattedFieldError inputName="kind" error={form.formState.errors.notarialAct} />
        }
      >
        {actInfo.notarialActs.sort(sortActLabels).map((act) => {
          if (isNonPreprintedSelected && act === NotarialActs.OATH_AFFIRMATION) {
            return null;
          }
          const helpText = HELP_FOR_ACT[act];
          return (
            <Fragment key={act}>
              <RadioLabel
                label={
                  <>
                    {notarialActLabel(act)}
                    {helpText && (
                      <Tooltip
                        trigger="click"
                        target={<Icon className={Styles.actHelp} name="question" />}
                      >
                        {helpText}
                      </Tooltip>
                    )}
                  </>
                }
                radio={
                  <RadioInput<QuickStampForm["notarialAct"]>
                    value={act}
                    data-automation-id={`answer-${act}`}
                    {...form.register("notarialAct", { required: defaultRequiredMessage(intl) })}
                  />
                }
              />

              {isNonPreprintedSelected &&
                notarialActWatch === NotarialActs.ACKNOWLEDGEMENT &&
                act === NotarialActs.ACKNOWLEDGEMENT &&
                props.notaryStateName !== "New York" && (
                  <Controller
                    control={form.control}
                    name="acknowledgementType"
                    render={({ field }) => (
                      <AckChoice value={field.value} onChange={field.onChange} />
                    )}
                  />
                )}
            </Fragment>
          );
        })}
      </RadioGroup>

      {!singleParticipant && (
        <CheckboxGroup
          label={
            <FormattedMessage
              id="f3195a8c-3f78-456d-b1fa-a10812230b77"
              defaultMessage="Which signers does this act apply to?"
            />
          }
          groupError={
            <FieldErrorMessage
              inputName="principalUserIds"
              message={
                form.formState.isSubmitted && invalidSelectedUserIds(principalUserIds) ? (
                  <FormattedMessage
                    id="439a0d22-dd74-4080-90d7-32928caf6cff"
                    defaultMessage="At least one participant required"
                  />
                ) : null
              }
            />
          }
        >
          {notarizationParticipants.map((participant, index) => {
            const { userId, representation } = participant!;
            const isNotPresent = !actInfo.presentUserIds.has(userId);
            return (
              <CheckboxLabel
                key={userId}
                label={
                  <>
                    {userFullName(participant, representation)}
                    {isNotPresent && (
                      <div className={Styles.notPresent}>
                        <FormattedMessage
                          id="f6c0a204-04a4-436f-9292-0bf9fbf953a0"
                          defaultMessage="(Not in meeting)"
                        />
                      </div>
                    )}
                  </>
                }
                checkbox={
                  <Checkbox
                    value={userId}
                    disabled={isNotPresent}
                    aria-invalid="false"
                    data-automation-id="notarial-act-principal-checkbox"
                    {...form.register(`principalUserIds.${index}` as const)}
                  />
                }
              />
            );
          })}
          {identityVerifiedWitnesses.map((participant, index) => {
            const { userId } = participant;
            return (
              <CheckboxLabel
                key={userId}
                label={userFullName(participant)}
                checkbox={
                  <Checkbox
                    value={userId!}
                    aria-invalid="false"
                    data-automation-id="notarial-act-principal-checkbox"
                    {...form.register(
                      `principalUserIds.${index + notarizationParticipants.length}` as const,
                    )}
                  />
                }
              />
            );
          })}
        </CheckboxGroup>
      )}

      <footer className={Styles.buttons}>
        <Button buttonColor="action" variant="secondary" onClick={props.onClose}>
          <FormattedMessage id="f670d17c-97d4-4a90-8590-53419f719b25" defaultMessage="Cancel" />
        </Button>
        <Button
          type="submit"
          buttonColor="action"
          variant="primary"
          automationId="select-notorial-act"
          disabled={invalidSelectedUserIds(principalUserIds)}
        >
          <FormattedMessage
            id="073e702a-eecc-4d24-b6bd-675799b93193"
            defaultMessage="Place Fields"
          />
        </Button>
      </footer>
    </form>
  );
}

export const QuickStampNotarialActItems = memo(QuickStampNotarialActItemsInner);

function NotarialActItems(props: SealProps) {
  const actInfo = getParticipantActInfo(props);
  const {
    singleParticipant,
    signerParticipants,
    notarizationParticipants,
    identityVerifiedWitnesses,
  } = actInfo;
  const [selectedUserIds, setSelectedUserIds] = useState(
    () => new Set(singleParticipant ? signerParticipants.map((p) => p.userId!) : []),
  );

  const handleUserCheck = (userId: string) => {
    setSelectedUserIds((selectedUserIds) => {
      const updated = new Set(selectedUserIds);
      updated.has(userId) ? updated.delete(userId) : updated.add(userId);
      return updated;
    });
  };
  const [selectedAct, setSelectedAct] = useState(
    defaultSelectedNotarialAct(props.currentDocumentClassification, actInfo.notarialActs),
  );

  const showPermittedActsSupportLink = useFeatureFlag("show-permitted-acts-support-article");
  const permittedActsSupportLink = getPermittedActsSupportLink(props.notaryStateName);

  return (
    <div className={Styles.notarialActItems}>
      {!selectedAct || singleParticipant ? (
        <>
          <FormattedMessage
            id="d0a83033-dbba-48d9-b0e1-16d514c381a3"
            defaultMessage="Notarial Act associated with this seal"
            tagName="h5"
          />
          {isCurrentDocumentPS1583(props.currentDocumentClassification, actInfo.notarialActs) && (
            <div className={Styles.subtitle}>{PS1583_ONLY_COPY}</div>
          )}
          {actInfo.notarialActs.sort(sortActLabels).map((act) => (
            <div key={act} data-automation-id={act}>
              <DeprecatedRadioButton
                id={act}
                name="notarialActs"
                radioValue={act}
                groupValue={selectedAct}
                onChange={() => setSelectedAct(act)}
              />
              <span onClick={() => setSelectedAct(act)}>{notarialActLabel(act)}</span>
            </div>
          ))}
          {showPermittedActsSupportLink && permittedActsSupportLink && (
            <a
              className={Styles.supportLink}
              href={permittedActsSupportLink}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage
                id="4f9655c0-fa60-4ce2-8801-05f58b563530"
                defaultMessage="Not seeing the act you're looking for? {icon}"
                values={{ icon: <Icon name="new-window" /> }}
              />
            </a>
          )}
        </>
      ) : (
        <>
          <FormattedMessage
            id="31e0e19d-2723-4567-a959-3ae071c2463d"
            defaultMessage="Signer(s) this Notarial Act applies to"
            tagName="h5"
          />
          {notarizationParticipants.map((participant) => {
            const { userId, representation } = participant!;
            const isNotPresent = !actInfo.presentUserIds.has(userId);
            return (
              <div key={userId}>
                <DeprecatedCheckboxWithLabel
                  automationId="notarial-act-principal-checkbox"
                  disabled={isNotPresent}
                  checked={selectedUserIds.has(userId)}
                  onChange={() => handleUserCheck(userId)}
                  label={
                    <>
                      {userFullName(participant, representation)}
                      {isNotPresent && (
                        <div className={Styles.notPresent}>
                          <FormattedMessage
                            id="2d4f982e-a518-42dd-a566-c9f62e3dfbd3"
                            defaultMessage="not in meeting"
                          />
                        </div>
                      )}
                    </>
                  }
                />
              </div>
            );
          })}
          {identityVerifiedWitnesses.map((participant) => {
            const { userId } = participant;
            return (
              <div key={userId}>
                <DeprecatedCheckboxWithLabel
                  automationId="notarial-act-principal-checkbox"
                  checked={selectedUserIds.has(userId!)}
                  onChange={() => handleUserCheck(userId!)}
                  label={<>{userFullName(participant)}</>}
                />
              </div>
            );
          })}
        </>
      )}
      <div className={Styles.buttons}>
        <Button buttonColor="action" variant="secondary" onClick={props.onClose}>
          <FormattedMessage id="f670d17c-97d4-4a90-8590-53419f719b25" defaultMessage="Cancel" />
        </Button>
        <Button
          buttonColor="action"
          variant="primary"
          onClick={() =>
            props.onSubmit({
              notarialAct: selectedAct!,
              principals: [...signerParticipants, ...identityVerifiedWitnesses]
                .filter((p) => selectedUserIds.has(p.userId!))
                .map((p) => p.id),
            })
          }
          disabled={!selectedAct || !selectedUserIds.size}
          automationId="select-notorial-act"
        >
          <FormattedMessage id="0abfd478-2f19-46a7-ab3b-b431fd0b004d" defaultMessage="Submit" />
        </Button>
      </div>
    </div>
  );
}

export default memo(NotarialActItems);
