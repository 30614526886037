import { FormattedMessage } from "react-intl";
import { Fields, type WrappedFieldsProps } from "redux-form";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";
import { useEffect, useRef } from "react";

import FormGroupErrors from "common/form/group_errors";
import { DeprecatedReduxFormCheckbox } from "common/form/fields/checkbox/checkboxbar_checkbox";
import RequiredAsterisk from "common/core/form/required-asterisk";
import SubFormSection from "common/form/sub_form/section";
import SectionHeader from "common/form/sub_form/section/header";
import { isAriaInvalid } from "common/core/form/error";
import Link from "common/core/link";
import { Hr } from "common/core/horizontal_rule";
import type { CustomerSignerFromForm } from "common/transactions/transaction_v2";
import {
  Ial2CheckboxLabel,
  Ial2CheckboxSublabel,
  SmsAuthCheckboxLabel,
  SmsAuthCheckboxSublabel,
  KbaCheckboxLabel,
  KbaCheckboxSublabel,
} from "common/proof_requirements/common";
import { OptionBar, type PresentNode } from "common/core/form/option";
import { SUPPORT_HOST } from "constants/support";

import Styles from "./proof.module.scss";

export function includeSMSVerification(signerValues: CustomerSignerFromForm) {
  return signerValues.smsAuthProof || signerValues.ial2Proof;
}

type CheckboxBarProps = {
  hasError?: boolean;
  disabled?: boolean;
  checkbox: PresentNode;
};

function Ial2CheckboxBar({ hasError, checkbox }: CheckboxBarProps) {
  return (
    <OptionBar
      hasError={hasError}
      label={<Ial2CheckboxLabel />}
      subLabel={<Ial2CheckboxSublabel />}
      input={checkbox}
    />
  );
}

function SmsAuthCheckboxBar({ hasError, checkbox, disabled }: CheckboxBarProps) {
  return (
    <OptionBar
      disabled={disabled}
      hasError={hasError}
      label={<SmsAuthCheckboxLabel />}
      subLabel={<SmsAuthCheckboxSublabel />}
      input={checkbox}
    />
  );
}

function KbaCheckboxBar({ hasError, checkbox }: CheckboxBarProps) {
  return (
    <OptionBar
      hasError={hasError}
      label={<KbaCheckboxLabel />}
      subLabel={<KbaCheckboxSublabel />}
      input={checkbox}
    />
  );
}

type ProofSectionProps = {
  fieldNamePrefix: string;
  formErrors?: {
    signerDetails?: [{ ial2Proof: boolean }];
  };
  signerIndex: number;
  change(name: string, value: boolean): void;
};

function ProofSection({
  change,
  fieldNamePrefix,
  formErrors,
  signerIndex,
  ...props
}: ProofSectionProps & { names: string[]; signerDetails: WrappedFieldsProps }) {
  const anyTouched = props.names.some((name) => {
    return get(props, name).meta.touched;
  });
  /* In the future, we will want to move to using a single name for this field, with an array of values. */
  const hasError = formErrors?.signerDetails?.[0]?.ial2Proof && anyTouched;

  // If this is an additional signer it takes a second to add
  // eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition
  const ial2Selected = Boolean(props.signerDetails[signerIndex]?.ial2Proof.input.value);
  const prevIal2Selected = useRef(ial2Selected);

  useEffect(() => {
    // SMS must be checked when IAL2 is checked. Only change SMS checkbox when IAL2 checkbox is being selected or was just unselected
    if (ial2Selected || prevIal2Selected.current) {
      change(`${fieldNamePrefix}.smsAuthProof`, ial2Selected);
    }
    prevIal2Selected.current = ial2Selected;
  }, [ial2Selected]);

  return (
    <>
      <Hr className={Styles.divider} />
      <SubFormSection>
        <SectionHeader
          title={
            <>
              <FormattedMessage
                id="1c3b5f51-dfab-48c9-9001-f4e198951ee9"
                defaultMessage="Add identity verification with Proof"
              />
              {formErrors && <RequiredAsterisk />}
            </>
          }
          subtitle={
            <FormattedMessage
              id="5637f1cd-d0fb-4550-9f5c-5b2b2f507e76"
              defaultMessage="Proof uses enhanced checks to validate a signer's identity. {link}"
              values={{
                link: (
                  <Link href={`${SUPPORT_HOST}/hc/en-us/articles/8706936036887`}>Learn more</Link>
                ),
              }}
            />
          }
        />
        <Ial2CheckboxBar
          hasError={hasError}
          checkbox={
            <DeprecatedReduxFormCheckbox
              aria-invalid={isAriaInvalid(hasError)}
              name={`${fieldNamePrefix}.ial2Proof`}
            />
          }
        />
        <SmsAuthCheckboxBar
          hasError={hasError}
          disabled={ial2Selected}
          checkbox={
            <DeprecatedReduxFormCheckbox
              disabled={ial2Selected}
              aria-invalid={isAriaInvalid(hasError)}
              name={`${fieldNamePrefix}.smsAuthProof`}
            />
          }
        />
        <KbaCheckboxBar
          hasError={hasError}
          checkbox={
            <DeprecatedReduxFormCheckbox
              aria-invalid={isAriaInvalid(hasError)}
              name={`${fieldNamePrefix}.kbaProof`}
            />
          }
        />
        {/*Should only be errors for primary signer */}
        <FormGroupErrors fields={[`${fieldNamePrefix}.ial2Proof`]} />
      </SubFormSection>
    </>
  );
}

function ProofSectionWithFields(props: ProofSectionProps) {
  // Borrowed this pattern from FormGroupErrors - allows us to see meta for individual field such as 'touched' state. We need to know touched state of these 3 fields at this level so we can pass hasError to CheckboxBar and DeprecatedReduxFormCheckbox so it will render a danger colored border. There are formErrors on mount and we don't want to show an error border right away.
  return (
    <Fields
      names={[
        `signerDetails[${props.signerIndex}].ial2Proof`,
        `signerDetails[${props.signerIndex}].smsAuthProof`,
        `signerDetails[${props.signerIndex}].kbaProof`,
      ]}
      component={ProofSection}
      {...props}
    />
  );
}

export { ProofSectionWithFields as ProofSection };
