import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import {
  NotaryComplianceStatuses,
  NotaryOnboardingStatuses,
  type NotaryCapacityType,
  type NotaryProfileInvalidFields,
} from "graphql_globals";
import AppSubdomains from "constants/app_subdomains";
import Icon from "common/core/icon";
import CheckProofImage from "assets/images/check_proof.svg";
import MegaphoneImage from "assets/images/megaphone.svg";
import { useNotaryCommonSetupRedirect } from "common/notary/profile_wizard/util";
import { Card } from "common/core/card";
import Button from "common/core/button";
import { useOnboardingV2 } from "util/feature_detection";
import { Heading, Paragraph } from "common/core/typography";
import { redirectToSubdomain } from "util/application_redirect";

import Styles from "./index.module.scss";

export type TaskItemInterface = {
  complete: boolean;
};

type TaskItemProps = TaskItemInterface & {
  iconName: string;
  title: ReactNode;
  info?: ReactNode;
  color: "blue" | "orange" | "teal" | "purple";
  wide?: boolean;
  onClick: () => void;
};

function TaskItem(props: TaskItemProps) {
  const { iconName, title, info, color, wide, complete, onClick } = props;

  return (
    <div
      className={classNames(Styles.taskItem, {
        [Styles.blue]: color === "blue",
        [Styles.orange]: color === "orange",
        [Styles.teal]: color === "teal",
        [Styles.purple]: color === "purple",
        [Styles.complete]: complete,
        [Styles.wide]: wide,
      })}
      onClick={onClick}
    >
      <div className={Styles.iconContainer}>
        <Icon className={Styles.icon} name={complete ? "success" : iconName} />
      </div>

      <div className={Styles.text}>
        <p className={Styles.title}>{title}</p>
        {info && <p className={Styles.info}>{info}</p>}
      </div>
    </div>
  );
}

type SetupNotaryProfile = {
  onboardingStatus: NotaryOnboardingStatuses;
  complianceStatus: NotaryComplianceStatuses;
  capacities: {
    type: NotaryCapacityType;
    validation: { invalidFields: NotaryProfileInvalidFields[] };
  }[];
};
type SetupNotaryProfileTaskItemProps<NP extends SetupNotaryProfile> = {
  notaryProfile: NP;
  complete: false; // for integration with <TaskSection />
};

function getSetupNotaryProfileTitle(notaryProfile: SetupNotaryProfile) {
  if (notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED) {
    return (
      <FormattedMessage
        id="c36acbe8-bb49-42d1-9116-cd77dd3f270c"
        defaultMessage="Complete your notary profile"
      />
    );
  }
  switch (notaryProfile.complianceStatus) {
    case NotaryComplianceStatuses.NEEDS_REVIEW:
      return (
        <FormattedMessage
          id="7d8d1e66-7002-4139-a0a9-aa6b9d1ed36c"
          defaultMessage="Your notary profile is in review"
        />
      );
    case NotaryComplianceStatuses.NOT_COMPLIANT:
      return (
        <FormattedMessage
          id="aefef655-f11d-4ac9-92f8-a7278a44e3ff"
          defaultMessage="Changes required on your notary profile"
        />
      );
  }
}

export function ProfileReviewCard() {
  return (
    <div className={Styles.profileReviewCard}>
      <Card noMargin fullWidth>
        <div className={Styles.topContent}>
          <img className={Styles.image} src={MegaphoneImage} aria-hidden="true" alt="" />
          <Heading level="h4" textStyle="headingFour">
            <FormattedMessage
              id="10df2715-9b5c-47d7-93be-dd559bcbfcae"
              defaultMessage="Good job! Your profile is in review."
            />
          </Heading>
        </div>
      </Card>
      <Card className={Styles.bottomCard} fullWidth>
        <div className={Styles.bottomContent}>
          <div className={Styles.textContent}>
            <img className={Styles.image} src={CheckProofImage} aria-hidden="true" alt="" />
            <div>
              <Heading level="h4" textStyle="headingFive">
                <FormattedMessage
                  id="0d725a2e-a223-4116-a479-b9ec86dd0c2e"
                  defaultMessage="Your notary profile is complete"
                />
              </Heading>
              <Paragraph className={Styles.bottomSectionText} size="large">
                <FormattedMessage
                  id="fa0ee8d9-69b5-4684-8051-92f11890f331"
                  defaultMessage="Please plan for 1-2 business days for review"
                />
              </Paragraph>
            </div>
          </div>
          <Button
            buttonColor="action"
            variant="primary"
            onClick={() =>
              redirectToSubdomain(AppSubdomains.customer, "/settings/notary/onboarding/Finished")
            }
          >
            <FormattedMessage
              id="eb64b114-2aea-4227-aa6a-7f02fdb6529e"
              defaultMessage="View status"
            />
          </Button>
        </div>
      </Card>
    </div>
  );
}

export function SetupNotaryProfileTaskItem<NP extends SetupNotaryProfile>({
  notaryProfile,
}: SetupNotaryProfileTaskItemProps<NP>) {
  const notaryCommonSetupRedirect = useNotaryCommonSetupRedirect(true);

  const isCompleteOnboarding =
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;
  const isProfileInReview =
    isCompleteOnboarding &&
    notaryProfile.complianceStatus === NotaryComplianceStatuses.NEEDS_REVIEW;
  const onboardingV2 = useOnboardingV2();

  return onboardingV2 && isProfileInReview ? (
    <ProfileReviewCard />
  ) : (
    <TaskItem
      iconName="notaries"
      title={getSetupNotaryProfileTitle(notaryProfile)}
      info={
        !isCompleteOnboarding && (
          <FormattedMessage
            id="b83256cf-c20f-4720-82ad-a2414dae2e7d"
            defaultMessage="Upload your notary commission details, digital certificate, and create your digital stamp."
          />
        )
      }
      color="orange"
      complete={isCompleteOnboarding}
      onClick={() => notaryCommonSetupRedirect(notaryProfile)}
      wide
    />
  );
}

type AddLogoTaskItemProps = TaskItemInterface & { onClick: () => void };
export function AddLogoTaskItem(props: AddLogoTaskItemProps) {
  const { complete, onClick } = props;

  return (
    <TaskItem
      iconName="logo-n"
      title={
        <FormattedMessage
          id="bb94bfef-715d-46ce-a984-1f4cf3b2d728"
          defaultMessage="Add your company logo to customize signer emails"
        />
      }
      color="teal"
      complete={complete}
      onClick={onClick}
    />
  );
}

type SetupBillingTaskItemProps = TaskItemInterface & { onClick: () => void };
export function SetupBillingTaskItem(props: SetupBillingTaskItemProps) {
  const { complete, onClick } = props;

  return (
    <TaskItem
      iconName="card"
      title={
        <FormattedMessage
          id="b87da48a-aa96-4195-a6b1-e80371f3cf95"
          defaultMessage="Set up your payment and billing details"
        />
      }
      color="purple"
      complete={complete}
      onClick={onClick}
    />
  );
}

type AddCompanyNameTaskItemProps = TaskItemInterface & { onClick: () => void };
export function AddCompanyNameTaskItem(props: AddCompanyNameTaskItemProps) {
  const { complete, onClick } = props;

  return (
    <TaskItem
      iconName="profile"
      title={
        <FormattedMessage
          id="b7acf42a-fa51-42aa-bf59-71b2823badac"
          defaultMessage="Add your company name and address to your account"
        />
      }
      color="blue"
      complete={complete}
      onClick={onClick}
    />
  );
}
