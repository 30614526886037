import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useEffect, useState, type ReactNode } from "react";
import { CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";

import { useForm, useWatch } from "common/core/form";
import { StripeCardElementsUIForReactHookForm, getLazyStripe } from "common/settings/payment/util";
import { ChoiceChip, OptionBar, RadioGroup, RadioInput } from "common/core/form/option";
import { Payer } from "graphql_globals";
import { useApolloClient, useMutation } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import AddCardToOrganizationMutation from "common/settings/payment/add_card_to_organization_mutation.graphql";
import { invalidCard } from "errors/credit_card";
import Button from "common/core/button";
import { verifyAch } from "common/settings/payment/ach_util";
import AddAchAccountMutation from "common/settings/payment/add_ach_account_to_organization_mutation.graphql";
import { Paragraph, Substyle } from "common/core/typography";
import {
  CardPaymentMethod,
  AchPaymentMethod,
} from "common/settingsv2/sidebar_settings/billing/payment_settings/payment";
import { useId } from "util/html";
import { isGraphQLError } from "util/graphql/query";
import { useMobileScreenClass } from "common/core/responsive";
import { FormattedFieldError } from "common/core/form/error";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import { STEPS } from "..";
import {
  Footer,
  FormHeading,
  HelperText,
  Error,
  type Organization,
  AccountSetupHeading,
  MobileDeviceContentWrapper,
} from "../../common";
import UpdateOrganizationDefaultPayerMutation from "./update_organization_payer.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  error: {
    id: "0b4bba50-fc65-4720-95e0-fae1f6a92bb1",
    defaultMessage: "Please select a payment option.",
  },
});

type FormValues = {
  cardName: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  defaultPayer: Payer | null;
};

type Portal =
  | { portal: "business"; userId: string | undefined }
  | { portal: "title"; userId?: never };

type Props = {
  onNext: () => Promise<void>;
  defaultPayerFormData: Payer | null;
  defaultPaymentSourceFormData: Organization["defaultPaymentSource"];
  organizationId: Organization["id"];
  alert?: ReactNode;
} & Portal;

function PaymentStepInner({
  portal,
  organizationId,
  onNext,
  defaultPayerFormData,
  defaultPaymentSourceFormData,
  alert,
}: Props) {
  const isMobileScreenSize = useMobileScreenClass();
  const isMobileBusiness = isMobileScreenSize && portal === "business";
  const intl = useIntl();
  const isMobile = useMobileScreenClass();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [selectBankAccountError, setSelectBankAccountError] = useState(false);
  const [editingCC, setEditingCC] = useState(false);
  const updateOrganizationPayer = useMutation(UpdateOrganizationDefaultPayerMutation);
  const addCardToOrganization = useMutation(AddCardToOrganizationMutation);
  const addAchAccount = useMutation(AddAchAccountMutation);

  const bankConnected = defaultPaymentSourceFormData?.__typename === "AchAccount";
  const creditCardSaved = defaultPaymentSourceFormData?.__typename === "Card";
  const headingId = useId();

  const stripe = useStripe();
  const stripeElements = useElements();

  const client = useApolloClient();
  const [activeOrganizationId] = useActiveOrganization();

  const form = useForm<FormValues>({
    defaultValues: {
      cardName: "",
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
      // Though we save Payer as ORGANIZATION_ACH when saving a bank account, it comes back in the front end as ORGANIZATION, same as credit card. This allows us to select the correct radio.
      defaultPayer:
        defaultPaymentSourceFormData && bankConnected
          ? Payer.ORGANIZATION_ACH
          : defaultPayerFormData,
    },
  });

  const defaultPayerFormValue = useWatch({ control: form.control, name: "defaultPayer" });

  useEffect(() => {
    //Clear errors when changing radios
    setError(false);
  }, [defaultPayerFormValue]);

  const trackPaymentStepCompleted = () => {
    return portal === "title"
      ? segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_PAYMENT_ONBOARDING_SUCCEEDED, {
          default_payer: defaultPayerFormValue,
        })
      : null;
  };

  const saveCreditCard = async ({
    cardName,
    defaultPayer,
  }: Pick<FormValues, "cardName" | "defaultPayer">) => {
    if (!stripe || !stripeElements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return Promise.resolve();
    }

    const cardElement = stripeElements.getElement(CardNumberElement);

    try {
      setSaving(true);

      const tokenData = await stripe.createToken(cardElement!, { name: cardName });

      await addCardToOrganization({
        variables: {
          input: {
            id: organizationId,
            cardToken: tokenData.token!.id,
            defaultPayer,
          },
        },
      });
    } finally {
      setSaving(false);
    }
  };

  const saveChargeRecipients = async (defaultPayer: FormValues["defaultPayer"]) => {
    try {
      setSaving(true);
      await updateOrganizationPayer({
        variables: {
          input: {
            id: organizationId,
            defaultPayer,
          },
        },
      });
    } finally {
      setSaving(false);
    }
  };

  const saveAchAccount = async ({ accountId, token }: { accountId: string; token: string }) => {
    try {
      await addAchAccount({
        variables: {
          input: {
            organizationId,
            accountId,
            publicToken: token,
          },
        },
      });
      setSelectBankAccountError(false);
    } catch {
      setError(true);
    }
  };

  const saveDefaultPayerAch = async () => {
    try {
      await updateOrganizationPayer({
        variables: {
          input: {
            id: organizationId,
            defaultPayer: Payer.ORGANIZATION_ACH,
          },
        },
      });
    } catch {
      setError(true);
    }
  };

  const connectBankAccount = async () => {
    setSaving(true);
    try {
      await verifyAch(client, activeOrganizationId!, async ({ accountId, token }) => {
        await saveAchAccount({ accountId, token });
        // Only save if default payer has not previously been set
        if (!defaultPayerFormData) {
          await saveDefaultPayerAch();
        }
      });
    } catch {
      setError(true);
    } finally {
      setSaving(false);
    }
  };

  const save = async ({ defaultPayer, cardName }: FormValues) => {
    // Charge recipients
    if (defaultPayer === Payer.CUSTOMER) {
      try {
        await saveChargeRecipients(defaultPayer);
        trackPaymentStepCompleted();
        return await onNext();
      } catch {
        setError(true);
      }
    }

    // Credit card
    if (defaultPayer === Payer.ORGANIZATION) {
      if (creditCardSaved && !editingCC) {
        return await onNext();
      }

      try {
        await saveCreditCard({ defaultPayer, cardName });
        trackPaymentStepCompleted();
        return await onNext();
      } catch {
        setError(true);
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const message = error.graphQLErrors[0].specifics;
          return Promise.reject(invalidCard({ message }));
        }
        return;
      }
    }

    //Bank Account
    if (defaultPayer === Payer.ORGANIZATION_ACH) {
      if (bankConnected) {
        trackPaymentStepCompleted();
        return await onNext();
      }
      return setSelectBankAccountError(true);
    }
  };

  const formHeading = (
    <FormattedMessage
      id="4d8f4b6d-ebde-4105-83fa-74f50905bd96"
      defaultMessage="{isTitle, select, true {Next} other {Lastly}}, enter a payment method for your transactions"
      values={{ isTitle: portal === "title" }}
    />
  );

  const creditCardLabel = (
    <FormattedMessage id="de73d0f8-11d3-44c4-8e07-8e5b7cca0f12" defaultMessage="Credit card" />
  );

  const mobileDeviceCreditCardLabel = (
    <FormattedMessage
      id="9c52f253-ce37-4af9-ba76-a44c6d2c1f3d"
      defaultMessage="Charge my account (credit card)"
    />
  );

  const chargeMyRecipientsLabel = (
    <FormattedMessage
      id="e56cf06e-6f58-436f-b0bf-211a60eec16b"
      defaultMessage="Charge my recipients"
    />
  );

  const coreFooterProps = {
    portal,
    step: STEPS.PAYMENT,
    loading: false,
    saveButtonAutomationId: "payment-modal-primary-btn",
  };

  const desktopContent = (
    <>
      <FormHeading>{formHeading}</FormHeading>

      <HelperText>
        <FormattedMessage
          id="d67b23ad-40a4-4d18-afc2-7f636ec4cedc"
          defaultMessage="Your plan has no platform fees, but we charge for transactions."
        />
      </HelperText>
      {alert && <div className={Styles.alert}>{alert}</div>}
      <div className={Styles.wrapper}>
        <RadioGroup
          aria-labelledby={headingId}
          className={Styles.radioGroup}
          horizontal={!isMobile}
          inputsClassname={Styles.radioGroupInputs}
          groupError={
            <FormattedFieldError
              className={Styles.error}
              inputName="defaultPayer"
              error={form.formState.errors.defaultPayer}
            />
          }
        >
          <ChoiceChip
            label={creditCardLabel}
            hasError={Boolean(form.formState.errors.defaultPayer)}
            radio={
              <RadioInput
                value={Payer.ORGANIZATION}
                {...form.register("defaultPayer", {
                  required: intl.formatMessage(MESSAGES.error),
                })}
              />
            }
          />

          {portal === "title" && (
            <ChoiceChip
              label={
                <FormattedMessage
                  id="12d1bf73-c3e7-4be0-b695-224054a6e510"
                  defaultMessage="Bank account"
                />
              }
              hasError={Boolean(form.formState.errors.defaultPayer)}
              radio={
                <RadioInput
                  value={Payer.ORGANIZATION_ACH}
                  {...form.register("defaultPayer", {
                    required: intl.formatMessage(MESSAGES.error),
                  })}
                />
              }
            />
          )}

          <ChoiceChip
            label={chargeMyRecipientsLabel}
            hasError={Boolean(form.formState.errors.defaultPayer)}
            radio={
              <RadioInput
                value={Payer.CUSTOMER}
                {...form.register("defaultPayer", {
                  required: intl.formatMessage(MESSAGES.error),
                })}
              />
            }
          />
        </RadioGroup>

        <>
          {defaultPayerFormValue === Payer.ORGANIZATION && (
            <>
              {creditCardSaved && !editingCC ? (
                <>
                  <CardPaymentMethod
                    name={defaultPaymentSourceFormData.name as string}
                    last4={defaultPaymentSourceFormData.last4 as string}
                  />
                  <Button
                    onClick={() => setEditingCC(true)}
                    buttonColor="action"
                    variant="tertiary"
                    buttonSize="condensed"
                  >
                    <FormattedMessage
                      id="f204867e-cfa0-4a2e-81de-84286a9e6d2f"
                      defaultMessage="Update credit card"
                    />
                  </Button>
                </>
              ) : (
                <StripeCardElementsUIForReactHookForm
                  changeCardElement={() => {}}
                  form={form}
                  showLabels
                />
              )}
            </>
          )}
          {defaultPayerFormValue === Payer.ORGANIZATION_ACH && (
            <>
              <Paragraph className={Styles.bankAccountText}>
                <FormattedMessage
                  id="b7053181-67ca-42c3-b845-b1344e8998a2"
                  defaultMessage="Allow your company to pay directly with a bank account. These charges will be batched and processed daily. You will be taken to Plaid to securely connect your account."
                />
              </Paragraph>
              {bankConnected && (
                <AchPaymentMethod
                  className={Styles.savedAch}
                  defaultPaymentSource={defaultPaymentSourceFormData}
                />
              )}
              <div className={Styles.connectBankWrapper}>
                <Button
                  onClick={() => connectBankAccount()}
                  isLoading={saving}
                  buttonColor="action"
                  variant="secondary"
                >
                  <FormattedMessage
                    id="c55f7394-1aea-487d-9ea8-60a29b54435d"
                    defaultMessage="{bankConnected, select, true {Update} other {Connect}} bank account"
                    values={{ bankConnected }}
                  />
                </Button>
                {selectBankAccountError && (
                  <Substyle textColor="danger" size="small">
                    <FormattedMessage
                      id="36395dfc-e824-49f0-81d8-a330ba4753da"
                      defaultMessage="Connect bank account to continue"
                    />
                  </Substyle>
                )}
              </div>
            </>
          )}
          {defaultPayerFormValue === Payer.CUSTOMER && (
            <Paragraph>
              <FormattedMessage
                id="ac40e7ce-263d-4858-b47a-804908785016"
                defaultMessage="Your recipients will be prompted for payment when they complete a transaction."
              />
            </Paragraph>
          )}
        </>
      </div>

      {error && <Error />}

      <Footer
        {...coreFooterProps}
        tooltipText={
          <FormattedMessage
            id="d9815da5-530e-4ad6-bbd4-38e5392dd115"
            defaultMessage="Your payment method will only be charged for transaction fees or if you opt-in to an account tier upgrade."
          />
        }
      />
    </>
  );

  const mobileDeviceBusinessContent = (
    <>
      <AccountSetupHeading />
      <RadioGroup
        labelSize="large"
        label={formHeading}
        groupError={
          <FormattedFieldError
            className={Styles.error}
            inputName="defaultPayer"
            error={form.formState.errors.defaultPayer}
          />
        }
      >
        <OptionBar
          label={chargeMyRecipientsLabel}
          input={
            <RadioInput
              value={Payer.CUSTOMER}
              {...form.register("defaultPayer", {
                required: intl.formatMessage(MESSAGES.error),
              })}
            />
          }
          hasError={Boolean(form.formState.errors.defaultPayer)}
        />
        <OptionBar
          label={mobileDeviceCreditCardLabel}
          input={
            <RadioInput
              value={Payer.ORGANIZATION}
              {...form.register("defaultPayer", {
                required: intl.formatMessage(MESSAGES.error),
              })}
            />
          }
          hasError={Boolean(form.formState.errors.defaultPayer)}
        />
      </RadioGroup>

      <MobileDeviceContentWrapper>
        {defaultPayerFormValue === Payer.ORGANIZATION && (
          <>
            {creditCardSaved && !editingCC ? (
              <>
                <CardPaymentMethod
                  name={defaultPaymentSourceFormData.name as string}
                  last4={defaultPaymentSourceFormData.last4 as string}
                />
                <Button
                  onClick={() => setEditingCC(true)}
                  buttonColor="action"
                  variant="tertiary"
                  buttonSize="condensed"
                >
                  <FormattedMessage
                    id="f204867e-cfa0-4a2e-81de-84286a9e6d2f"
                    defaultMessage="Update credit card"
                  />
                </Button>
              </>
            ) : (
              <StripeCardElementsUIForReactHookForm
                changeCardElement={() => {}}
                form={form}
                showLabels
              />
            )}
          </>
        )}

        {defaultPayerFormValue === Payer.CUSTOMER && (
          <Paragraph size="large">
            <FormattedMessage
              id="ac40e7ce-263d-4858-b47a-804908785016"
              defaultMessage="Your recipients will be prompted for payment when they complete a transaction."
            />
          </Paragraph>
        )}
      </MobileDeviceContentWrapper>

      {error && <Error />}

      <Footer
        {...coreFooterProps}
        mobileDeviceStyling
        continueButtonText={
          <FormattedMessage
            id="145b3a9d-15db-4f15-8cbe-b0629a609947"
            defaultMessage="Complete my setup"
          />
        }
      />
    </>
  );

  return (
    <form onSubmit={form.handleSubmit(save)}>
      {isMobileBusiness ? mobileDeviceBusinessContent : desktopContent}
    </form>
  );
}

export function PaymentStep(props: Props) {
  return (
    <Elements stripe={getLazyStripe()}>
      <PaymentStepInner {...props} />
    </Elements>
  );
}
