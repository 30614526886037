import { type ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { useQuery, useMutation } from "util/graphql";
import { EasylinkTypes, OrganizationApiKeyPayer } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import type { FieldValues, UseFormReturn } from "common/core/form";
import { transformProofRequirementForForm } from "common/proof_requirements/common";

import { EditSignerUploadEasylink } from "./signer_upload/edit";
import EasylinkEditQuery, {
  type EasylinkEdit_node_Easylink as Easylink,
} from "./easylink_edit.query.graphql";
import { EditDocumentsProvidedEasylink } from "./documents_provided/edit";
import { useNavigateToEasylinkDashboardRoute } from "./navigate";
import { COMMON_MESSAGES } from "./common";
import EditEasylinkMutation, { type EditEasylinkVariables } from "./edit_easylink_mutation.graphql";
import { FormPageLayout } from "../common";
import { EditIdentifyEasylink } from "./identify/edit";
import { EditVerifyEasylink } from "./verify/edit";

export function EasylinkEditForm() {
  const { easylinkId } = useParams();
  const navigateToEasylinkDashboardRoute = useNavigateToEasylinkDashboardRoute();

  useEffect(() => {
    if (!easylinkId) {
      navigateToEasylinkDashboardRoute();
    }
  }, [easylinkId]);

  const { data, loading } = useQuery(EasylinkEditQuery, {
    variables: { easylinkId: easylinkId! },
  });

  if (data && !loading) {
    const easylink = data.node! as Easylink;
    const notaryProfile = data.viewer.user?.notaryProfile || null;

    const sharedValues = {
      id: easylink.id,
      active: easylink.active,
      name: easylink.name || "",
      proofRequirement: transformProofRequirementForForm(easylink.proofRequirement),
    };

    const payer = easylink.payer || OrganizationApiKeyPayer.CUSTOMER;
    const maxSigners = easylink.maxSigners.toString();
    const minSigners = easylink.minSigners.toString();
    const ccRecipients = easylink.ccRecipientEmails.map((email) => ({ email }));

    switch (easylink.easylinkType) {
      case EasylinkTypes.SIGNER_UPLOAD:
        return (
          <EditSignerUploadEasylink
            notaryProfile={notaryProfile}
            initialValues={{
              ...sharedValues,
              completionRequirement: easylink.completionRequirement,
              payer,
              maxSigners,
              minSigners,
              ccRecipients,
            }}
          />
        );
      case EasylinkTypes.DOCUMENTS_PROVIDED:
        return (
          <EditDocumentsProvidedEasylink
            notaryProfile={notaryProfile}
            initialValues={{
              ...sharedValues,
              templateId: easylink.template?.id,
              payer,
              maxSigners,
              minSigners,
              ccRecipients,
            }}
          />
        );
      case EasylinkTypes.IDENTIFY:
        return (
          <EditIdentifyEasylink
            initialValues={{
              ...sharedValues,
              proofRequirement: sharedValues.proofRequirement!,
              payer: undefined,
              ccRecipients,
            }}
          />
        );
      case EasylinkTypes.VERIFY:
        return (
          <EditVerifyEasylink
            initialValues={{
              ...sharedValues,
              payer: undefined,
            }}
          />
        );
    }
  }
  return <LoadingIndicator />;
}

export function EditEasylinkLayout<FormValuesType extends FieldValues>({
  children,
  title,
  form,
  initialValues,
  variables,
}: {
  children: ReactNode;
  title: ReactNode;
  form: UseFormReturn<FormValuesType>;
  initialValues: FormValuesType;
  variables: (values: FormValuesType, justActivated: boolean) => EditEasylinkVariables;
}) {
  const { handleSubmit, formState } = form;

  const [errorMessage, setErrorMessage] = useState<string>();
  const intl = useIntl();
  const navigateToEasylinkDashboardRoute = useNavigateToEasylinkDashboardRoute();

  const editEasylinkMutationFn = useMutation(EditEasylinkMutation);

  const updateEasylink = async (values: FormValuesType, justActivated: boolean) => {
    return editEasylinkMutationFn({
      variables: variables(values, justActivated),
    })
      .then((result) => {
        const updatedEasylink = result.data!.updateEasylink!.easylink;
        navigateToEasylinkDashboardRoute({
          active: updatedEasylink.active,
          updatedEasylinkName: updatedEasylink.name!,
          justActivated,
        });
      })
      .catch(() => {
        setErrorMessage(intl.formatMessage(COMMON_MESSAGES.ERROR_MESSAGE));
      });
  };

  return (
    <FormPageLayout
      heading={
        <FormattedMessage
          id="7a448085-88f6-4a7f-af80-e1c94b67421f"
          defaultMessage="Edit EasyLink"
        />
      }
      title={title}
      loading={formState.isSubmitting}
      submitText={
        initialValues.active ? (
          <FormattedMessage
            id="d82ff58b-6f14-46fa-96cf-56a9f7cfda9f"
            defaultMessage="Save changes"
          />
        ) : (
          <FormattedMessage
            id="1110ce95-e8ea-4b81-b8b6-3b609b6f7c0e"
            defaultMessage="Save and activate"
          />
        )
      }
      submitDisabled={!formState.isDirty}
      onSubmit={handleSubmit(async (values) => {
        return updateEasylink(values, !initialValues.active);
      })}
      secondarySubmitText={
        initialValues.active ? null : (
          <FormattedMessage
            id="bf3a9a66-305e-452a-8f5f-1bfb5b141c1a"
            defaultMessage="Save without activating"
          />
        )
      }
      secondaryHandleSubmit={handleSubmit(async (values) => {
        return updateEasylink(values, false);
      })}
      cancelText={
        <FormattedMessage
          id="d78bed31-858f-4331-9d60-bff38e8ff0ee"
          defaultMessage="Return to dashboard"
        />
      }
      onCancel={() => {
        navigateToEasylinkDashboardRoute({
          active: initialValues.active,
          restorePreviousView: true,
        });
      }}
      errorMessage={errorMessage}
    >
      {children}
    </FormPageLayout>
  );
}
