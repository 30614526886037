import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Link from "common/core/link";

import Styles from "./index.module.scss";

export const STEP_CONTENT = Object.freeze({
  PersonalInfo: (
    <FormattedMessage
      id="17822465-af46-43a7-ad76-569e8e5f14ef"
      defaultMessage="Personal Information"
    />
  ),
  CommissionDetails: (
    <FormattedMessage
      id="80a613bb-e593-4ab7-976e-a9f95f51ca7c"
      defaultMessage="Commission Details"
    />
  ),
  InsuranceDetails: (
    <FormattedMessage
      id="085697e9-c9e9-4406-b994-4b80fa1552db"
      defaultMessage="Insurance Details"
    />
  ),
  NNAInfo: <FormattedMessage id="582b84d3-9112-43e5-bbb0-ea42327039f2" defaultMessage="NNA Info" />,
  DigitalCertificate: (
    <FormattedMessage
      id="e969494f-c874-4aa2-b817-7cb56c841cae"
      defaultMessage="Digital Certificate"
    />
  ),
  SignatureAndSeal: (
    <FormattedMessage
      id="cb19ff58-06aa-4b47-a068-d7db1e46a847"
      defaultMessage="Signature and Seal"
    />
  ),
  StateEducation: (
    <FormattedMessage
      id="ad924c22-b107-4614-8bcf-b9cb11427301"
      defaultMessage="State Required Education"
    />
  ),
  PayoutOptions: (
    <FormattedMessage
      id="889fb199-66b3-4732-afc2-1e1938ebb7e9"
      defaultMessage="Payment Information"
    />
  ),
  IdentityVerification: (
    <FormattedMessage
      id="8b42e9f1-9ea6-42af-a522-753a80c358ce"
      defaultMessage="Identity Verification"
    />
  ),
  ProofCertificate: (
    <FormattedMessage
      id="08554224-1e43-4320-9c73-88de4b5b4cb5"
      defaultMessage="Digital Certificate"
    />
  ),
  SubmitProfile: (
    <FormattedMessage id="9200de39-e36e-48f9-8d8c-a95b438f4b35" defaultMessage="Submit Profile" />
  ),
  ProofAcademy: (
    <FormattedMessage
      id="dc55e6eb-af69-47d1-aa46-1aaeee87bc09"
      defaultMessage="Proof Academy Training"
    />
  ),
  ProfileApproval: (
    <FormattedMessage id="ef1346b7-ae9a-4fa7-af69-dae767185167" defaultMessage="Profile Approval" />
  ),
});

export type Step = {
  id: keyof typeof STEP_CONTENT;
  isActive?: boolean;
  completed?: boolean;
};

type Props = {
  steps: readonly Step[];
  fullName: string;
};

function Sidebar({ steps, fullName }: Props) {
  return (
    <div className={Styles.main} data-automation-id="notary-onboarding-sidebar">
      <div>
        <h5>
          <FormattedMessage
            id="f93721fd-71f0-45c9-8f7e-0b21608032ac"
            defaultMessage="Notary onboarding"
          />
        </h5>
        <div className={Styles.nameText}>{fullName}</div>
      </div>
      <ol>
        {steps.map(({ id, isActive, completed }) => {
          const content = STEP_CONTENT[id];
          const cx = classnames({
            [Styles.active]: isActive,
            [Styles.icon]: completed,
            "icon-tick": completed,
          });
          return (
            <li key={id} className={cx}>
              {completed && !isActive ? (
                <Link underlined={false} to={`/settings/notary/onboarding/${id}`}>
                  {content}
                </Link>
              ) : (
                content
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default memo(Sidebar);
