import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";

import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import {
  DeprecatedFirstNameField,
  DeprecatedLastNameField,
  DeprecatedMiddleNameField,
} from "common/form/fields/name_fields";
import { DeprecatedEmailField } from "common/form/fields/email";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";

class OrganizationTransactionWitnessesSubForm extends PureComponent {
  render() {
    const { readOnly, displayRequiredAsterisk } = this.props;

    const nameRowFields = [
      "organizationTransactionWitnesses[0].firstName",
      "organizationTransactionWitnesses[0].middleName",
      "organizationTransactionWitnesses[0].lastName",
    ];

    return (
      <div className="OrganizationTransactionWitnessesSubForm">
        <DeprecatedFormRow>
          <FormGroup
            errorClassName="OrganizationTransactionWitnessesSubForm__validationFailed"
            fields={nameRowFields}
            disableFormRowStyle
          >
            <DeprecatedMultipartRow>
              <DeprecatedMultipartColumn width={5}>
                <DeprecatedFirstNameField
                  id="organizationTransactionWitnesses[0].firstName"
                  name="organizationTransactionWitnesses[0].firstName"
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="first-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={3}>
                <DeprecatedMiddleNameField
                  id="organizationTransactionWitnesses[0].middleName"
                  name="organizationTransactionWitnesses[0].middleName"
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="middle-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={5}>
                <DeprecatedLastNameField
                  id="organizationTransactionWitnesses[0].lastName"
                  name="organizationTransactionWitnesses[0].lastName"
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="last-name-field"
                  disabled={readOnly}
                />
              </DeprecatedMultipartColumn>
            </DeprecatedMultipartRow>
            <FormGroupErrors
              fields={nameRowFields}
              errorClassName="OrganizationTransactionWitnessesSubForm--ValidationMessage"
            />
          </FormGroup>
        </DeprecatedFormRow>

        <DeprecatedFormRow>
          <FormGroup
            errorClassName="OrganizationTransactionWitnessesSubForm__validationFailed"
            fields={["organizationTransactionWitnesses[0].email"]}
            disableFormRowStyle
          >
            <DeprecatedEmailField
              id="organizationTransactionWitnesses[0].email"
              name="organizationTransactionWitnesses[0].email"
              data-automation-id="email-field"
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk={displayRequiredAsterisk}
              disabled={readOnly}
            />
            <FormGroupErrors
              fields={["organizationTransactionWitnesses[0].email"]}
              errorClassName="OrganizationTransactionWitnessesSubForm--ValidationMessage"
            />
          </FormGroup>
        </DeprecatedFormRow>
      </div>
    );
  }
}

OrganizationTransactionWitnessesSubForm.propTypes = {
  displayRequiredAsterisk: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
};

export default OrganizationTransactionWitnessesSubForm;
