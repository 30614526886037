import "./index.scss";

import { memo, useRef, type ComponentProps } from "react";

import BeholderHeader from "common/meeting/beholder/header";
import { Hidden } from "common/core/responsive";
import { getCurrentPenholderParticipant, type getPrimaryParticipant } from "common/meeting/util";

import type {
  WitnessMeetingDocument_meetingParticipants_WitnessParticipant as WitnessParticipant,
  WitnessMeetingDocument_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
  WitnessMeetingDocument_meetingParticipants_SignerParticipant as SignerParticipant,
  WitnessMeetingDocument as Meeting,
} from "./index_fragment.graphql";
import WitnessMeetingPDFV3 from "./v3";

export type ActiveParticipant =
  | WitnessParticipant
  | SignerParticipant
  | IdentityVerifiedWitnessParticipant;

type PdfProps = ComponentProps<typeof WitnessMeetingPDFV3>;
type Props = {
  meeting: Meeting;
  query: PdfProps["query"];
  user: Parameters<typeof getPrimaryParticipant>[1];
  onShowNotaryPointer?: PdfProps["onShowNotaryPointer"];
  notaryPointer: PdfProps["notaryPointer"];
  indicatedDesignation: PdfProps["indicatedDesignation"];
  interaction$: PdfProps["interaction$"];
  witnessParticipant: WitnessParticipant | IdentityVerifiedWitnessParticipant;
};

function WitnessMeetingDocumentBundle({
  notaryPointer,
  onShowNotaryPointer,
  indicatedDesignation,
  meeting,
  query,
  witnessParticipant,
  user,
  interaction$,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const currentPenHolderParticipant = getCurrentPenholderParticipant(
    meeting,
    user,
  ) as ActiveParticipant | null;

  return (
    <div className="WitnessMeetingDocumentBundle" ref={containerRef}>
      <Hidden xs sm>
        <BeholderHeader meeting={meeting} />
      </Hidden>
      {/* This is used by both notary and signer portals, so thats why there's two flags. */}
      <WitnessMeetingPDFV3
        meeting={meeting}
        query={query}
        interaction$={interaction$}
        witnessParticipant={witnessParticipant}
        user={user}
        onShowNotaryPointer={onShowNotaryPointer}
        indicatedDesignation={indicatedDesignation}
        notaryPointer={notaryPointer}
        currentPenHolderParticipant={currentPenHolderParticipant}
      />
    </div>
  );
}

export default memo(WitnessMeetingDocumentBundle);
