import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { useLogout } from "common/authentication";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import request from "util/request";
import { useActiveOrganization } from "common/account/active_organization";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";

type Props = {
  onClose: () => void;
};

const MESSAGES = defineMessages({
  title: {
    id: "afef04d0-6e46-40fc-9fca-7d5ab9bfa959",
    defaultMessage: "Sign documents now",
  },
  cancel: {
    id: "3a07bb17-f8b6-4673-aea7-76d3b238e360",
    defaultMessage: "Cancel",
  },
  continue: {
    id: "64a7609c-7da2-49a1-8d67-c915602442cc",
    defaultMessage: "Continue",
  },
  info: {
    id: "4476d994-43ca-4ada-a730-72862c213fa4",
    defaultMessage:
      "You will be logged out and redirected to the document upload page. The signer can then upload and sign their documents.",
  },
});

export function SignOnDeviceModal({ onClose }: Props) {
  const intl = useIntl();
  const logout = useLogout();
  const [activeOrganizationId] = useActiveOrganization();
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    await request("post", "accounts", {
      type: "single_use_signer",
      affiliated_organization_id: activeOrganizationId,
    });
    await logout();
    redirectToSubdomain(AppSubdomains.customer, "/signup/upload");
  };

  return (
    <WorkflowModal
      title={intl.formatMessage(MESSAGES.title)}
      buttons={[
        <Button key="cancel" buttonColor="dark" variant="tertiary" onClick={onClose}>
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          key="continue"
          buttonColor="action"
          variant="primary"
          onClick={handleContinue}
          isLoading={loading}
        >
          {intl.formatMessage(MESSAGES.continue)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      {intl.formatMessage(MESSAGES.info)}
    </WorkflowModal>
  );
}

export function useSignOnDevice() {
  const [showSignOnDeviceModal, setShowSignOnDeviceModal] = useState(false);

  return {
    showSignOnDeviceModal,
    setShowSignOnDeviceModal,
  };
}
