import { defineMessages, useIntl } from "react-intl";

import { DeprecatedTextField } from "common/form/fields/text";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import { DeprecatedFormRow } from "common/form/elements/row";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";

const MESSAGES = defineMessages({
  fileNumber: {
    id: "0242fbc4-f561-43ae-8f9a-adc94a37459a",
    defaultMessage: "File number",
  },
  loanNumber: {
    id: "aaa81ae8-cbe4-4b0b-a932-98405d243692",
    defaultMessage: "Loan number",
  },
});

function TransactionDetails() {
  const intl = useIntl();

  return (
    <SubForm>
      <SubFormSection>
        <DeprecatedFormRow>
          <DeprecatedTextField
            id="fileNumber"
            name="fileNumber"
            data-automation-id="file-number"
            placeholder={intl.formatMessage(MESSAGES.fileNumber)}
            placeholderAsLabel
            useStyledInput
          />
        </DeprecatedFormRow>
        <DeprecatedFormRow>
          <DeprecatedTextField
            id="loanNumber"
            name="loanNumber"
            data-automation-id="loan-number"
            placeholder={intl.formatMessage(MESSAGES.loanNumber)}
            placeholderAsLabel
            useStyledInput
          />
        </DeprecatedFormRow>
      </SubFormSection>
    </SubForm>
  );
}

export default deprecatedSubForm({
  getValuesFor: [],
})(TransactionDetails);
