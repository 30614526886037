import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { v4 } from "uuid";
import classnames from "classnames";

import { NotaryComplianceStatuses, NotaryOnboardingStatuses } from "graphql_globals";
import Link from "common/core/link";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import ProofByotMarketingImage from "assets/images/get_started/megaphone.svg";
import NotaryToolsImage from "assets/images/get_started/notary-tools.svg";
import { Heading, Paragraph } from "common/core/typography";

import { LockedLink } from "../locked_link";
import Styles from "./index.module.scss";

type NotaryToolsHowToItemProps = {
  notaryProfile: {
    onboardingStatus: NotaryOnboardingStatuses;
    complianceStatus: NotaryComplianceStatuses;
  };
};
type HowToItemProps = {
  img: ComponentPropsWithoutRef<"img">;
  title: ReactNode;
  info: ReactNode;
  links: ReactNode;
};

export function HowToItem(props: HowToItemProps) {
  return (
    <div className={Styles.howToItem}>
      <div className={Styles.imageContainer}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img {...props.img} className={classnames(Styles.image, props.img.className)} />
      </div>
      <Heading level="h4" textStyle="headingFour" className={Styles.title}>
        {props.title}
      </Heading>
      <Paragraph textColor="subtle" className={Styles.info}>
        {props.info}
      </Paragraph>
      <div className={Styles.links}>{props.links}</div>
    </div>
  );
}

export function NotaryGrowRonHowToItem() {
  return (
    <HowToItem
      img={{ src: ProofByotMarketingImage, alt: "" }}
      title={
        <FormattedMessage
          id="643cfa72-fe7e-4efb-b7b9-49a017f9ee5e"
          defaultMessage="Explore marketing resources to help grow your business"
        />
      }
      info={
        <FormattedMessage
          id="730d9d41-7c75-4e27-a8a1-f2488714516a"
          defaultMessage="Find out how to share with your customers that you've added notarizations."
        />
      }
      links={
        // TODO - This link is not finalized yet
        <Link href="https://www.proof.com/academy/business-academy">
          <FormattedMessage id="0f3a5c6d-a09d-4e44-84d6-a0d454982a43" defaultMessage="Learn more" />
        </Link>
      }
    />
  );
}

function getNotaryToolsLink({ notaryProfile }: NotaryToolsHowToItemProps) {
  if (notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED) {
    return (
      <LockedLink>
        <FormattedMessage
          id="d249ba82-44c5-498b-a8e6-8cc4902c25b4"
          defaultMessage="To unlock access, complete your <link>notary profile</link>"
          values={{
            link: (msg) => (
              <Link
                onClick={() =>
                  redirectToSubdomain(AppSubdomains.customer, "/settings/notary/onboarding")
                }
              >
                {msg}
              </Link>
            ),
          }}
        />
      </LockedLink>
    );
  }

  if (notaryProfile.complianceStatus === NotaryComplianceStatuses.NOT_COMPLIANT) {
    return (
      <LockedLink>
        <FormattedMessage
          id="e4d950f4-5507-469e-8e24-4a6a81d5ea5a"
          defaultMessage="To unlock access, make the required changes to your <link>notary profile</link>"
          values={{
            link: (msg) => (
              <Link
                onClick={() =>
                  redirectToSubdomain(AppSubdomains.customer, "/settings/notary/profile", {
                    newTab: true,
                  })
                }
              >
                {msg}
              </Link>
            ),
          }}
        />
      </LockedLink>
    );
  }

  const redirectPortal =
    CURRENT_PORTAL === "title_agency"
      ? AppSubdomains.title_agency
      : CURRENT_PORTAL === "lender"
        ? AppSubdomains.title_agency
        : AppSubdomains.business;

  return (
    <Link
      onClick={() =>
        redirectToSubdomain(redirectPortal, `/simulated-meeting/meeting-${v4()}`, {
          newTab: true,
        })
      }
    >
      <FormattedMessage id="3894e1ef-b471-4542-a39a-71b95cb22c2b" defaultMessage="Try now" />
    </Link>
  );
}

export function NotaryToolsHowToItem(props: NotaryToolsHowToItemProps) {
  return (
    <HowToItem
      img={{
        src: NotaryToolsImage,
        "aria-hidden": "true",
        alt: "",
      }}
      title={
        <FormattedMessage
          id="78685a88-b28a-404b-87e7-9fd37bcea9e9"
          defaultMessage="Try out the notary tools"
        />
      }
      info={
        <FormattedMessage
          id="12589af4-da89-46cc-b0f6-d998469fa9eb"
          defaultMessage="Practice using the notary tools in a simulated meeting environment."
        />
      }
      links={getNotaryToolsLink(props)}
    />
  );
}
