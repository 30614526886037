import { useCallback } from "react";

import {
  NotaryOnboardingStatuses,
  NotaryComplianceStatuses,
  NotaryCapacityType,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";

type NotaryProfile = {
  capacities: {
    type: NotaryCapacityType;
    validation: { invalidFields: NotaryProfileInvalidFields[] };
  }[];
  onboardingStatus: NotaryOnboardingStatuses;
  complianceStatus: NotaryComplianceStatuses;
};

function isByotPaymentMissing({ capacities }: NotaryProfile) {
  return capacities.some((cap) => {
    return (
      cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
      cap.validation.invalidFields.some((invalidField) => {
        return (
          invalidField === NotaryProfileInvalidFields.INVALID_PAYMENT ||
          invalidField === NotaryProfileInvalidFields.MISSING_STRIPE_CONNECT
        );
      })
    );
  });
}

export function useNotaryCommonSetupRedirect(needsReviewRedirect: boolean = false) {
  return useCallback((notaryProfile: NotaryProfile) => {
    if (
      notaryProfile.complianceStatus === NotaryComplianceStatuses.COMPLIANT &&
      isByotPaymentMissing(notaryProfile)
    ) {
      // Notary payment settings is in organization settings in the business app
      redirectToSubdomain(AppSubdomains.business, "/settings/billing/payment-settings");
    } else {
      // Notary onboarding and personal settings are in the signer app
      const url =
        notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED
          ? "/settings/notary/onboarding"
          : "/settings/notary/profile";
      redirectToSubdomain(AppSubdomains.customer, url, { newTab: needsReviewRedirect });
    }
  }, []);
}
