import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Footer from "common/notary/onboarding/footer";
import { SealLogo } from "common/core/logo";
import Link from "common/core/link";
import { NOTARY_STATE_COMPLIANCE_URL } from "constants/marketing";
import { NotarizeNetwork } from "common/core/logo/notarize_network";

import Styles from "./index.module.scss";

type Props = {
  usStateName: string | undefined | null;
  onNext: ComponentProps<typeof Footer>["onSubmit"];
  independentNotary: boolean;
};

function NotaryOnboardingContent({ onNext, usStateName, independentNotary }: Props) {
  const complianceLink = usStateName
    ? `${NOTARY_STATE_COMPLIANCE_URL}/${usStateName.toLowerCase()}`
    : NOTARY_STATE_COMPLIANCE_URL;
  return (
    <>
      <div className={Styles.main}>
        <SealLogo className={Styles.logo} />

        {independentNotary ? (
          <FormattedMessage
            id="a121cabd-0367-46a0-bf86-3fe205a13aba"
            defaultMessage="Welcome to the {logo}!"
            tagName="h3"
            values={{ logo: <NotarizeNetwork /> }}
          />
        ) : (
          <FormattedMessage
            id="53f4d28a-802f-4f78-9f7c-5205c36e9757"
            defaultMessage="Welcome to Proof!"
            tagName="h3"
          />
        )}

        {independentNotary ? (
          <>
            <FormattedMessage
              id="985865a5-bcc1-41c8-8466-8bea1ae4027b"
              defaultMessage="Please visit <compliance>this link</compliance> to review the required training, tools, and credentials for your commissioning state that must be satisfied in order to perform notarizations on the Proof platform."
              values={{
                compliance: (text) => <Link href={complianceLink}>{text}</Link>,
              }}
              tagName="p"
            />
            <FormattedMessage
              id="09169c84-639c-4966-affe-fbf630fca028"
              defaultMessage="When you're ready, progress through the steps of our Onboarding Wizard to complete your notary profile. If you are missing any of the required documents, you can always save your progress and come back."
              tagName="p"
            />
            <FormattedMessage
              id="b1aa8c00-bd08-4ad6-a259-334ce977428a"
              defaultMessage="Once you have completed all required fields, the onboarding team will review your information for compliance and reach out by email with the next steps. You may contact them with questions at {onboardingLink}."
              values={{
                onboardingLink: (
                  <Link href="mailto:notaryonboarding@proof.com">notaryonboarding@proof.com</Link>
                ),
              }}
              tagName="p"
            />
          </>
        ) : (
          <FormattedMessage
            id="3f9510fe-31d2-4475-bc78-4226d773af1f"
            defaultMessage="Please follow the steps to fill out your notary profile. If you are missing any of the required documents you can always save your progress and come back. Once you have completed everything, the onboarding team will review your information and reach out with the next steps."
            tagName="p"
          />
        )}
        <FormattedMessage
          id="7262916a-c06c-4293-aca3-93529e2e250b"
          defaultMessage="Thank you for your interest in joining the Proof platform!"
          tagName="p"
        />
      </div>
      <Footer onSubmit={onNext} />
    </>
  );
}

export default memo(NotaryOnboardingContent);
