import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { hardNavigateTo } from "util/navigation";
import AppSubdomains from "constants/app_subdomains";
import { redirectUrl } from "util/application_redirect";
import { b } from "util/html";
import { PROOF_WORKRAMP_REGISTER_URL } from "constants/support";
import { isNotaryODN } from "common/notary/capacity";
import Button from "common/core/button";
import { Heading } from "common/core/typography";
import ApprovalImage from "assets/images/notary_profile_approval.svg";
import TrainingImage from "assets/images/proof_academy.png";
import { useLazyQuery } from "util/graphql/query";
import type { NotaryActivationStatus, NotaryCapacityType } from "graphql_globals";

import WrInstantAuthQuery, {
  type WorkrampInstantAuthUrl_notaryProfile_NotaryProfile as NotaryProfile,
} from "./wr_instant_auth_query.graphql";
import Styles from "./finished.module.scss";

type NotaryProfileWithCapacity = {
  id: string;
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
  }[];
};

type Props = {
  notaryProfile: NotaryProfileWithCapacity | null;
  includeContinue?: boolean;
};

function FinishedOnboardingSection({ notaryProfile, includeContinue }: Props) {
  const isOdn = isNotaryODN(notaryProfile);
  const [trainingLinkClicked, setTrainingLinkClicked] = useState(false);
  // need to make this additional query because the WR instant auth url needs to be generated right at the time of use + expires after 2 mins
  const [fetchTrainingUrl, { loading }] = useLazyQuery(WrInstantAuthQuery, {
    fetchPolicy: "no-cache",
  });
  return (
    <div className={Styles.main}>
      <header>
        <p className={Styles.headerEmoji} aria-hidden="true">
          🎉
        </p>
        <FormattedMessage
          id="d4b87bb2-b683-43a8-8c97-e5ae155170ea"
          defaultMessage="Profile submitted!"
          tagName="h1"
        />
        <p className={Styles.tagLine}>
          <FormattedMessage
            id="c6772df5-b888-4c39-961a-01d1bb660fa3"
            defaultMessage="Thank you for submitting your profile. There are a few more steps..."
          />
        </p>
      </header>

      <div className={Styles.steps}>
        <div aria-hidden="true" className={Styles.stepsBar} />
        <Heading level="h2" textStyle="headingFive" className={Styles.activeStep}>
          <FormattedMessage
            id="4ca5bd69-22bb-46da-8748-f8f3f963ba20"
            defaultMessage="Next, create an account to begin training"
          />
        </Heading>
        <div className={Styles.card}>
          <div>
            <Heading level="h3" textStyle="headingSix">
              <FormattedMessage
                id="48dc4416-195c-45a0-b86f-73b56e5cbea8"
                defaultMessage="Learn on Proof Academy{isOdn, select, true{ as a Notarize Network Notary} other{}}"
                values={{ isOdn }}
              />
            </Heading>
            <FormattedMessage
              id="4f844734-1522-42a5-9d33-e6b2cd02dda9"
              defaultMessage="Begin platform training today by registering for a Proof Academy account. {isOdn, select, true{Onboarding is contingent upon completion of our required platform certification} other{Obtaining your platform certification is highly recommended for best use of the platform}}."
              values={{ isOdn }}
              tagName="p"
            />
            <Button
              buttonColor="action"
              variant="primary"
              withIcon={{ placement: "left", name: "new-window" }}
              disabled={trainingLinkClicked}
              isLoading={loading}
              onClick={() => {
                fetchTrainingUrl({
                  variables: { notaryProfileId: notaryProfile!.id },
                }).then((result) => {
                  const notaryProfileWithWr = result.data?.notaryProfile as NotaryProfile;
                  const trainingUrl =
                    notaryProfileWithWr.workrampInstantAuthUrl || PROOF_WORKRAMP_REGISTER_URL;
                  hardNavigateTo(trainingUrl, { newTab: true });
                  setTrainingLinkClicked(true);
                });
              }}
            >
              <FormattedMessage
                id="aeadd69b-1c8c-468a-bff3-5c40e3d79afe"
                defaultMessage="Begin training"
              />
            </Button>
          </div>
          <aside className={Styles.trainingImageContainer}>
            <img alt="" src={TrainingImage} />
          </aside>
        </div>

        <Heading
          level="h2"
          textStyle="headingFive"
          className={trainingLinkClicked ? Styles.activeStep : undefined}
        >
          <FormattedMessage
            id="9bf82ef8-5132-48b9-bb89-b1e2fee00086"
            defaultMessage="Then, we'll review your profile"
          />
        </Heading>
        <div className={Styles.card}>
          <div>
            <Heading level="h3" textStyle="headingSix" className={Styles.activeStep}>
              <FormattedMessage
                id="1604d97a-10fd-40c4-aa38-9264a3e3f740"
                defaultMessage="Look out for a profile review"
              />
            </Heading>
            <FormattedMessage
              id="e391c410-1765-4df7-8f0a-996bce7f4831"
              defaultMessage="Your profile will be reviewed by one of our Notary Onboarding Specialists who will help guide you through the next steps of onboarding. Please allow us up to <b>2 business days</b> to contact you via email with next steps."
              values={{ b }}
              tagName="p"
            />
          </div>
          <img alt="" src={ApprovalImage} />
        </div>

        <Heading level="h2" textStyle="headingFive">
          <FormattedMessage
            id="b50813f1-7ddd-40f9-bc7f-93a264b54642"
            defaultMessage="Once approved, you can start using Proof!"
          />
        </Heading>
      </div>

      {includeContinue && (
        <footer className={Styles.footer}>
          <Button
            buttonColor="action"
            variant="primary"
            disabled={!trainingLinkClicked}
            onClick={() => hardNavigateTo(redirectUrl(AppSubdomains.business, "/get-started"))}
          >
            <FormattedMessage id="044a7673-15c5-44dd-bf84-6226d1320047" defaultMessage="Continue" />
          </Button>
        </footer>
      )}
    </div>
  );
}

export default memo(FinishedOnboardingSection);
