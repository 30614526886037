import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import SubFormSection from "common/form/sub_form/section";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import SectionHeader from "common/form/sub_form/section/header";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";

import OrganizationTransactionWitnesses from "./organization_transaction_witness";
import Styles from "./index.module.scss";

const messages = defineMessages({
  organizationTransactionWitnessesRequired: {
    id: "fb415f63-02a6-403b-95fe-1b6716db4d92",
    defaultMessage: "Does this transaction require a credible witness?",
  },
  organizationTransactionWitnessesRequiredSubtitle: {
    id: "e20b8266-1eeb-4bbe-aa9c-44e2e0bb14ff",
    defaultMessage:
      "If the signer does not have a US SSN or US ID card, they can use a credible witness to identify them. A credible witness must have a US SSN and valid ID and join the meeting to verify the signer's identity.",
  },
  phoneNumber: {
    id: "121f156d-c6d4-4c83-954e-323e4c257a3c",
    defaultMessage: "Phone Number",
  },
  no: {
    id: "4c9b3683-2de9-43cf-8f52-27b59023e680",
    defaultMessage: "No",
  },
  yes: {
    id: "0cea4fe4-7e05-4f20-8fe0-accec0376f11",
    defaultMessage: "Yes",
  },
});

type Props = {
  change: (formValue: string, value: [] | null) => void;
  organizationTransactionWitnesses?: {
    firstName: string;
    lastName: string;
    email: string;
    middleName?: string;
    phoneNumber?: string;
  }[];
  readOnly?: boolean;
};

export default function CredibleWitnessSection(props: Props) {
  const { change, organizationTransactionWitnesses = [], readOnly = false } = props;
  const intl = useIntl();
  const [
    showOrganizationTransactionWitnessesSection,
    setShowOrganizationTransactionWitnessesSection,
  ] = useState(false);

  return (
    <>
      <SubFormSectionDivider />
      <SubFormSection className={Styles.credibleWitnessSection}>
        <DeprecatedFormRow>
          <SectionHeader
            title={intl.formatMessage(messages.organizationTransactionWitnessesRequired)}
            subtitle={intl.formatMessage(messages.organizationTransactionWitnessesRequiredSubtitle)}
          />
        </DeprecatedFormRow>
        <DeprecatedFormRow>
          <DeprecatedRadioButtonField
            id="showOrganizationTransactionWitnessesSection"
            name="showOrganizationTransactionWitnessesSection"
            labelText={intl.formatMessage(messages.no)}
            automationId="credible-witness-not-required-radio-button"
            className={Styles.credibleWitnessSectionRadioButton}
            labelClassName={Styles.credibleWitnessSectionRadioLabel}
            radioValue={false}
            size="small"
            groupValue={
              showOrganizationTransactionWitnessesSection ||
              Boolean(organizationTransactionWitnesses.length)
            }
            onChange={() => {
              setShowOrganizationTransactionWitnessesSection(false);
              change("organizationTransactionWitnesses", []);
            }}
            disabled={readOnly}
          />
          <DeprecatedRadioButtonField
            id="showOrganizationTransactionWitnessesSection"
            name="showOrganizationTransactionWitnessesSection"
            labelText={intl.formatMessage(messages.yes)}
            automationId="credible-witness-required-radio-button"
            className={Styles.credibleWitnessSectionRadioButton}
            labelClassName={Styles.credibleWitnessSectionRadioLabel}
            radioValue
            size="small"
            groupValue={
              showOrganizationTransactionWitnessesSection ||
              Boolean(organizationTransactionWitnesses.length)
            }
            onChange={() => {
              setShowOrganizationTransactionWitnessesSection(true);
            }}
            disabled={readOnly}
          />
        </DeprecatedFormRow>

        {(showOrganizationTransactionWitnessesSection ||
          Boolean(organizationTransactionWitnesses.length)) && (
          <>
            <DeprecatedFormRow noMargin>
              <OrganizationTransactionWitnesses
                id="organizationTransactionWitnesses"
                name="organizationTransactionWitnesses"
                displayRequiredAsterisk
                readOnly={readOnly}
              />
            </DeprecatedFormRow>
            <DeprecatedFormRow>
              <FormGroup
                fields={["organizationTransactionWitnesses[0].phoneNumber"]}
                disableFormRowStyle
              >
                <DeprecatedPhoneNumberField
                  id="organizationTransactionWitnesses[0].phoneNumber"
                  name="organizationTransactionWitnesses[0].phoneNumber"
                  data-automation-id="organizationTransactionWitnesses-phone-number-field"
                  label={intl.formatMessage(messages.phoneNumber)}
                  readOnly={readOnly}
                />
              </FormGroup>
            </DeprecatedFormRow>
          </>
        )}
      </SubFormSection>
    </>
  );
}
