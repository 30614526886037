import { memo, type ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { PhotoIdVerificationFailureReasons } from "graphql_globals";
import Button from "common/core/button";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import {
  PICTURE_ID_ACTION_REQUIRED,
  PICTURE_ID_FAILED,
  PICTURE_ID_SUCCESS,
  PICTURE_ID_PENDING,
  PICTURE_ID_UNKNOWN,
  DRIVERS_LICENSE,
  CERTIFICATE_OF_CITIZENSHIP,
} from "constants/id_validation";
import CredentialSuccessIcon from "assets/images/meeting/credential_analysis/credential_success.svg";
import CredentialPendingIcon from "assets/images/meeting/credential_analysis/credential_pending.svg";
import AlertMessage from "common/core/alert_message";

import { messages } from "./messages";

type Props = {
  status: string;
  documentClaimedType: string;
  refresh: () => void;
  onChangeAction: (newValue: string) => void;
  runValidation: () => void;
  isLoading?: boolean;
  actionValue: string;
  subMessage: ReactNode;
  failureReasons: { check: PhotoIdVerificationFailureReasons }[] | null;
};
type RealMessage = NonNullable<ReturnType<typeof getFailureReasonMessage>>;

const MESSAGES = defineMessages({
  missingBarcodeTitle: {
    id: "ea70c86c-ae8f-4a84-9a16-c14567c78c4a",
    defaultMessage: "ID barcode missing",
  },
  missingBarcodeDescription: {
    id: "d007b547-cd7a-4cfe-b557-71bf751e7c86",
    defaultMessage:
      "Please review for a potential missing or unreadable barcode and request a retake",
  },
  errorBarcodeTitle: {
    id: "4538ae76-2136-4b47-a9e4-0b631af5e86a",
    defaultMessage: "ID info may not match profile",
  },
  errorBarcodeDescription: {
    id: "8e834321-bb12-42e0-88ad-745ca36dd497",
    defaultMessage:
      "The ID does not match the signer’s profile. Please review and request a retake",
  },
  electronicReplicaTitle: {
    id: "75f32774-78d3-4e87-a261-9b031e9bb27d",
    defaultMessage: "Electronic replica detected",
  },
  electronicReplicaDesc: {
    id: "ac7ddbde-9a83-4de9-9e53-869196b4e550",
    defaultMessage:
      "Please review if this ID is a replica of an image presented on another screen or electronic device",
  },
  idExpiredTitle: {
    id: "f191c64c-7aad-4bc8-987e-4f3051bbcf81",
    defaultMessage: "ID may be expired",
  },
  idExpiredDesc: {
    id: "90715ef3-0706-43b3-b9bf-de4e4598e485",
    defaultMessage:
      "Please review expiration date and your state's ID requirements for expired IDs",
  },
  invalidDatesTitle: {
    id: "084e43e3-6519-4038-943b-11d10001c001",
    defaultMessage: "ID dates may be invalid",
  },
  invalidDatesDesc: {
    id: "718272c5-dc39-41ee-bcdc-2798f7daf187",
    defaultMessage:
      "Please review issuing and expiration dates track with standards of issuing state",
  },
});

function getPhotoIdStatus(
  intl: ReturnType<typeof useIntl>,
  status: string,
  canRerunPhotoValidation: boolean,
) {
  switch (status) {
    case PICTURE_ID_ACTION_REQUIRED:
      return intl.formatMessage(messages.statusActionRequired);
    case PICTURE_ID_SUCCESS:
      return intl.formatMessage(messages.statusSuccess);
    case PICTURE_ID_PENDING:
      return intl.formatMessage(messages.statusPending);
    case PICTURE_ID_FAILED:
    case PICTURE_ID_UNKNOWN:
    default:
      return canRerunPhotoValidation
        ? intl.formatMessage(messages.statusUnknown)
        : intl.formatMessage(messages.statusUnknownRequiresRetake);
  }
}

function getPhotoIdStatusIcon(status: string) {
  switch (status) {
    case PICTURE_ID_ACTION_REQUIRED:
    case PICTURE_ID_SUCCESS:
      return CredentialSuccessIcon;
    case PICTURE_ID_PENDING:
      return CredentialPendingIcon;
    case PICTURE_ID_FAILED:
    case PICTURE_ID_UNKNOWN:
    default:
      return null;
  }
}

export function getFailureReasonMessage(check: PhotoIdVerificationFailureReasons) {
  switch (check) {
    case PhotoIdVerificationFailureReasons.BARCODE_ERROR:
      return {
        title: MESSAGES.missingBarcodeTitle,
        description: MESSAGES.missingBarcodeDescription,
      };
    case PhotoIdVerificationFailureReasons.BARCODE_INCONSISTENCY:
    case PhotoIdVerificationFailureReasons.BARCODE_PROPERTIES_MISMATCH:
      return {
        title: MESSAGES.errorBarcodeTitle,
        description: MESSAGES.errorBarcodeDescription,
      };
    case PhotoIdVerificationFailureReasons.ID_EXPIRED:
      return {
        title: MESSAGES.idExpiredTitle,
        description: MESSAGES.idExpiredDesc,
      };
    case PhotoIdVerificationFailureReasons.ELECTRONIC_REPLICA:
      return {
        title: MESSAGES.electronicReplicaTitle,
        description: MESSAGES.electronicReplicaDesc,
      };
    case PhotoIdVerificationFailureReasons.INVALID_DATES:
      return {
        title: MESSAGES.invalidDatesTitle,
        description: MESSAGES.invalidDatesDesc,
      };
    default:
      return null;
  }
}

function PhotoIdStatus(props: Props) {
  const { documentClaimedType, status, actionValue, onChangeAction } = props;
  const intl = useIntl();
  const canRerunPhotoValidation =
    documentClaimedType === DRIVERS_LICENSE || documentClaimedType === CERTIFICATE_OF_CITIZENSHIP;
  const photoIconPresent = getPhotoIdStatusIcon(status);
  const withMargin = photoIconPresent ? "--withMargin" : "";
  // We use a map to dedupe messages
  const failureReasonMessages = new Map<RealMessage, PhotoIdVerificationFailureReasons>(
    props.failureReasons?.flatMap(({ check }) => {
      const message = getFailureReasonMessage(check);
      return message ? [[message, check]] : [];
    }),
  );
  return (
    <div className="MeetingCredentialAnalysis--content--section credentialAnalysisStatus">
      <div className="MeetingCredentialAnalysis--textMain title">
        <FormattedMessage
          id="51335d64-c745-47ed-9047-2ca6aeadeecf"
          defaultMessage="Identity alerts ({numAlerts})"
          values={{ numAlerts: failureReasonMessages.size }}
        />
      </div>
      <div className="MeetingCredentialAnalysis--verificationStatus">
        <div className="MeetingCredentialAnalysis--textMain">
          {photoIconPresent && <img alt="status-icon" src={photoIconPresent} />}
          {getPhotoIdStatus(intl, status, canRerunPhotoValidation)}
        </div>
        <div className={`MeetingCredentialAnalysis--subText${withMargin}`}>{props.subMessage}</div>
        {status === PICTURE_ID_PENDING && (
          <div
            className="MeetingCredentialAnalysis--statusLink"
            onClick={props.refresh}
            data-automation-id="refresh-status"
          >
            <div className="MeetingCredentialAnalysis--statusLink--text">
              {intl.formatMessage(messages.refresh)}
            </div>
          </div>
        )}
        {status === PICTURE_ID_ACTION_REQUIRED && (
          <div className="MeetingCredentialAnalysis--ActionOptions">
            <DeprecatedRadioButton
              labelText={
                <FormattedMessage
                  id="9d2d48e0-4a6c-48f7-8899-a5303caa2c01"
                  defaultMessage="Information matches"
                />
              }
              radioValue="matches"
              onChange={onChangeAction}
              groupValue={actionValue}
              size="small"
            />
            <DeprecatedRadioButton
              labelText={
                <FormattedMessage
                  id="ac215341-f07e-47f1-9ead-b98312830755"
                  defaultMessage="Information does not match"
                />
              }
              radioValue="doesnotmatch"
              onChange={onChangeAction}
              groupValue={actionValue}
              size="small"
            />
          </div>
        )}
        {canRerunPhotoValidation &&
          (status === PICTURE_ID_UNKNOWN || status === PICTURE_ID_FAILED) && (
            <Button
              className="MeetingCredentialAnalysis--runValidation"
              buttonColor="action"
              variant="primary"
              automationId="run-validation"
              isLoading={props.isLoading}
              onClick={props.runValidation}
            >
              {intl.formatMessage(messages.runValidationButton)}
            </Button>
          )}
      </div>
      {Array.from(failureReasonMessages.entries()).map(([message, check]) => (
        <AlertMessage key={check} kind="warning" className="MeetingCredentialAnalysis--fraudSignal">
          <div>
            <p>{intl.formatMessage(message.title)}</p>
            <p>{intl.formatMessage(message.description)}</p>
          </div>
        </AlertMessage>
      ))}
      <div className="MeetingCredentialAnalysis--fraudSignalPadding" aria-hidden="true" />
    </div>
  );
}

export default memo(PhotoIdStatus);
