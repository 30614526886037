import { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { PICTURE_ID_PENDING, PICTURE_ID_SUCCESS, PASSPORT, ID_CARD } from "constants/id_validation";

import { messages } from "./messages";

class DocumentCredentialsForm extends Component {
  componentDidMount() {
    const { signerIdentity, initialize } = this.props;
    initialize(DocumentCredentialsForm.initialFormValues(signerIdentity));
  }

  static initialFormValues(signerIdentity) {
    const { documentNumber, documentClassification, documentIssuer } = signerIdentity.photoId;
    return {
      documentIssuer,
      documentClassification,
      documentNumber,
    };
  }

  render() {
    const { signerIdentity, intl } = this.props;
    const {
      photoId: { status, documentClaimedType, verified },
    } = signerIdentity;

    const isInvalidClaimedDocType = [PASSPORT, ID_CARD].includes(documentClaimedType);
    const isPending = status === PICTURE_ID_PENDING;
    const successfulVerification = verified && status === PICTURE_ID_SUCCESS;
    const disableFields = isInvalidClaimedDocType || isPending || successfulVerification;

    return (
      <>
        <div className="MeetingCredentialAnalysis--textMain">
          {intl.formatMessage(messages.credentialInformation)}
        </div>
        <div className="MeetingCredentialAnalysis--inputField documentCredentials">
          <FormGroup
            fields={["documentIssuer", "documentClassification"]}
            className="Form-row--last"
            errorClassName="CredentialAnalysis--ValidationFailed"
          >
            <DeprecatedMultipartRow>
              <DeprecatedMultipartColumn width={4}>
                <DeprecatedTextField
                  id="documentIssuer"
                  name="documentIssuer"
                  placeholder={intl.formatMessage(messages.documentIssuer)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="credential-issuer-field"
                  disabled={disableFields}
                  autoComplete="off"
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={6}>
                <DeprecatedTextField
                  id="documentClassification"
                  name="documentClassification"
                  placeholder={intl.formatMessage(messages.documentClassification)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="credential-type-field"
                  disabled={disableFields}
                  autoComplete="off"
                />
              </DeprecatedMultipartColumn>
            </DeprecatedMultipartRow>
            <FormGroupErrors fields={["documentIssuer", "documentClassification"]} />
          </FormGroup>
          <div className="MeetingCredentialAnalysis--inputField">
            <FormGroup
              fields={["documentNumber"]}
              className="Form-row StyledText"
              errorClassName="CredentialAnalysis--documentNumber"
            >
              <DeprecatedTextField
                id="documentNumber"
                name="documentNumber"
                placeholder={intl.formatMessage(messages.documentNumber)}
                placeholderAsLabel
                useStyledInput
                data-automation-id="document-number-field"
                disabled={disableFields}
                autoComplete="off"
              />
              <FormGroupErrors fields={["documentNumber"]} />
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
}

DocumentCredentialsForm.propTypes = {
  signerIdentity: PropTypes.shape({
    photoId: PropTypes.shape({
      status: PropTypes.string,
      verified: PropTypes.bool,
      dob: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      documentAddress: PropTypes.object,
      documentNumber: PropTypes.string,
      documentIssuer: PropTypes.string,
      documentClaimedType: PropTypes.string,
      documentClassification: PropTypes.string,
    }),
  }).isRequired,
  initialize: PropTypes.func,
};

DocumentCredentialsForm.defaultProps = {
  initialize: () => {},
};

export default injectIntl(DocumentCredentialsForm);
