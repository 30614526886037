import ActionsIndicator from "common/core/actions_indicator";
import { DocumentBundleMembershipRole } from "graphql_globals";

import type {
  NotaryMeetingBundleNavigator_meetingParticipants as MeetingParticipants,
  NotaryMeetingBundleNavigator_documentBundle_documents_edges_node as Document,
  NotaryMeetingBundleNavigator_meetingParticipants_WitnessParticipant as WitnessParticipant,
  NotaryMeetingBundleNavigator_meetingParticipants_SignerParticipant as SignerParticipant,
  NotaryMeetingBundleNavigator_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "./index_fragment.graphql";

type Props = {
  currentDocumentNode: Document;
  meetingParticipants: MeetingParticipants[];
};

function signerParticipantsPredicate(
  participant: MeetingParticipants,
): participant is SignerParticipant | WitnessParticipant | IdentityVerifiedWitnessParticipant {
  return (
    participant.__typename === "SignerParticipant" ||
    participant.__typename === "WitnessParticipant" ||
    participant.__typename === "IdentityVerifiedWitnessParticipant"
  );
}

function getActionsCount(
  currentDocumentNode: Document,
  meetingParticipants: MeetingParticipants[],
) {
  const meetingParticipantSignerIndices = new Set(
    meetingParticipants
      .filter(signerParticipantsPredicate)
      .map((meetingParticipant) => meetingParticipant.signerRole.index),
  );
  const unfulfilledDesignations = currentDocumentNode.annotationDesignations.edges.filter(
    ({ node }) => {
      if (node.fulfilled || node.designationGroupId || !node.active) {
        return false;
      }
      const { index, role } = node.signerRole;
      return (
        // we only want designations for signers who are currently in the meeting
        role === DocumentBundleMembershipRole.NOTARY ||
        role === DocumentBundleMembershipRole.WITNESS ||
        meetingParticipantSignerIndices.has(index)
      );
    },
  );
  const unfulfilledDesignationGroups = currentDocumentNode.designationGroups.filter((group) => {
    if (group.numOutstandingFulfillments <= 0 || !group.signerRole) {
      return false;
    }
    const { index, role } = group.signerRole;
    return (
      role === DocumentBundleMembershipRole.NOTARY ||
      role === DocumentBundleMembershipRole.WITNESS ||
      meetingParticipantSignerIndices.has(index)
    );
  });
  return unfulfilledDesignations.length + unfulfilledDesignationGroups.length;
}

function SignerActionsCount({ currentDocumentNode, meetingParticipants }: Props) {
  const actionsCount = getActionsCount(currentDocumentNode, meetingParticipants);
  const areDesignationsDisabled = currentDocumentNode.hidden;
  return (
    <ActionsIndicator
      actionsCount={actionsCount}
      isDisabled={areDesignationsDisabled}
      hideTooltip
    />
  );
}

export default SignerActionsCount;
