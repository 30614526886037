import { memo, type ComponentProps } from "react";
import { useIntl, defineMessages } from "react-intl";

import { TEXT_ANNOTATION_PLACEHOLDER } from "constants/globals";
import {
  AnnotationGraphicTypes,
  AnnotationSubtype,
  CoordinateSystem,
  PageTypes,
} from "graphql_globals";
import { annotationPdfPointSizeFromText } from "common/pdf/util";
import { usePDFContext } from "common/pdf/pspdfkit";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import type { GraphicCache } from "common/meeting/context/graphic_cache";
import { forceAssetRecreation, type SignatureOptionsOrganization } from "util/signature_options";

import type {
  WitnessMeetingDocumentV3_meetingParticipants_WitnessParticipant as WitnessParticipant,
  WitnessMeetingDocumentV3_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "./index_fragment.graphql";
import { getCachedSizeForAnnotation } from ".";

type Props = {
  currentTool: string | null;
  witnessParticipant: WitnessParticipant | IdentityVerifiedWitnessParticipant;
  organization: SignatureOptionsOrganization;
  cache: GraphicCache;
};

const MESSAGES = defineMessages({
  signature: {
    id: "6b1afccc-6f35-4dfa-9431-e776b75434aa",
    defaultMessage: "Your Signature Here",
  },
});

function textAssetPlaceholder(
  text: string,
  location: ComponentProps<typeof Annotation>["annotation"]["location"],
) {
  const size = annotationPdfPointSizeFromText(text);
  const annotation = {
    id: "annotation-preview-text-asset-placeholder",
    __typename: "TextAnnotation",
    subtype: AnnotationSubtype.FREE_TEXT,
    location,
    text,
    size: { __typename: "Size", ...size },
    annotationDesignationId: null,
    canEdit: true,
  } as const;
  return <Annotation annotation={annotation} isPreview />;
}

function ToolPreview({ currentTool, witnessParticipant, organization, cache }: Props) {
  const { usePreviewLocation } = usePDFContext();
  const intl = useIntl();
  const previewLocation = usePreviewLocation(Boolean(currentTool));

  if (!previewLocation || previewLocation.pageType !== PageTypes.DOCUMENT) {
    return null;
  }

  const location = {
    __typename: "AnnotationLocation",
    point: { __typename: "Point", ...previewLocation.point },
    coordinateSystem: CoordinateSystem.ABSOLUTE,
    page: previewLocation.pageIndex,
    pageType: previewLocation.pageType,
  } as const;

  switch (currentTool) {
    case AnnotationSubtype.FREE_TEXT:
    case "text": {
      const size = annotationPdfPointSizeFromText(TEXT_ANNOTATION_PLACEHOLDER);
      const annotation = {
        id: `annotation-preview-text-${AnnotationSubtype.FREE_TEXT}`,
        __typename: "TextAnnotation",
        subtype: AnnotationSubtype.FREE_TEXT,
        location,
        text: "",
        size: { __typename: "Size", ...size },
        annotationDesignationId: null,
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case AnnotationSubtype.SIGNATURE: {
      const asset = witnessParticipant.signingAssets?.signatureAsset?.png;
      if (
        !asset ||
        forceAssetRecreation({
          organization,
          signingAssets: witnessParticipant.signingAssets,
          type: "SIGNATURE",
        })
      ) {
        return textAssetPlaceholder(intl.formatMessage(MESSAGES.signature), location);
      }
      const size = getCachedSizeForAnnotation({
        cache,
        userId: witnessParticipant.userId!,
        graphicType: AnnotationGraphicTypes.SIGNATURE,
        size: { width: 200, height: 40 },
      });
      const annotation = {
        id: "annotation-preview-vectorsignature",
        __typename: "VectorGraphicAnnotation",
        subtype: AnnotationSubtype.SIGNATURE,
        pngAsset: { __typename: "SecureUrl", url: asset.url! },
        asset: null,
        location,
        size: { __typename: "Size", ...size },
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    default:
      return null;
  }
}

export default memo(ToolPreview);
