import { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { fromSocketEvent } from "socket/util";
import { segmentTrack } from "util/segment";
import { NetworkStatus, useMutation, useQuery } from "util/graphql";
import { Column, Container, Row } from "common/core/responsive";
import LoadingIndicator from "common/core/loading_indicator";
import { Hr } from "common/core/horizontal_rule";
import type Channel from "socket/channel";
import { pushNotification } from "common/core/notification_center/actions";
import MeetingQuery, {
  type NotaryMeeting,
  type NotaryMeeting_meeting_Meeting as NotaryMeetingRoot,
  type NotaryMeetingVariables,
} from "common/meeting/notary/meeting_query.graphql";
import IdentityTimeline from "common/identity/timeline";
import {
  ControlledIdentityDocumentViewer,
  parsePhotosForDocumentViewer,
  useIdentityDocumentViewerEngine,
} from "common/identity/document_viewer";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { useRetakeManagerContext } from "common/identity_verification/retake/notary";
import {
  getPhotoRequirementsForDocumentViewer,
  PhotoType,
} from "common/identity/document_viewer/engine";
import {
  ControlledPopoutViewer,
  usePopoutViewerEngine,
} from "common/identity/document_viewer/popout_viewer";
import { MeetingParticipantRoles } from "graphql_globals";

import Styles from "./index.module.scss";
import IdentityProfile from "./profile";
import SimplifiedIdentityProfile from "./witness/profile";
import IdentityAttributes from "./attributes";
import MeetingCredentialAnalysisV2Query, {
  type MeetingCredentialAnalysisV2_signerIdentity_SignerIdentity as SignerIdentity,
} from "./index.query.graphql";
import UpdatePhotoIdentificationMutation from "./update_photo_identification.mutation.graphql";
import AcknowledgeSignerPhotoIdentificationMutation from "./acknowledge_signer_photo_identification.mutation.graphql";

export type ActiveParticipant = {
  id: string;
  signerIdentityId: string;
  requiresBiometrics: boolean;
  requiresCredentialAnalysis: boolean;
  role: string;
};

type WrapperProps = {
  meeting: { id: string };
  activeParticipant: ActiveParticipant;
  onClose: () => void;
  channel: Channel;
  onSuccess?: () => void;
  isWitnessViewer?: boolean;
  credentialsViewed?: boolean;
};

type InternalProps = WrapperProps & {
  signerIdentity: SignerIdentity;
  popoutEngine: ReturnType<typeof usePopoutViewerEngine>;
  popoutOpen: boolean;
  setPopoutOpen: (open: boolean) => void;
};

const MESSAGES = defineMessages({
  identityVerifySuccess: {
    id: "9e2969ca-0dfd-4094-bba3-e1b514a3cb6b",
    defaultMessage:
      "You've validated the {isWitness, select, true {witness} other {signer}}'s identity",
  },
  identityVerifyFailure: {
    id: "14933655-c14c-4098-b502-9d5cb3b02b1b",
    defaultMessage:
      "An unknown error occurred while trying to verify the {isWitness, select, true {witness} other {signer}}'s identity documents.",
  },
  signerCredentialsVerified: {
    id: "fe363171-375e-41cd-9cd2-cc423ff8a8b8",
    defaultMessage: "You viewed the credentials",
  },
});

enum AnalyticsEvent {
  Opened = "Opened",
  Closed = "Closed",
  PopoutOpened = "Popout opened",
  PopoutClosed = "Popout closed",
  CompleteVerification = "Complete verification clicked",
  CompleteAcknowledgment = "Complete acknowledgement clicked",
}

type AnalyticsData = Record<string, unknown>;

function useCredentialViewerAnalytics() {
  return (event: AnalyticsEvent, data?: AnalyticsData) => {
    segmentTrack(`Credential Viewer V2 - ${event}`, data);
  };
}

function MeetingCredentialAnalysisV2(props: InternalProps) {
  const {
    meeting: { id: meetingId },
    activeParticipant,
    onClose,
    onSuccess,
    signerIdentity,
    popoutEngine,
    popoutOpen,
    setPopoutOpen,
    isWitnessViewer,
    credentialsViewed,
  } = props;
  const intl = useIntl();
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [acknowledgementLoading, setAcknowledgementLoading] = useState(false);
  const acknowledgeSignerPhotoIdentification = useMutation(
    AcknowledgeSignerPhotoIdentificationMutation,
  );
  const updatePhotoIdentification = useMutation(UpdatePhotoIdentificationMutation);
  const parsedPhotos = parsePhotosForDocumentViewer(signerIdentity);
  const documentViewerEngine = useIdentityDocumentViewerEngine({
    photos: parsedPhotos,
    requirements: getPhotoRequirementsForDocumentViewer(signerIdentity),
  });
  const retakeManager = useRetakeManagerContext(activeParticipant, {
    isPrimaryPhoto: documentViewerEngine.currentPhotoType === PhotoType.Primary,
  });
  const track = useCredentialViewerAnalytics();

  useEffect(() => {
    track(AnalyticsEvent.Opened);
  }, []);

  const handleOpenPopout = useCallback(() => {
    track(AnalyticsEvent.PopoutOpened);
    setPopoutOpen(true);
  }, []);
  const handleClosePopout = useCallback(() => {
    track(AnalyticsEvent.PopoutClosed);
    setPopoutOpen(false);
  }, []);

  const handleClose = () => {
    track(AnalyticsEvent.Closed);
    onClose();
  };

  const handleCompleteAcknowledgement = () => {
    track(AnalyticsEvent.CompleteAcknowledgment);
    setAcknowledgementLoading(true);

    return acknowledgeSignerPhotoIdentification({
      variables: {
        input: {
          photoIdentificationId: signerIdentity.photoId!.id,
          signerIdentityId: signerIdentity.id,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.signerCredentialsVerified),
        });
      })
      .finally(() => {
        setAcknowledgementLoading(false);
      });
  };

  const handleCompleteVerification = () => {
    track(AnalyticsEvent.CompleteVerification);
    setVerificationLoading(true);
    return updatePhotoIdentification({
      variables: {
        input: {
          photoIdentificationId: signerIdentity.photoId!.id,
          verified: true,
        },
      },
      update(cache) {
        const data = cache.readQuery<NotaryMeeting>({
          query: MeetingQuery,
          variables: { meetingId },
        });
        const meeting = data!.meeting as NotaryMeetingRoot;
        const newMeeting = {
          ...meeting,
          meetingParticipants: meeting.meetingParticipants.map((part) => {
            return part.__typename === "SignerParticipant" &&
              part.signerIdentityId === signerIdentity.id
              ? {
                  ...part,
                  photoIdVerified: true,
                }
              : part;
          }),
        };
        cache.writeQuery<NotaryMeeting, NotaryMeetingVariables>({
          data: { ...data!, meeting: newMeeting },
          query: MeetingQuery,
          variables: { meetingId },
        });
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.identityVerifySuccess, {
            isWitness: activeParticipant.role === MeetingParticipantRoles.WITNESS,
          }),
        });
        onSuccess?.();
      })
      .catch(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.identityVerifyFailure, {
            isWitness: activeParticipant.role === MeetingParticipantRoles.WITNESS,
          }),
        });
      })
      .finally(() => {
        setVerificationLoading(false);
      });
  };

  return popoutOpen ? (
    <ControlledPopoutViewer
      onClose={handleClose}
      onClosePopout={handleClosePopout}
      popoutEngine={popoutEngine}
      documentEngine={documentViewerEngine}
      retakeManager={retakeManager}
    />
  ) : (
    <div className={Styles.modal}>
      <Container fluid className={Styles.container}>
        <Row gutterWidth={48}>
          <Column xs={8}>
            <section>
              <ControlledIdentityDocumentViewer
                retakeManager={retakeManager}
                engine={documentViewerEngine}
                onPopout={handleOpenPopout}
                isSimplifiedViewer={isWitnessViewer}
              />
            </section>
            <section>
              <IdentityAttributes signerIdentity={signerIdentity} />
            </section>
          </Column>
          <Column xs={4}>
            <section>
              {isWitnessViewer ? (
                <SimplifiedIdentityProfile
                  activeParticipant={activeParticipant}
                  onClose={handleClose}
                  onViewCredentials={handleCompleteAcknowledgement}
                  credentialsViewed={credentialsViewed!}
                  loading={acknowledgementLoading}
                />
              ) : (
                <IdentityProfile
                  activeParticipant={activeParticipant}
                  signerIdentity={signerIdentity}
                  onClose={handleClose}
                  onVerify={handleCompleteVerification}
                  onRetake={retakeManager.initiate}
                  loading={verificationLoading || retakeManager.inProgress}
                />
              )}
            </section>
            <Hr className={Styles.hr} />
            {!isWitnessViewer && (
              <section className={Styles.timelineContainer}>
                <IdentityTimeline
                  identityTimeline={signerIdentity.identityTimeline}
                  options={{ horizontal: false }}
                />
              </section>
            )}
          </Column>
        </Row>
      </Container>
    </div>
  );
}

export default function MeetingCredentialAnalysisV2Wrapper(props: WrapperProps) {
  const { activeParticipant, channel } = props;
  const { data, loading, refetch, networkStatus } = useQuery(MeetingCredentialAnalysisV2Query, {
    variables: { signerIdentityId: activeParticipant.signerIdentityId },
  });

  useEffect(() => {
    const sub = fromSocketEvent(channel, "photo_updated").subscribe(() => refetch());
    return () => sub.unsubscribe();
  }, [channel]);

  // We want to preserve the popout viewer open and location state while MeetingCredentialAnalysisV2
  // unmounts and remounts during a data fetch, so the state has to live here.
  const [popoutOpen, setPopoutOpen] = useState(false);
  const popoutEngine = usePopoutViewerEngine();

  if ((loading && networkStatus !== NetworkStatus.refetch) || !data) {
    return <LoadingIndicator />;
  }

  if (data.signerIdentity?.__typename !== "SignerIdentity") {
    throw new Error(`Expected signerIdentity, got ${data.signerIdentity?.__typename}.`);
  }

  return (
    <MeetingCredentialAnalysisV2
      signerIdentity={data.signerIdentity}
      popoutOpen={popoutOpen}
      setPopoutOpen={setPopoutOpen}
      popoutEngine={popoutEngine}
      {...props}
    />
  );
}
