import "./reject.scss";

import { reduxForm, type InjectedFormProps, type FormSubmitHandler } from "redux-form";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";
import FormGroup from "common/form/group";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { DeprecatedTextAreaField } from "common/form/fields/text_area";
import FormGroupErrors from "common/form/group_errors";
import { DOC_REJECTION_DESCRIPTIONS } from "constants/document";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";

export type RejectionFormValues = {
  rejectionReason: string;
  rejectionAnecdote: string;
};
type Props = {
  onSubmitReport: FormSubmitHandler<RejectionFormValues, Props>;
  onDismiss: () => void;
};
type InnerProps = InjectedFormProps<RejectionFormValues, Props> & Props;

const MESSAGES = defineMessages({
  placeholder: {
    id: "3a11aeb8-ca68-445b-9e34-946db9ae698b",
    defaultMessage:
      "Add a brief explanation of the issue to help us understand the reason for rejection",
  },
});

function validate(values: RejectionFormValues) {
  return composeValidators(
    validatePresence({ error: null, field: "rejectionReason", label: "Rejection Description" }),
  )(values);
}

function Modal({ handleSubmit, onSubmitReport, onDismiss }: InnerProps) {
  const intl = useIntl();
  const submitHandler = handleSubmit(onSubmitReport);
  return (
    <IllustrationModal
      className="NotaryMeetingRejectDocumentModal"
      title={
        <FormattedMessage
          id="6d209d30-a615-42f9-8f22-d0be4c7db8e7"
          defaultMessage="Reject This Document"
        />
      }
      buttons={[
        <Button
          key="1"
          buttonColor="action"
          variant="primary"
          automationId="submit-report-button"
          onClick={submitHandler}
        >
          <FormattedMessage
            id="98427ad3-2666-4e4e-99da-64e293dd7b43"
            defaultMessage="Submit Report"
          />
        </Button>,
        <Button
          key="0"
          buttonColor="action"
          variant="secondary"
          automationId="return-to-meeting-button"
          onClick={onDismiss}
        >
          <FormattedMessage
            id="2070f674-ee5f-4489-8275-4c1c54ec4114"
            defaultMessage="Return to Meeting"
          />
        </Button>,
      ]}
    >
      <form onSubmit={submitHandler}>
        <FormattedMessage
          id="42232106-2693-4c8d-9cff-c75f67ce2853"
          defaultMessage="Why did you reject this document?"
          tagName="h5"
        />
        <FormGroup fields={["rejectionReason"]}>
          {Object.entries(DOC_REJECTION_DESCRIPTIONS).map(([key, val]) => (
            <DeprecatedRadioButtonField
              key={key}
              id={key}
              labelText={intl.formatMessage(val)}
              radioValue={key}
              name="rejectionReason"
            />
          ))}
          <FormGroupErrors fields={["rejectionReason"]} />
        </FormGroup>
        <FormGroup fields={["rejectionAnecdote"]}>
          <DeprecatedTextAreaField
            id="rejectionAnecdote"
            placeholder={intl.formatMessage(MESSAGES.placeholder)}
            name="rejectionAnecdote"
            rows={4}
          />
          <FormGroupErrors fields={["rejectionAnecdote"]} />
        </FormGroup>
      </form>
    </IllustrationModal>
  );
}

export const RejectDocumentModal = reduxForm<RejectionFormValues, Props>({
  form: "rejectDocumentModal",
  touchOnBlur: false,
  touchOnChange: true,
  validate,
})(Modal);
