import { defineMessages, useIntl } from "react-intl";

import { usePermissions } from "common/core/current_user_role";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import SectionHeader from "common/form/sub_form/section/header";
import SubFormSection from "common/form/sub_form/section";
import TipWell from "common/core/tip_well";

const messages = defineMessages({
  secondaryId: {
    id: "c03935f5-51ae-4d4a-8f6a-6c595d6a22b1",
    defaultMessage: "Will the signer(s) need a secondary ID?",
  },
  secondaryIdTipHeader: {
    id: "927d05fe-bab3-4cea-962b-700b8faa9a69",
    defaultMessage: "When should I ask for a secondary ID?",
  },
  secondaryIdTip: {
    id: "23401917-d14c-46b6-9b86-4366af42c404",
    defaultMessage:
      "Some documents explicitly require two forms of " +
      "ID. Check the documents you are sending to see if they do.",
  },
});

export function SecondaryIdSection() {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  return (
    <SubFormSection
      className="SignerDetailsSection--SecondaryId"
      tipWell={
        <TipWell heading={intl.formatMessage(messages.secondaryIdTipHeader)}>
          {intl.formatMessage(messages.secondaryIdTip)}
        </TipWell>
      }
      automationId="secondaryIdForm"
    >
      <DeprecatedFormRow>
        <SectionHeader title={intl.formatMessage(messages.secondaryId)} />
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <DeprecatedRadioButtonField
          id="secondaryId"
          name="secondaryId"
          labelText="No"
          automationId="secondary-id-not-required-radio-button"
          labelClassName="SignerDetailsSection--SecondaryId--Fields--RadioLabel"
          radioValue={false}
          size="small"
          disabled={!hasPermissionFor("editTransactionSigners")}
        />
        <DeprecatedRadioButtonField
          id="secondaryId"
          name="secondaryId"
          labelText="Yes"
          automationId="secondary-id-required-radio-button"
          labelClassName="SignerDetailsSection--SecondaryId--Fields--RadioLabel"
          radioValue
          size="small"
          disabled={!hasPermissionFor("editTransactionSigners")}
        />
      </DeprecatedFormRow>
    </SubFormSection>
  );
}
