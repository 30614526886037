import "../signer_info_text_fields.scss";

import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { messages } from "common/meeting/notary/credential_analysis/messages";

function SignerInfoTextFields({ signerIdentity }) {
  const intl = useIntl();
  const { dob, firstName, middleName, lastName } = signerIdentity.photoId;
  return (
    <div className="MeetingCredentialAnalysis--inputField signerInfoFields">
      <div className="FormGroup Form-row">
        <DeprecatedMultipartRow>
          <DeprecatedMultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">{intl.formatMessage(messages.first)}</span>
              <div className="SignerInformation--value firstName">{firstName}</div>
            </div>
          </DeprecatedMultipartColumn>
          <DeprecatedMultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">
                {intl.formatMessage(messages.middle)}
              </span>
              <div className="SignerInformation--value middleName">{middleName}</div>
            </div>
          </DeprecatedMultipartColumn>
          <DeprecatedMultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">{intl.formatMessage(messages.last)}</span>
              <div className="SignerInformation--value lastName">{lastName}</div>
            </div>
          </DeprecatedMultipartColumn>
        </DeprecatedMultipartRow>
      </div>

      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">
            {intl.formatMessage(messages.dateOfBirth)}
          </span>
          <div className="SignerInformation--value dateOfBirth">{dob}</div>
        </div>
      </div>
    </div>
  );
}

SignerInfoTextFields.propTypes = {
  signerIdentity: PropTypes.shape({
    photoId: PropTypes.shape({
      dob: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
};

export default SignerInfoTextFields;
